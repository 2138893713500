/* eslint-disable multiline-ternary */
import React, { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Select,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/core';
import Carousel from 'react-items-carousel';

import { Card } from '../../common/components/Card';
import {
  IconChevronDown,
  IconFacebook,
  IconInstagram
} from '../../common/components/Icons';
import { UList, UListItem } from '../../common/components/List';
import { images, categories, publications, prizes, expositions } from './data';
import coliseumImage from '../../common/assets/images/artist/gallery/Street/Tourist in Olympe.jpg';
import joseAntoineCostaImage from '../../common/assets/images/artist/jose-antoine-costa.png';
import confidentialityImage from '../../common/assets/images/confidentiality.jpg';
import { ArtistHeader } from './components/ArtistHeader';
import { ArtistPrizes } from './ArtistPrizes';
import yellowKornerIcon from '../../common/assets/images/yellow-korner.svg';
import { CarouselItem } from './components/CarouselItem';
import { GalleryItem } from './components/GalleryItem';
import { GalleryItemShow } from './components/GalleryItemShow';

export const ArtistShow = () => {
  const isMobile = useBreakpointValue({
    base: true,
    xl: false
  });

  const numberOfCards = useBreakpointValue({
    base: 2,
    sm: 3,
    md: 4,
    xl: 5,
    xxl: 5
  });

  const gutter = useBreakpointValue({
    base: 10,
    md: 24,
    xxl: 40
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentImage, setCurrentImage] = useState({});
  const [currentCat, setCurrentCat] = useState('');
  const [currentItem, setCurrentItem] = useState(0);

  const selectCategory = cat => {
    setCurrentCat(cat);

    if (isMobile) {
      const gallery = document.getElementById('gallery');
      const headerHeight = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--header-height')
        .replace('px', '');

      const y =
        gallery.getBoundingClientRect().top +
        window.pageYOffset -
        Number(headerHeight);

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const selectedCatImages = currentCat
    ? images.filter(img => img.category === currentCat)
    : images;

  return (
    <>
      <ArtistHeader
        bgImage={coliseumImage}
        avatarImage={joseAntoineCostaImage}
        artistName='José Antoine Costa'
        artistQuote="« L'architecture est le grand livre de l'humanité, l'expression
    principale de l'homme à ses divers états de développement, soit comme
    force, soit comme intelligence. » - Victor Hugo"
        mt={{ base: 20, sm: 16, md: 40 }}
      >
        <Box maxW={{ base: '953px', xxl: '1400px' }} mx='auto'>
          <Card
            mt={16}
            bgColor='black.light'
            shadow='none'
            borderColor='primary'
            borderTopWidth='1px'
          >
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Box>
                <Text fontSize={{ lg: 'xl' }} textAlign={{ md: 'justify' }}>
                  Photographe de l’humain, José Antoine Costa inclut dans ses
                  photographies une vision du monde esthétique, une quête de
                  beauté intérieure et plastique qui donne lieu à des images
                  riches de sens, créant des moments de grâce. ( Par Costanza
                  Spina )
                </Text>
                <Flex
                  color='gray.light'
                  mt={6}
                  wrap={{ base: 'wrap', xl: 'nowrap' }}
                  alignItems='center'
                >
                  <Stack
                    direction='row'
                    spacing={{ base: 4, lg: 6 }}
                    justify={{ base: 'space-evenly', lg: 'start' }}
                    wrap={{ base: 'wrap', xl: 'nowrap' }}
                    mr={{ xl: 6 }}
                    align='center'
                    flexGrow='1'
                  >
                    {[
                      {
                        label: 'Facebook',
                        url: 'https://www.facebook.com/joseantoinecostaphoto',
                        SNSIcon: IconFacebook
                      },
                      {
                        label: 'Yellow Korner',
                        url:
                          'https://www.yellowkorner.com/fr/c/jose-antoine-costa',
                        SNSIcon: props => (
                          <Image
                            src={yellowKornerIcon}
                            {...props}
                            mt={{ base: '1px', sm: 0 }}
                            boxSize={{ base: '34px', sm: 4, lg: '1.25rem' }}
                          />
                        )
                      },
                      {
                        label: 'Instagram',
                        url: 'https://www.instagram.com/joseantoinecosta',
                        SNSIcon: IconInstagram
                      }
                    ].map(({ SNSIcon, url, label }) => (
                      <Link
                        key={label}
                        href={url}
                        d='flex'
                        alignItems='center'
                        _hover={{ color: 'white' }}
                        whiteSpace='nowrap'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <SNSIcon
                          color='primary'
                          flexShrink='0'
                          boxSize={{ base: 8, sm: 4, lg: '1.25rem' }}
                          mr={{ sm: 1, lg: 2 }}
                        />
                        <chakra.span
                          css={{
                            '@media screen and (max-width: 30em)': {
                              border: '0px',
                              clip: 'rect(0px,0px,0px,0px)',
                              height: '1px',
                              width: '1px',
                              margin: '-1px',
                              padding: '0px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              position: 'absolute'
                            }
                          }}
                        >
                          {label}
                        </chakra.span>
                      </Link>
                    ))}
                  </Stack>

                  <Button
                    as={Link}
                    colorScheme='primary'
                    size='lg'
                    fontSize={{ xl: 'lg' }}
                    href='mailto:joseantoine.costa@gmail.com'
                    flexGrow='1'
                    w={{ base: '100%', sm: 'auto' }}
                    mt={{ base: 6, xl: 0 }}
                  >
                    Contacter l'artiste
                  </Button>
                </Flex>
              </Box>
              <Box pt={4}>
                <Divider
                  orientation='vertical'
                  mx={10}
                  borderColor='gray.light'
                />
              </Box>
              <Box d={{ base: 'none', md: 'block' }}>
                <Heading
                  fontSize={{ base: 'lg', lg: 'xl' }}
                  whiteSpace='nowrap'
                >
                  Evénements à venir
                </Heading>
              </Box>
            </Flex>
          </Card>
          <Accordion allowMultiple>
            <AccordionItem borderColor='primary' bgColor='black.light'>
              <h2>
                <AccordionButton
                  py={5}
                  px={6}
                  fontSize={{ lg: 'xl' }}
                  _expanded={{ color: 'primary' }}
                  _focus={{ outline: 'none' }}
                >
                  <Box flex='1' textAlign='left'>
                    Publications, expositions et récompenses de l’artiste
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                as={Card}
                pt={{ base: 4, md: 0 }}
                pb={{ base: 4, md: 8 }}
                px={{ base: 8, md: null }}
              >
                <Flex
                  justify='space-between'
                  fontSize={{ lg: 'lg' }}
                  wrap={{ base: 'wrap', lg: 'nowrap' }}
                  mb={8}
                >
                  <Box flex={{ lg: '0 0 30%' }}>
                    <Heading
                      fontSize={{ base: 'lg', lg: 'xl' }}
                      whiteSpace='nowrap'
                    >
                      Publications
                    </Heading>
                    <UList spacing={4} mt={4} pl='8'>
                      {publications.slice(0, 3).map((pub, index) => (
                        <UListItem key={index}>
                          <strong>{pub.title}</strong>
                          <br />
                          {pub.subtitle}
                        </UListItem>
                      ))}
                    </UList>
                  </Box>
                  <Box mt={{ base: 6, lg: 0 }} flex={{ lg: '0 0 30%' }}>
                    <Heading
                      fontSize={{ base: 'lg', lg: 'xl' }}
                      whiteSpace='nowrap'
                    >
                      Expositions
                    </Heading>
                    <UList spacing={4} mt={4} pl='8'>
                      {expositions.slice(0, 3).map((expo, index) => (
                        <UListItem key={index}>{expo.title}</UListItem>
                      ))}
                    </UList>
                  </Box>
                  <Box mt={{ base: 6, lg: 0 }} flex={{ lg: '0 0 30%' }}>
                    <Heading
                      fontSize={{ base: 'lg', lg: 'xl' }}
                      whiteSpace='nowrap'
                    >
                      Prix et récompenses
                    </Heading>
                    <UList spacing={4} mt={4} pl='8'>
                      {prizes.slice(0, 3).map((prize, index) => (
                        <UListItem key={index}>
                          <strong>{prize.title}</strong>
                          <br />
                          {prize.subtitle}
                        </UListItem>
                      ))}
                    </UList>
                  </Box>
                </Flex>
                <ArtistPrizes />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </ArtistHeader>
      <chakra.section
        py={{ base: 'min(calc(6vw), 30px)', xl: 5 }}
        bgColor='black.light'
        my={20}
        mx='auto'
        overflowX='auto'
        minW='0'
        width='100%'
        borderTop='1px solid'
        borderBottom='1px solid'
        borderColor='primary'
        css={{
          '.carousel-items-wrapper': {
            justifyContent:
              categories.length < numberOfCards ? 'center' : 'start'
          }
        }}
      >
        <Carousel
          gutter={gutter}
          activePosition='center'
          chevronWidth={60}
          disableSwipe={false}
          alwaysShowChevrons={false}
          numberOfCards={numberOfCards}
          slidesToScroll={1}
          outsideChevron={false}
          showSlither
          firstAndLastGutter
          activeItemIndex={currentItem}
          requestToChangeActive={setCurrentItem}
          classes={{ itemsInnerWrapper: 'carousel-items-wrapper' }}
          rightChevron={
            <IconChevronDown
              transform='rotate(-90deg)'
              rounded='full'
              bg='gray.light'
              boxSize={{ base: 5, sm: 10 }}
              p={{ base: 1, sm: 2 }}
              filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
            />
          }
          leftChevron={
            <IconChevronDown
              transform='rotate(90deg)'
              rounded='full'
              bg='gray.light'
              boxSize={{ base: 5, sm: 10 }}
              p={{ base: 1, sm: 2 }}
              filter='drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
            />
          }
        >
          <CarouselItem
            image={{ src: null }}
            onClick={() => selectCategory('')}
            data-active={currentCat === '' ? true : null}
            label='All'
          />
          {categories.map((cat, index) => (
            <CarouselItem
              key={index}
              data-active={currentCat === cat.label ? true : null}
              onClick={() => selectCategory(cat.label)}
              {...cat}
            />
          ))}
        </Carousel>
      </chakra.section>
      <chakra.section px={{ base: 'min(calc(4vw), 100px)', xl: 0 }}>
        <Box id='gallery' maxW='1300px' mx='auto'>
          <Heading
            display='flex'
            alignItems='center'
            pos='sticky'
            top='63px'
            zIndex='docked'
            py={{ base: 3, md: 5 }}
            bgColor='bg.homepage'
            fontSize={{ base: 'md', md: 'xl' }}
          >
            <Avatar
              pointerEvents='none'
              src={joseAntoineCostaImage}
              size='sm'
              mr={3}
            />
            <Text
              as='span'
              d='inline-block'
              maxW={{ base: '120px', sm: 'none' }}
            >
              José Antoine Costa
            </Text>
            <Select
              onChange={e => {
                selectCategory(e.target.value);
                setCurrentItem(
                  categories.findIndex(cat => cat.label === e.target.value) + 1
                );
              }}
              color='primary'
              maxW={{ base: '55%', sm: '200px' }}
              ml={{ base: 1, sm: 4 }}
              px={{ base: 1, sm: 0 }}
              borderColor='gray.medium'
              fontSize={{ base: 'md', md: 'xl' }}
              _focus={{
                outline: 'none'
              }}
              _hover={{
                borderColor: 'gray.medium'
              }}
              value={currentCat}
              css={{
                select: {
                  paddingLeft: 4,
                  paddingRight: 4
                },
                '> option': {
                  backgroundColor: 'initial',
                  fontFamily: 'body',
                  color: 'initial'
                }
              }}
            >
              <option value='' onClick={() => setCurrentItem(0)}>
                All
              </option>
              {categories.map((cat, index) => (
                <option
                  key={index}
                  value={cat.label}
                  onClick={() => setCurrentItem(index + 1)}
                >
                  {cat.label}
                </option>
              ))}
            </Select>
          </Heading>
          <Grid
            templateColumns={{ md: 'repeat(3, 1fr)' }}
            autoFlow='dense'
            templateRows='1fr'
            alignItems='center'
            rowGap='min(calc(4vw), 100px)'
            columnGap='min(calc(6vw), 150px)'
            mx='auto'
          >
            {selectedCatImages?.map((img, index) => (
              <GalleryItem
                key={img.src}
                img={img}
                onClick={e => {
                  setCurrentImage(img);
                  onOpen(e);
                }}
              />
            ))}
          </Grid>
        </Box>
      </chakra.section>
      <Box
        bgImage={`url(${confidentialityImage})`}
        bgPosition='center'
        backgroundSize='auto 100%'
        w='100%'
        h='10vw'
        mt={{ base: 12, md: 36 }}
      />
      <GalleryItemShow
        isOpen={isOpen}
        onClose={onClose}
        currentImage={currentImage}
      />
    </>
  );
};
