/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/core';
import { Field, Form, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import { FormCheckbox, FormInput } from '../../../common/components/Form';
import { FormSection, FormSectionTitle } from '../components/Form';
import { FormDatepicker } from '../../../common/components/Form/FormDatepicker';
import { updateUser } from '../../auth/actions';
import { useAuthDispatch, useAuthState } from '../../auth';
import { Card } from '../../../common/components/Card/index.js';
import { toastOptions } from '../../../common/components/Toast';
import { IconClose } from '../../../common/components/Icons';
import { getDateString } from '../../../common/utils';

const ReferencesSchema = Yup.object({
  references: Yup.array(
    Yup.object({
      nameCompany: Yup.string().required(['doit être rempli(e)']),
      name: Yup.string().required(['doit être rempli(e)']),
      job: Yup.string().required(['doit être rempli(e)']),
      email: Yup.string()
        .email('Email invalide')
        .required(['doit être rempli(e)']),
      seniorityCompanyStart: Yup.mixed()
        .nullable()
        .required(['doit être rempli(e)']),
      seniorityCompanyEnd: Yup.mixed()
        .nullable()
        .required(['doit être rempli(e)'])
    })
  )
});

export const ReferencesLogic = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.isJunior && values.references.length) {
      const referencesToKeep = values.references.filter(ref => ref.id) || [];
      setFieldValue('references', referencesToKeep);
    }
  }, [values.isJunior]);

  return null;
};

export const References = () => {
  const toast = useToast();
  const dispatch = useAuthDispatch();
  const { user } = useAuthState();

  const addReference = formik => {
    const reference = {
      userId: user.id,
      nameCompany: '',
      name: '',
      job: '',
      email: '',
      seniorityCompanyStart: null,
      seniorityCompanyEnd: null
    };
    const updatedReferences = [...formik.values.references, reference];
    formik.setFieldValue('references', updatedReferences);
  };

  const deleteReference = (formik, index) => {
    const confirm = window.confirm('Voulez-vous supprimer cette référence ?');

    if (confirm) {
      const referenceToDelete = formik.values.references[index];
      let updatedReferences;

      if (referenceToDelete.id) {
        updatedReferences = formik.values.references;
        updatedReferences[index] = {
          ...referenceToDelete,
          _destroy: true
        };
      } else {
        formik.values.references.splice(index, 1);
        updatedReferences = formik.values.references;
      }

      formik.setFieldValue('references', updatedReferences);
    }
  };

  return (
    <Formik
      initialValues={{
        ...user,
        references:
          user.references.length > 0
            ? user.references?.map(ref => ({
                ...ref,
                seniorityCompanyStart: new Date(ref.seniorityCompanyStart),
                seniorityCompanyEnd: new Date(ref.seniorityCompanyEnd)
              }))
            : !user?.isJunior
            ? Array.from(Array(2)).map(index => ({
                userId: 1,
                nameCompany: '',
                name: '',
                job: '',
                email: '',
                seniorityCompanyStart: null,
                seniorityCompanyEnd: null
              }))
            : []
      }}
      enableReinitialize
      validationSchema={ReferencesSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, actions) => {
        updateUser(dispatch, {
          ...values,
          referencesAttributes: {
            ...values.references,
            ...values.references.map(ref => ({
              ...ref,
              seniorityCompanyStart: getDateString(ref.seniorityCompanyStart),
              seniorityCompanyEnd: getDateString(ref.seniorityCompanyEnd)
            }))
          }
        })
          .then(() => {
            actions.setSubmitting(false);
            toast(
              toastOptions({
                description: 'Informations sauvegardées',
                status: 'success'
              })
            );
          })
          .catch(err => {
            actions.setSubmitting(false);
            if (err.response) {
              actions.setErrors(err.response?.data?.errors);
            }
          });
      }}
    >
      {formik => (
        <Form>
          <Heading as='h1' textStyle='section' mb={6}>
            Référence pour votre dossier
          </Heading>
          {(user?.references?.length < 2 ||
            formik.values?.references?.filter(ref => !ref._destroy)?.length <
              2) &&
          !formik.values?.isJunior ? (
            <Alert
              w={{ xl: 8 / 12 }}
              status='info'
              variant='solid'
              background='gray.dark'
              rounded='md'
              mb={8}
            >
              <AlertIcon flexShrink={0} />
              <AlertDescription>
                Vous devez avoir au moins <strong>2 références</strong>. Votre
                dossier risque d’être bloqué ou refusé lors du traitement par
                l’administrateur.
              </AlertDescription>
            </Alert>
          ) : null}

          <ReferencesLogic />

          <VStack spacing='formSection' align='stretch'>
            <Field as={FormCheckbox} type='checkbox' name='isJunior'>
              Je suis junior je n'ai pas de références
            </Field>
            <VStack spacing={8} align='stretch'>
              {formik.values?.references?.map((reference, index) =>
                reference._destroy ? null : (
                  <FormControl
                    key={index}
                    isInvalid={
                      formik.getFieldMeta(`references[${index}]`).touched &&
                      formik.getFieldMeta(`references[${index}]`).error
                    }
                  >
                    <Card
                      as={FormSection}
                      variant='outline'
                      colorScheme={
                        formik.getFieldMeta(`references[${index}]`).touched &&
                        formik.getFieldMeta(`references[${index}]`).error
                          ? 'isInvalid'
                          : null
                      }
                      size='md'
                      backgroundColor='bg.homepage'
                      pt={{ base: 8, md: '27px' }}
                      pb={{ base: 8, md: '27px' }}
                      mr={{ base: -6, sm: 0 }}
                      ml={{ base: '-24px !important', sm: '0 !important' }}
                    >
                      <FormSectionTitle as='h2' pos='relative' mb={0}>
                        Référence
                        <CloseButton
                          color='gray.default'
                          top='-0.25rem'
                          right='-0.5rem'
                          pos='absolute'
                          onClick={() => deleteReference(formik, index)}
                        >
                          <IconClose boxSize={3} />
                        </CloseButton>
                      </FormSectionTitle>
                      <FormInput
                        label='Nom de la société'
                        name={`references[${index}].nameCompany`}
                        placeholder='ETI'
                        isInvalid={
                          formik.getFieldMeta(
                            `references[${index}].nameCompany`
                          ).touched &&
                          formik.getFieldMeta(
                            `references[${index}].nameCompany`
                          ).error
                        }
                      />
                      <FormInput
                        label='Prénom et Nom'
                        name={`references[${index}].name`}
                        placeholder='Jean Dupont'
                        isInvalid={
                          formik.getFieldMeta(`references[${index}].name`)
                            .touched &&
                          formik.getFieldMeta(`references[${index}].name`).error
                        }
                      />
                      <FormInput
                        label='Poste'
                        name={`references[${index}].job`}
                        placeholder='Chef de projet'
                        isInvalid={
                          formik.getFieldMeta(`references[${index}].job`)
                            .touched &&
                          formik.getFieldMeta(`references[${index}].job`).error
                        }
                      />
                      <FormInput
                        label='Adresse mail professionnelle'
                        type='email'
                        name={`references[${index}].email`}
                        placeholder='dupont.jean@eti.com'
                        isInvalid={
                          formik.getFieldMeta(`references[${index}].email`)
                            .touched &&
                          formik.getFieldMeta(`references[${index}].email`)
                            .error
                        }
                      />
                      <Stack
                        w='full'
                        direction={{ base: 'column', md: 'row' }}
                        wrap='wrap'
                        spacing={{ base: 4, md: 8 }}
                      >
                        <FormLabel as={Text} minW='full'>
                          Pendant quelle période avez-vous travaillé avec cette
                          personne ?
                        </FormLabel>
                        <Flex alignItems='baseline'>
                          <Text as='span' pr={2} ml={{ md: -8 }}>
                            du{' '}
                          </Text>
                          <FormDatepicker
                            width='180px'
                            minDate={null}
                            filterDate={null}
                            name={`references[${index}].seniorityCompanyStart`}
                            isInvalid={
                              formik.getFieldMeta(
                                `references[${index}].seniorityCompanyStart`
                              ).touched &&
                              formik.getFieldMeta(
                                `references[${index}].seniorityCompanyStart`
                              ).error
                            }
                          />
                        </Flex>
                        <Flex alignItems='baseline'>
                          <Text as='span' pr={2}>
                            au{' '}
                          </Text>
                          <FormDatepicker
                            width='180px'
                            minDate={null}
                            filterDate={null}
                            name={`references[${index}].seniorityCompanyEnd`}
                            isInvalid={
                              formik.getFieldMeta(
                                `references[${index}].seniorityCompanyEnd`
                              ).touched &&
                              formik.getFieldMeta(
                                `references[${index}].seniorityCompanyEnd`
                              ).error
                            }
                          />
                        </Flex>
                      </Stack>
                    </Card>
                    <FormErrorMessage pt={3}>
                      Tous les champs doivent être complétés pour créer une
                      référence.
                    </FormErrorMessage>
                  </FormControl>
                )
              )}
            </VStack>
            <Stack
              direction={{ base: 'column', md: 'row' }}
              justify='space-between'
            >
              <Button
                colorScheme='secondary'
                variant='rounded'
                size='xl'
                type='button'
                disabled={
                  formik.values.references?.filter(
                    reference => !reference._destroy
                  )?.length >= 4
                }
                onClick={() => addReference(formik)}
              >
                Ajouter une référence
              </Button>
              <Button
                colorScheme='primary'
                variant='rounded'
                size='xl'
                type='submit'
                isLoading={formik.isSubmitting}
              >
                Sauvegarder
              </Button>
            </Stack>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
