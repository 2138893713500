/* eslint-disable multiline-ternary */
import React, { useEffect, useState, useMemo } from 'react';
import qs from 'qs';
import { Box, Grid, Text, VStack } from '@chakra-ui/core';
import { useHistory, useLocation } from 'react-router';
import { useDataLoader } from 'react-remote-data-hooks';

import { MissionCard, MissionCardSkeleton } from '../../components/Mission';
import { getMissions } from '../../../../common/api/missions';
import { IndexContainer } from '../../components/Container';
import { SearchBar } from './components/SearchBar';
import { Pagination } from '../../../../common/components/Pagination';
import { getConstants } from '../../../../common/api/config';
import { ProfileRecap } from './components/ProfileRecap';

const initialValues = {
  byTitleOrDescription: '',
  byRemunerations: [],
  byLocations: [],
  byDurations: [],
  byAsap: false,
  byStartDate: null,
  byExperiences: [],
  bySectors: [],
  page: 1,
  perPage: '5'
};

export const Search = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true
      }),
    [location.search]
  );

  const [filters, setFilters] = useState({
    ...initialValues,
    ...queryParameters
  });

  const [constants, setConstants] = useState({});

  const { data, loaded, loading, reload } = useDataLoader(() =>
    getMissions(filters).then(({ data }) => data)
  );

  useEffect(() => {
    getConstants().then(({ data }) => setConstants(data));
  }, []);

  useEffect(() => {
    reload();
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true })
    });
  }, [filters]);

  const missions =
    !loaded && loading
      ? [...Array(5)].map((i, index) => <MissionCardSkeleton key={index} />)
      : data?.missions?.map((mission, index) => (
          <MissionCard key={index} content={mission} /> // eslint-disable-line
        )); // eslint-disable-line

  return (
    <Box minH='100vh' d='flex' flexDir='column'>
      <ProfileRecap />
      <SearchBar
        onSubmit={values => {
          window.scrollTo(0, 0);
          setFilters({ ...values, page: 1 });
        }}
        filters={filters}
        constants={constants}
        initialValues={initialValues}
      />
      <IndexContainer flex='1' d='flex' flexDir='column' pt={[2, null, 4]}>
        <Text pt={2} pb={3} textAlign='right' fontSize='sm' color='gray.light'>
          tri : du plus récent au plus ancien
        </Text>
        <VStack
          align='stretch'
          spacing={[4, 8]}
          mb={[4, 8]}
          flex='1'
          justify={data?.missions?.length ? 'flex-start' : 'center'}
        >
          {data?.missions?.length || (!loaded && loading) ? (
            missions
          ) : (
            <Grid placeItems='center' h='100%'>
              <Text textAlign='center'>Aucun résultat</Text>
            </Grid>
          )}
        </VStack>
        <Pagination
          onChange={newPagination => {
            window.scrollTo(0, 0);
            setFilters({ ...filters, ...newPagination });
          }}
          page={Number(filters.page)}
          perPage={filters.perPage}
          total={data?.meta?.totalCount}
        />
      </IndexContainer>
    </Box>
  );
};
