import React, { useEffect, useState } from 'react';
import { Box, GridItem, Text } from '@chakra-ui/layout';
import reactImageSize from 'react-image-size';

export const GalleryItem = ({ img, ...props }) => {
  const [dims, setDims] = useState({ width: 0, height: 0 });

  useEffect(() => {
    reactImageSize(img.src).then(dimensions => setDims(dimensions));
  }, [img]);

  const ratio = `calc((${dims.height} / ${dims.width || 375.73}) * 100%)`;
  const height = dims.width ? (375.73 * dims.height) / dims.width : 0;
  const width = height > 400 ? (400 * dims.width) / dims.height : '100%';

  return (
    <GridItem
      _notFirst={{ mt: 5 }}
      cursor='pointer'
      pos='relative'
      textAlign='center'
      minW='0'
      {...props}
    >
      <Box mx='auto' width={width} maxW='100%'>
        <Box
          pointerEvents='none'
          backgroundImage={`url("${img.src}")`}
          backgroundPosition='center'
          backgroundRepeat='no-repeat'
          backgroundSize='contain'
          paddingTop={ratio}
        />
      </Box>
      <Text
        color='gray.light'
        fontSize='small'
        pt={2}
        style={{
          mixBlendMode: 'difference'
        }}
      >
        {img.alt}
      </Text>
    </GridItem>
  );
};
