const { uploadFromUrl, getPresignedUrl } = require('../api/upload');

export const uploadFile = async ({ file }) => {
  const {
    data: { url, key }
  } = await getPresignedUrl();

  await uploadFromUrl({ url, data: file });

  return key;
};
