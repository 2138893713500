import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  chakra,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  useDisclosure,
  useStyleConfig,
  VisuallyHidden,
  VStack
} from '@chakra-ui/core';
import {
  IconArrowLeft,
  IconCalendar,
  IconCog,
  IconCurrency,
  IconExperience,
  IconFilters,
  IconTime
} from '../../../../../common/components/Icons';
import {
  FormCheckbox,
  FormCheckboxGroup
} from '../../../../../common/components/Form';
import { FormDatepicker } from '../../../../../common/components/Form/FormDatepicker';
import { Field, useFormikContext } from 'formik';
import { debounce } from '../../../../../common/utils';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const FiltersLogic = () => {
  const {
    values,
    setFieldValue,
    submitForm,
    touched,
    setTouched
  } = useFormikContext();

  useEffect(() => {
    if (values.byAsap) {
      setFieldValue('byStartDate', null);
    }
  }, [values.byAsap, setFieldValue]);

  useEffect(() => {
    if (values.byStartDate) {
      setFieldValue('byAsap', false);
    }
  }, [values.byStartDate, setFieldValue]);

  const debouncedSubmitForm = debounce(submitForm, 400);

  useEffect(() => {
    if (Object.values(touched).some(touch => touch)) {
      debouncedSubmitForm();
    } else {
      setTouched({ byTitleOrDescription: true, byLocations: true });
    }
  }, [values]);

  return null;
};

export const Filters = ({ constants, ...props }) => {
  return (
    <Flex
      gridColumn='1 / span 2'
      overflowX='auto'
      borderColor='gray.light'
      borderRightWidth='1px'
      {...props}
    >
      <FilterPopover>
        <PopoverTrigger>
          <FilterButton leftIcon={<IconCurrency />} name='byRemunerations'>
            TJM
          </FilterButton>
        </PopoverTrigger>
        <FilterPopoverContent>
          <FormCheckboxGroup name='byRemunerations'>
            {constants?.tjm?.map(tjm => (
              <FormCheckbox
                key={tjm}
                checkboxStyle={{
                  py: 1
                }}
                value={tjm}
              >
                {tjm}
              </FormCheckbox>
            ))}
          </FormCheckboxGroup>
        </FilterPopoverContent>
      </FilterPopover>
      <FilterPopover>
        <PopoverTrigger>
          <FilterButton leftIcon={<IconTime />} name='byDurations'>
            Durée
          </FilterButton>
        </PopoverTrigger>
        <FilterPopoverContent>
          <FormCheckboxGroup name='byDurations'>
            {constants?.missionTimes?.map(missionTime => (
              <FormCheckbox
                key={missionTime}
                checkboxStyle={{ py: 1 }}
                value={missionTime}
              >
                {missionTime}
              </FormCheckbox>
            ))}
          </FormCheckboxGroup>
        </FilterPopoverContent>
      </FilterPopover>
      <FilterPopover>
        <PopoverTrigger>
          <FilterButton leftIcon={<IconCalendar />} name='byStartDate'>
            Date de début
          </FilterButton>
        </PopoverTrigger>
        <FilterPopoverContent>
          <Field
            as={FormCheckbox}
            type='checkbox'
            name='byAsap'
            checkboxStyle={{ py: 1 }}
          >
            Au plus tôt
          </Field>
          <Divider mt={4} />
          <Text my={4} d='block'>
            À partir de :
          </Text>
          <FormDatepicker
            name='byStartDate'
            showMonthYearPicker
            showTwoColumnMonths
            inline
          />
        </FilterPopoverContent>
      </FilterPopover>
      <FilterPopover>
        <PopoverTrigger>
          <FilterButton leftIcon={<IconExperience />} name='byExperiences'>
            Expérience
          </FilterButton>
        </PopoverTrigger>
        <FilterPopoverContent>
          <FormCheckboxGroup name='byExperiences'>
            {constants?.experiences?.map(exp => (
              <FormCheckbox key={exp} checkboxStyle={{ py: 1 }} value={exp}>
                {exp} ans
              </FormCheckbox>
            ))}
          </FormCheckboxGroup>
        </FilterPopoverContent>
      </FilterPopover>
      <FilterPopover>
        <PopoverTrigger>
          <FilterButton leftIcon={<IconCog />} name='bySectors'>
            Secteurs
          </FilterButton>
        </PopoverTrigger>
        <FilterPopoverContent>
          <FormCheckboxGroup name='bySectors'>
            {constants?.sectors?.map(sector => (
              <FormCheckbox
                key={sector}
                checkboxStyle={{ py: 1, maxW: '300px' }}
                value={sector}
              >
                {sector}
              </FormCheckbox>
            ))}
          </FormCheckboxGroup>
        </FilterPopoverContent>
      </FilterPopover>
    </Flex>
  );
};

export const FilterButton = React.forwardRef(({ children, ...props }, ref) => {
  const { values } = useFormikContext();

  const { byTitleOrDescription, byLocations, ...filterValues } = values;

  const filtersSelected = useCallback(() => {
    const filters = filterValues[props.name];
    if (filterValues[props.name]) {
      if (filters instanceof Date) {
        return { hasFiltersSelected: true, nbOfFiltersSelected: 0 };
      }
      if (filters.length) {
        return {
          hasFiltersSelected: true,
          nbOfFiltersSelected: filters.length
        };
      }
    }

    return { hasFiltersSelected: false, nbOfFiltersSelected: 0 };
  }, [filterValues, props.name]);

  const filtersLabel = useMemo(() => filtersSelected(), [filtersSelected]);

  return (
    <Button
      as='div'
      tabIndex='0'
      role='button'
      ref={ref}
      variant='filter'
      textAlign='left'
      width='20%'
      css={{
        minWidth: '150px'
      }}
      rightIcon={
        <Icon boxSize={2} viewBox='0 0 8 6' fill='currentColor'>
          <path d='M4 6L.536 0h6.928L4 6z' />
        </Icon>
      }
      color={filtersLabel.hasFiltersSelected ? 'primary' : null}
      {...props}
    >
      <chakra.div
        css={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
          display: 'inline-block',
          maxWidth: '90%'
        }}
      >
        {filtersLabel.hasFiltersSelected && values[props.name] instanceof Date
          ? format(values[props.name], 'LLL yyyy', { locale: fr })
          : filtersLabel.hasFiltersSelected && values[props.name].length === 1
          ? values[props.name].toString() // eslint-disable-line
          : children /* eslint-disable-line */}
      </chakra.div>
      <chakra.div ml='1'>
        {filtersLabel.nbOfFiltersSelected &&
        filtersLabel.nbOfFiltersSelected > 1
          ? ` (${values[props.name].length})`
          : null}
      </chakra.div>
    </Button>
  );
});

const FilterPopover = ({ children, ...props }) => {
  return (
    <Popover
      placement='bottom-start'
      gutter={0}
      modifiers={[
        {
          name: 'sameWidth',
          enabled: true,
          phase: 'beforeWrite',
          requires: ['computeStyles'],
          fn: ({ state }) => {
            if (
              state.elements.popper.firstChild.offsetWidth <=
              state.rects.reference.width
            ) {
              state.elements.popper.firstChild.style.width = `${state.rects
                .reference.width + 1}px`;
            } else {
              state.elements.popper.firstChild.style.width =
                state.elements.popper.firstChild.offsetWidth;
            }
          },
          effect: ({ state }) => {
            if (
              state.elements.popper.firstChild.offsetWidth <=
              state.elements.reference.offsetWidth
            ) {
              state.elements.popper.firstChild.style.width = `${state.elements
                .reference.offsetWidth + 1}px`;
            } else {
              state.elements.popper.firstChild.style.width = 'max-content';
            }
          }
        }
      ]}
      {...props}
    >
      {children}
    </Popover>
  );
};

const FilterPopoverContent = ({ children, ...props }) => {
  const card = useStyleConfig('Menu', {});

  return (
    <PopoverContent
      {...card.list}
      color='white'
      maxH='50vh'
      w='max-content'
      maxW='none'
      minW='0'
      px={3}
      overflowY='auto'
      overflowX='hidden'
      _focus={{ outline: '0' }}
      borderTopRadius={0}
    >
      {children}
    </PopoverContent>
  );
};

export const FiltersMobile = ({ constants, initialValues }) => {
  const { values, resetForm } = useFormikContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    byTitleOrDescription,
    byLocations,
    page,
    perPage,
    ...filters
  } = values;

  const nbFilters = useMemo(
    () =>
      Object.values(filters).reduce((acc, filters) => {
        switch (typeof filters) {
          case 'boolean':
            return filters ? acc + 1 : acc + 0;
          case 'string':
            return filters ? acc + 1 : acc + 0;
          default:
            if (filters instanceof Date) {
              return acc + 1;
            }
            return filters ? acc + filters.length : acc + 0;
        }
      }, 0),
    [filters]
  );

  return (
    <>
      <Button
        type='button'
        minWidth='2.75rem'
        h='2.75rem'
        py={1}
        px={3}
        alignItems='center'
        borderRadius='0'
        borderColor='primary'
        fontWeight='normal'
        color='white'
        borderBottomWidth='2px'
        borderLeftWidth='2px'
        d={['inline-flex', 'none']}
        onClick={onOpen}
      >
        <IconFilters boxSize='6' mr='2' />
        <VisuallyHidden>Filtres</VisuallyHidden>
        {nbFilters}
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen} size='full'>
        <DrawerOverlay>
          <DrawerContent bg='bg.dashboard' color='white'>
            <DrawerHeader
              d='flex'
              bg='bg.homepage'
              borderBottomWidth='1px'
              borderBottomColor='gray.medium'
              py={2}
            >
              <IconButton
                icon={<IconArrowLeft />}
                aria-label='Fermer'
                color='white'
                bg='transparent'
                px='0'
                mr={2}
                onClick={onClose}
              />
              <Heading textStyle='section'>Filtres</Heading>
              <Button
                type='reset'
                color='error'
                bg='transparent'
                ml='auto'
                px='0'
                fontWeight='500'
                onClick={() => {
                  resetForm({ values: initialValues });
                }}
              >
                Effacer les filtres
              </Button>
            </DrawerHeader>
            <DrawerBody pt={4}>
              <VStack
                divider={<StackDivider borderColor='gray.default' />}
                spacing={8}
                pb={16}
                align='stretch'
              >
                <FormCheckboxGroup
                  name='byRemunerations'
                  legend={
                    <chakra.span color='primary' fontSize='20px'>
                      <IconCurrency
                        verticalAlign='baseline'
                        mr={2}
                        boxSize={4}
                      />
                      TJM
                    </chakra.span>
                  }
                >
                  {constants?.tjm?.map(tjm => (
                    <FormCheckbox
                      key={tjm}
                      checkboxStyle={{
                        py: 1
                      }}
                      value={tjm}
                    >
                      {tjm}
                    </FormCheckbox>
                  ))}
                </FormCheckboxGroup>
                <FormCheckboxGroup
                  name='byDurations'
                  legend={
                    <chakra.span color='primary' fontSize='20px'>
                      <IconTime verticalAlign='baseline' mr={2} boxSize={4} />
                      Durée
                    </chakra.span>
                  }
                >
                  {constants?.missionTimes?.map(missionTime => (
                    <FormCheckbox
                      key={missionTime}
                      checkboxStyle={{ py: 1 }}
                      value={missionTime}
                    >
                      {missionTime}
                    </FormCheckbox>
                  ))}
                </FormCheckboxGroup>
                <Box>
                  <FormCheckboxGroup
                    as={Box}
                    name='byAsap'
                    legend={
                      <chakra.span color='primary' fontSize='20px'>
                        <IconCalendar
                          verticalAlign='baseline'
                          mr={2}
                          boxSize={4}
                        />
                        Date de début
                      </chakra.span>
                    }
                    mb={4}
                  />
                  <Field
                    as={FormCheckbox}
                    type='checkbox'
                    name='byAsap'
                    checkboxStyle={{ py: 1 }}
                  >
                    Au plus tôt
                  </Field>
                  <Divider mt={4} borderColor='black.light' />
                  <Text mt={4} d='block'>
                    À partir de :
                  </Text>
                  <FormDatepicker
                    name='byStartDate'
                    showMonthYearPicker
                    inline
                    dark
                  />
                </Box>
                <FormCheckboxGroup
                  name='byExperiences'
                  legend={
                    <chakra.span color='primary' fontSize='20px'>
                      <IconExperience
                        verticalAlign='baseline'
                        mr={2}
                        boxSize={4}
                      />
                      Expériences
                    </chakra.span>
                  }
                >
                  {constants?.experiences?.map(exp => (
                    <FormCheckbox
                      key={exp}
                      checkboxStyle={{ py: 1 }}
                      value={exp}
                    >
                      {exp} ans
                    </FormCheckbox>
                  ))}
                </FormCheckboxGroup>
                <FormCheckboxGroup
                  name='bySectors'
                  legend={
                    <chakra.span color='primary' fontSize='20px'>
                      <IconCog verticalAlign='baseline' mr={2} boxSize={4} />
                      Secteurs
                    </chakra.span>
                  }
                >
                  {constants?.sectors?.map(sector => (
                    <FormCheckbox
                      key={sector}
                      checkboxStyle={{ py: 1 }}
                      value={sector}
                    >
                      {sector}
                    </FormCheckbox>
                  ))}
                </FormCheckboxGroup>
              </VStack>
              <Button
                type='submit'
                colorScheme='primary'
                size='full'
                position='fixed'
                bottom='0'
                left='0'
                right='0'
                onClick={e => {
                  onClose(e);
                }}
              >
                Sauvegarder
              </Button>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
