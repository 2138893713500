const colors = {
  primary: '#F8D885',
  secondary: '#FFB19E',
  error: '#FF4572',
  success: '#51BA94',
  orange: '#FFB19E',
  black: {
    dark: '#000000',
    light: '#393D44'
  },
  gray: {
    light: '#959595',
    medium: '#5C5C5C',
    dark: '#36393F',
    border: '#F1F1F1',
    default: '#6B6B6B'
  },
  bg: {
    homepage: '#25282D',
    dashboard: '#181C22'
  }
};

export default colors;
