import { Heading, Link } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';
import React from 'react';
import { Container } from '../../common/components/Grid';
import { PublicLayout } from '../../common/layouts/PublicLayout';

export const LegalMentions = () => {
  return (
    <PublicLayout>
      <Container>
        <chakra.section
          __css={{
            lineHeight: 1.5,
            'h2:not(:first-of-type)': {
              mt: 8,
              fontSize: '2xl',
              fontWeight: 'medium',
              color: 'primary'
            },
            p: { my: 3 },
            a: {
              textDecor: 'underline'
            }
          }}
        >
          <Heading textStyle='h1' mb={10} fontSize='4xl'>
            Mentions Légales
          </Heading>
          <Heading textStyle='h2'>Edition du site internet</Heading>
          <p>
            Le site{' '}
            <Link href='https://www.all-ffi.com/'>
              https://www.all-ffi.com/
            </Link>{' '}
            (ci-après 'le Site') est édité par la société ABSI CONSULTING
            (ci-après l’Editeur), SARL au capital social de 1&nbsp;000 € dont le
            siège social est situé 52 boulevard de Charonne Paris 75020
          </p>
          <p>
            Email :{' '}
            <Link href='mailto:contact@all-ffi.com'>contact@all-ffi.com</Link>
            <br />
            RCS PARIS 820985133
            <br />
            TVA intracommunautaire FR 87820985133
            <br />
            Le directeur de la publication est M. Alain Behar.
          </p>
          <Heading textStyle='h2'>Hébergeur du site internet</Heading>
          <p>
            Le site{' '}
            <Link href='https://www.all-ffi.com/'>
              https://www.all-ffi.com/
            </Link>{' '}
            est hébergé par la société OVH, SAS dont le siège social est situé 2
            rue Kellermann, 59100 Roubaix, France, sur un serveur situé à
            Gravelines, France.
          </p>
          <Heading textStyle='h2'>Respect de la propriété individuelle</Heading>
          <p>
            Toutes les marques, photographies, textes, commentaires, images
            animées ou non, séquences vidéo, sons, ainsi que toutes les
            applications informatiques qui pourraient être utilisées pour faire
            fonctionner le Site et plus généralement tous les éléments
            reproduits ou utilisés sur le Site sont protégés par les lois en
            vigueur au titre de la propriété intellectuelle.
            <br />
            Ils sont la propriété pleine et entière de l’Éditeur ou de ses
            partenaires, sauf mentions particulières. Toute reproduction,
            représentation, utilisation ou adaptation, sous quelque forme que ce
            soit, de tout ou partie de ces éléments, y compris les applications
            informatiques, sans l’accord préalable et écrit de l’Éditeur, sont
            strictement interdites. Le fait pour l’Éditeur de ne pas engager de
            procédure dès la prise de connaissance des utilisations non
            autorisées ne vaut pas acceptation desdites utilisations et
            renonciation aux poursuites.
            <br />
            Seule l’utilisation pour un usage privé dans un cercle de famille
            est autorisée et toute autre utilisation est constitutive de
            contrefaçon et/ou d’atteinte aux droits voisins, sanctionnées par
            Code de la propriété intellectuelle. La reprise de tout ou partie de
            ce contenu nécessite l’autorisation préalable de l’Éditeur ou du
            titulaire des droits sur ce contenu.
          </p>
          <Heading textStyle='h2'>Liens hypertextes</Heading>
          <p>
            Le Site peut contenir des liens hypertextes donnant accès à d’autres
            sites web édités et gérés par des tiers et non par l’Éditeur.
            L’Éditeur ne pourra être tenu responsable directement ou
            indirectement dans le cas où lesdits sites tiers ne respecteraient
            pas les dispositions légales. La création des liens hypertextes vers
            le Site ne peut être faite qu’avec l’autorisation écrite et
            préalable de l’Éditeur.
          </p>
        </chakra.section>
      </Container>
    </PublicLayout>
  );
};
