import React, { createRef, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormInput, FormPasswordInput } from '../../../common/components/Form';
import { history } from '../../../common/routes/history';
import { routes } from '../../../common/routes';
import { toastOptions } from '../../../common/components/Toast';
import { updateUser } from '../../auth/actions';
import { useAuthDispatch, useAuthState } from '../../auth';
import { FormSection } from '../components/Form';
import { uploadFile } from '../../../common/utils/uploadFile';

export const General = () => {
  const toast = useToast();

  const passwordRef = createRef();
  const idDocumentRef = createRef();

  const [idDocumentKey, setIdDocumentKey] = useState(null);
  const { isOpen, onToggle } = useDisclosure();

  const dispatch = useAuthDispatch();
  const { user, hasFullAccess } = useAuthState();

  return (
    <Formik
      initialValues={{
        ...user,
        password: '',
        passwordConfirmation: ''
      }}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      onSubmit={(values, actions) => {
        updateUser(dispatch, {
          ...values,
          password: values.password || undefined,
          passwordConfirmation: values.password
            ? values.passwordConfirmation
            : undefined,
          id_document_key: values.idDocument ? `${idDocumentKey}` : undefined,
          id_document_filename: values.idDocument
            ? `${values.idDocument}`
            : undefined
        })
          .then(() => {
            actions.setSubmitting(false);
            if (values.password !== '') {
              history.push(routes.logout);
              actions.setFieldValue('password', '');
              actions.setFieldValue('passwordConfirmation', '');
            }
            toast(
              toastOptions({
                description: 'Informations générales sauvegardées',
                status: 'success'
              })
            );
          })
          .catch(err => {
            actions.setSubmitting(false);
            if (err.response) {
              actions.setTouched({});
              actions.setErrors(err.response?.data?.errors);
            }
          });
      }}
    >
      {formik => (
        <Form>
          <Heading as='h1' textStyle='section'>
            Informations personnelles
          </Heading>
          <VStack spacing='formSection' align='stretch' mt='formTitle'>
            <FormSection>
              <FormInput label='Nom' name='lastName' placeholder='Nom' />
              <FormInput label='Prénom' name='firstName' placeholder='Prénom' />
              <FormInput
                type='email'
                label='Email'
                name='email'
                placeholder='ffi@contact.fr'
              />
              <FormInput
                type='tel'
                label='Téléphone'
                name='phoneNumber'
                placeholder='0619283746'
              />

              <Box>
                <FormLabel mt={6} htmlFor='idDocument'>
                  Pièce d'identité
                </FormLabel>
                <Button
                  type='button'
                  htmlFor='idDocument'
                  as={FormLabel}
                  variant='rounded'
                  size='lg'
                  d='inline-flex'
                  colorScheme='secondary'
                  mb={0}
                  whiteSpace='inherit'
                >
                  Mettre à jour votre pièce d'identité
                </Button>
                <Text as='small'>{formik.values.idDocument}</Text>
                <Input
                  ref={idDocumentRef}
                  type='file'
                  name='idDocument'
                  id='idDocument'
                  display='none'
                  onChange={async event => {
                    formik.setFieldTouched('idDocument', false);
                    if (event.currentTarget.files.length > 0) {
                      const filename = event.currentTarget.files[0].name;
                      uploadFile({
                        file: event.currentTarget.files[0]
                      })
                        .then(key => {
                          setIdDocumentKey(key);
                          formik.setFieldTouched('idDocument', true);
                          formik.setFieldValue('idDocument', filename);
                        })
                        .catch(e => {
                          formik.setFieldTouched('idDocument', true);
                          formik.setFieldError(
                            'idDocument',
                            e.response?.data?.errors ||
                              "Le chargement de votre pièce d'identité à échouer, merci de recommencer."
                          );
                        });
                    }
                  }}
                />
                <FormControl>
                  <FormErrorMessage>
                    <ErrorMessage name='idDocument' />
                  </FormErrorMessage>
                </FormControl>
                {user.idDocumentUrl && (
                  <>
                    <Text mt={2}>
                      Votre votre pièce d'identité actuelle est :{' '}
                      <Text as='strong'>{user.idDocumentFilename}</Text>{' '}
                      <Link
                        color='primary'
                        href={user.idDocumentUrl}
                        target='_blank'
                        rel='noreferrer'
                      >
                        voir
                      </Link>
                    </Text>
                    <Alert
                      status='info'
                      variant='solid'
                      background='gray.dark'
                      rounded='md'
                      my={8}
                    >
                      <AlertIcon flexShrink={0} />
                      <AlertDescription>
                        {hasFullAccess
                          ? "Pièce d'identité validée."
                          : "Votre pièce d'identité est en cours de validation."}
                      </AlertDescription>
                    </Alert>
                  </>
                )}
              </Box>
            </FormSection>

            <Box>
              <Button
                colorScheme='secondary'
                variant='ghost'
                onClick={() => {
                  onToggle();
                  if (!isOpen) {
                    const ref = passwordRef.current;

                    setTimeout(() => {
                      ref.scrollIntoView({ behavior: 'smooth' });
                    }, 300);

                    passwordRef.current.focus();
                  }
                }}
                mt={10}
                mb={isOpen ? null : -12}
                px={0}
                color='primary'
                textDecoration='underline'
                _hover={{ color: 'white' }}
              >
                Modifier mon mot de passe
              </Button>
            </Box>

            <Box w='full'>
              <Collapse in={isOpen}>
                <FormSection>
                  <FormPasswordInput
                    ref={passwordRef}
                    type='password'
                    label='Nouveau mot de passe'
                    name='password'
                    autoComplete='new-password'
                    placeholder='*******'
                  />
                  <FormPasswordInput
                    type='password'
                    label='Confirmation de mot de passe'
                    name='passwordConfirmation'
                    placeholder='*******'
                  />
                </FormSection>
              </Collapse>
            </Box>
          </VStack>
          <Box textAlign={{ md: 'right' }} mt='formSubmit'>
            <Button
              colorScheme='primary'
              variant='rounded'
              size='xl'
              type='submit'
              alignSelf={{ md: 'flex-end' }}
              isLoading={formik.isSubmitting}
            >
              Sauvegarder
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
