import React, { useReducer } from 'react';
import { initialState, AuthReducer } from './reducer';

const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

export function useAuthState () {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

export function useAuthDispatch () {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

const profileWhiteList = [
  'id',
  'createdAt',
  'updatedAt',
  'cvUrl',
  'email',
  'firstName',
  'lastName',
  'searchAvailability',
  'confirmedAt',
  'isJunior',
  'registrationStatus',
  'idDocumentUrl',
  'idDocumentFilename',
  'corporateName',
  'sirenOrSiret',
  'presentationPreference'
];

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  const profileCompletion =
    Object.entries(user.user).filter(([key, value]) => {
      if (!profileWhiteList.find(str => str === key)) {
        switch (key) {
          case 'locations':
          case 'searchJobs':
          case 'actualJob':
            return value && value.length > 0;
          case 'references':
            return user.user.isJunior ? true : value && value.length > 0;
          default:
            return !!value;
        }
      }
      return false;
    })?.length /
    Object.entries(user.user).filter(
      ([key, value]) => !profileWhiteList.find(str => str === key)
    )?.length;

  const hasFullAccess = user.user.registrationStatus === 'verified';

  return (
    <AuthStateContext.Provider
      value={{ ...user, hasFullAccess, profileCompletion }}
    >
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
