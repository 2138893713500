import React from 'react';
import { Box, Heading, Image, Text } from '@chakra-ui/core';
import { Card } from '../../../common/components/Card';

import heroImage from '../../../common/assets/images/hero.jpg';
import heroImage2x from '../../../common/assets/images/hero@2x.jpg';

export const Hero = () => {
  return (
    <Box
      as='section'
      d={{ md: 'grid' }}
      gridTemplateColumns={{ md: '1fr auto' }}
      gridTemplateRows={{ md: '1fr auto' }}
      h={{ md: '100vh' }}
      mt='calc(var(--header-height) * -1 - 1rem)'
    >
      <Image
        pointerEvents='none'
        gridColumn={{ md: '1/-1' }}
        gridRow={{ md: '1/-1' }}
        srcSet={`${heroImage} 1x,${heroImage2x} 2x`}
        objectFit='cover'
        objectPosition={{ base: '30%', xl: 'center' }}
        h={{ base: '50vh', md: '100vh' }}
        w='100vw'
        maxW='none'
        pos={{ base: 'absolute', md: 'static' }}
        top={0}
        zIndex={-1}
        alt='by José Antoine Costa'
      />
      <Box
        d={{ base: 'block', md: 'none' }}
        pt='96px'
        h='50vh'
        px={8}
        zIndex={1}
      />
      <Text
        gridColumn={{ md: '1 / span 1' }}
        gridRow={{ md: '2 / span 1' }}
        alignSelf='end'
        px={2}
        pb={2}
        position={['absolute', null, 'static']}
        transform={['translateY(-100%)', null, 'none']}
        fontWeight='300'
        fontSize='0.75rem'
        opacity='50%'
      >
        © José Antoine Costa
      </Text>
      <Card
        gridColumn={{ md: '2/-1' }}
        gridRow={{ md: '2/-1' }}
        minW={{ base: '100%', md: 'auto' }}
        w='max-content'
        maxW={{ base: '100%', md: '600px', xxl: '800px' }}
        variant='no-col'
        pt={{ md: 8 }}
        boxShadow='0'
      >
        <Heading
          textStyle='mega'
          fontSize={['3rem', null, '6xl']}
          mb='4'
          lineHeight='1'
        >
          ALL
        </Heading>
        <Text mb='2' fontSize={{ base: 'md', md: 'lg', xxl: 'inherit' }}>
          La plateforme de la Fédération Française des indépendants.
          <br />
          Faite par les indépendants, pour les indépendants.
        </Text>
      </Card>
    </Box>
  );
};
