const Card = {
  baseStyle: {
    padding: { base: '32px', md: '40px' },
    backgroundColor: 'black.light',
    boxShadow: 'card'
  },

  sizes: null,

  variants: {
    outline: props => ({
      px: { base: 6, md: 8 },
      borderWidth: 1,
      borderColor: props.colorScheme === 'isInvalid' ? 'error' : 'gray.default',
      borderRadius: 8
    })
  },

  defaultProps: null
};

export default Card;
