/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Global,
  ListItem,
  Link,
  Button,
  HStack,
  List,
  IconButton,
  useDisclosure,
  Slide,
  VStack,
  Stack,
  Image,
  useBreakpointValue
} from '@chakra-ui/core';
import { Container } from '../components/Grid';
import { getDashboardPath, routes } from '../routes';
import { IconClose, IconMenu } from '../components/Icons';
import { ErrorBoundary } from '../../features/errors/ErrorBoundary';
import Logo from '../assets/images/logo-home.svg';
import LogoWithoutText from '../assets/images/logo-dashboard.svg';
import { useAuthState } from '../../features/auth';

export const PublicLayout = ({ children, ...props }) => {
  return (
    <Box
      textStyle='body'
      d='flex'
      flexDir='column'
      minH='100vh'
      fontSize={['md', 'lg', null, null, null, 'xl']}
      pt='var(--header-height)'
      {...props}
    >
      <Global
        styles={theme => ({
          body: { backgroundColor: theme.colors.bg.homepage }
        })}
      />
      <Header />
      <Box as='main' flexGrow='1' pt={4} zIndex='1'>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
      <Footer />
    </Box>
  );
};

const Header = props => {
  const [isStucked, setIsStucked] = useState(false);
  const stickyRef = React.useRef();
  const { isOpen, onToggle } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, xl: false });
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const headerHeight = useBreakpointValue({
    base: 65,
    md: 76,
    lg: 92,
    xl: 92,
    xxl: 92
  });
  const { user } = useAuthState();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--header-height',
      `${headerHeight}px`
    );
  }, [headerHeight]);

  const handleScroll = () => {
    if (stickyRef.current) {
      setIsStucked(
        (document.documentElement.scrollTop || document.body.scrollTop) >= 10
      );
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // unobserve on unmount
    return function () {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const Nav = () => (
    <>
      <ListItem>
        <PublicNavLink
          as={Link}
          href={`${routes.home}#politique-ffi`}
          onClick={onToggle}
        >
          Politique FFI
        </PublicNavLink>
      </ListItem>
      <ListItem>
        <PublicNavLink
          as={Link}
          href={`${routes.home}#axes-commerciaux`}
          onClick={onToggle}
        >
          Axes commerciaux
        </PublicNavLink>
      </ListItem>
      <ListItem>
        <PublicNavLink to={routes.discovery}>
          Découverte artistique
        </PublicNavLink>
      </ListItem>
      <ListItem mb={{ base: 10, sm: 0 }}>
        <PublicNavLink as={Link} href='mailto:contact@all-ffi.com'>
          Contact
        </PublicNavLink>
      </ListItem>
      {user ? (
        <>
          <ListItem d={{ base: 'block', sm: 'none' }}>
            <PublicNavLink
              to={getDashboardPath()}
              color='primary'
              fontWeight='semibold'
              _hover={{ color: 'secondary' }}
              pos='relative'
            >
              ALL FFI
            </PublicNavLink>
          </ListItem>
          <ListItem d={{ base: 'block', sm: 'none' }}>
            <PublicNavLink to={routes.logout} pos='relative'>
              Déconnexion
            </PublicNavLink>
          </ListItem>
        </>
      ) : (
        <>
          <ListItem d={{ base: 'block', sm: 'none' }}>
            <PublicNavLink
              to={routes.login}
              color='primary'
              fontWeight='semibold'
              _hover={{ color: 'secondary' }}
              pos='relative'
            >
              Connexion
            </PublicNavLink>
          </ListItem>
          <ListItem d={{ base: 'block', sm: 'none' }}>
            <Button
              as={NavLink}
              to={routes.register}
              colorScheme='primary'
              size='md'
              fontSize='inherit'
              w='100px'
            >
              Adhérer
            </Button>
          </ListItem>
        </>
      )}
    </>
  );

  return (
    <Box
      ref={stickyRef}
      as='header'
      pt={{ base: 2, lg: isStucked ? 2 : 6 }}
      pb={2}
      bgColor={isStucked ? 'bg.homepage' : null}
      shadow={isStucked ? 'card' : null}
      zIndex={isStucked ? 'banner' : 10}
      pos='fixed'
      top='0'
      width='100%'
      transitionTimingFunction='ease'
      transitionDuration='normal'
      transitionProperty='padding, background-color'
    >
      <Box
        px={{ base: 6, md: 8 }}
        maxW={{ lg: '1364px', xxl: '1600px' }}
        mx='auto'
      >
        <Flex as='nav' alignItems='center'>
          <Link
            as={NavLink}
            to={routes.home}
            _focus={{ '&:not(:focus-visible)': { outline: 0 } }}
            pos='relative'
            zIndex='docked'
          >
            <Image
              width='auto'
              height={isStucked ? 49 : { base: '49px', md: '60px', xl: '80px' }}
              transitionTimingFunction='ease'
              transitionDuration='normal'
              transitionProperty='height'
              src={Logo}
              alt='Logo - Fédération Française des Indépendants'
            />
          </Link>

          <Box
            as={Slide}
            direction='right'
            in={isOpen}
            d={{ base: 'block', xl: 'none' }}
          >
            <Box p={8} bg='bg.homepage' shadow='card' h='100vh'>
              <VStack as={List} align='start' spacing={6} mt={20}>
                <PublicNavLink to={routes.home} exact>
                  Accueil
                </PublicNavLink>
                <Nav />
              </VStack>
            </Box>
          </Box>
          <HStack
            as={List}
            alignItems='center'
            fontSize={{ base: 'sm', '2xl': 'xl' }}
            spacing={{ base: 5, md: 6, lg: 7 }}
            ml='auto'
          >
            {!isMobile ? <Nav /> : null}
            {user ? (
              <>
                <ListItem d={{ base: 'none', sm: 'block' }}>
                  <PublicNavLink
                    to={getDashboardPath()}
                    color='primary'
                    fontWeight='semibold'
                    _hover={{ color: 'secondary' }}
                    ml={{ base: 5, lg: 10 }}
                    pos='relative'
                  >
                    ALL FFI
                  </PublicNavLink>
                </ListItem>
                <ListItem d={{ base: 'none', sm: 'block' }}>
                  <PublicNavLink to={routes.logout} pos='relative'>
                    Déconnexion
                  </PublicNavLink>
                </ListItem>
              </>
            ) : (
              <>
                <ListItem d={{ base: 'none', sm: 'block' }}>
                  <PublicNavLink
                    to={routes.login}
                    color='primary'
                    fontWeight='semibold'
                    _hover={{ color: 'secondary' }}
                    ml={{ base: 5, lg: 10 }}
                    pos='relative'
                  >
                    Connexion
                  </PublicNavLink>
                </ListItem>
                <ListItem d={{ base: 'none', sm: 'block' }}>
                  <Button
                    as={NavLink}
                    to={routes.register}
                    colorScheme='primary'
                    size={isDesktop ? 'md' : 'sm'}
                    fontSize='inherit'
                    px='1.125em'
                    py='1em'
                    minW='100px'
                  >
                    Adhérer
                  </Button>
                </ListItem>
              </>
            )}
          </HStack>
          <IconButton
            onClick={onToggle}
            h='1.75rem'
            w='1.75rem'
            px={0}
            icon={
              isOpen ? ( // eslint-disable-line
                <IconClose
                  height='1.25rem'
                  width='1.25rem'
                  ml='0.15rem'
                  mt='0.15rem'
                />
              ) : (
                <IconMenu h='1.75rem' w='1.75rem' />
              )
            }
            aria-label={isOpen ? 'Fermer' : 'Menu'}
            variant='ghost'
            colorScheme='white'
            color='white'
            zIndex='10'
            d={{ base: 'inline-block', xl: 'none' }}
            ml={5}
          />
        </Flex>
      </Box>
    </Box>
  );
};

const PublicNavLink = ({ children, ...props }) => {
  return (
    <Link
      as={NavLink}
      _hover={{ color: 'primary', textDecor: 'none' }}
      _activeLink={{ color: 'primary' }}
      {...props}
    >
      {children}
    </Link>
  );
};

const PublicFooterNavLink = props => (
  <PublicNavLink {...props} _activeLink={null} />
);

const Footer = props => {
  return (
    <Box as='footer' py={12} textStyle='mini'>
      <Container>
        <Box textAlign={{ base: 'center', md: 'initial' }}>
          <Link
            as={NavLink}
            to={routes.home}
            _focus={{ '&:not(:focus-visible)': { outline: 0 } }}
            d='inline-block'
          >
            <Image
              src={LogoWithoutText}
              height='60px'
              alt='Logo - Fédération Française des Indépendants'
            />
          </Link>
        </Box>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          w={{ base: 'full', lg: 9.5 / 12 }}
          justify='space-between'
          spacing={{ base: 12, md: 0 }}
          mt={8}
        >
          <VStack
            as={List}
            align={{ base: 'center', md: 'flex-start' }}
            spacing={{ base: 4, md: 6 }}
          >
            <ListItem>
              <PublicFooterNavLink to={routes.discovery}>
                Découverte artistique
              </PublicFooterNavLink>
            </ListItem>
            <ListItem>
              <PublicFooterNavLink as='a' href='mailto:contact@all-ffi.com'>
                Contact
              </PublicFooterNavLink>
            </ListItem>
          </VStack>
          <VStack
            as={List}
            align={{ base: 'center', md: 'flex-start' }}
            spacing={{ base: 4, md: 6 }}
          >
            <ListItem>
              <PublicFooterNavLink to={routes.login}>
                Connexion
              </PublicFooterNavLink>
            </ListItem>
            <ListItem>
              <PublicFooterNavLink to={routes.register}>
                Inscription
              </PublicFooterNavLink>
            </ListItem>
          </VStack>
          <VStack
            as={List}
            align={{ base: 'center', md: 'flex-start' }}
            spacing={{ base: 4, md: 8 }}
            color='gray.default'
            fontSize='0.85em'
          >
            <ListItem>
              <PublicFooterNavLink to={routes.legalMentions}>
                Mentions légales
              </PublicFooterNavLink>
            </ListItem>
            <ListItem>Copyrights © 2020</ListItem>
          </VStack>
        </Stack>
      </Container>
    </Box>
  );
};
