import React, { Component } from 'react';
import { createStandaloneToast } from '@chakra-ui/core';
import { api, apiV1 } from '../../common/api/api';
import { PageError } from './PageError';
import { history } from '../../common/routes/history';
import { routes } from '../../common/routes';
import { toastOptions } from '../../common/components/Toast';

const toast = createStandaloneToast();

export class ErrorBoundary extends Component {
  constructor (props) {
    super(props);
    this.state = { error: null, errorInfo: null };

    history.listen((location, action) => {
      // clear erorr on location change
      this.setState({ error: null, errorInfo: null });
    });
  }

  componentDidCatch (error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  handleRequest (req) {
    this.setState({ error: null });
    return req;
  }

  handleResponseError (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      const { status } = error.response;

      switch (status) {
        case 400:
          toast(
            toastOptions({
              description: 'Une erreur est survenue',
              status: 'error'
            })
          );
          break;
        case 401:
          if (history.location.pathname === routes.login) {
            return Promise.reject(error);
          }
          history.push(routes.logout);
          break;
        case 422:
          toast(
            toastOptions({
              description: 'Le formulaire saisi est incomplet ou erroné.',
              status: 'error'
            })
          );
          break;
        default:
          break;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
      history.push(routes.error.network);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
    this.setState({ error });
    return Promise.reject(error);
  }

  componentDidMount () {
    // Set axios interceptors
    this.apiRequestInterceptor = api.interceptors.request.use(req =>
      this.handleRequest(req)
    );

    this.apiResponseInterceptor = api.interceptors.response.use(
      res => res,
      err => this.handleResponseError(err)
    );

    this.apiV1RequestInterceptor = apiV1.interceptors.request.use(req =>
      this.handleRequest(req)
    );

    this.apiV1ResponseInterceptor = apiV1.interceptors.response.use(
      res => res,
      err => this.handleResponseError(err)
    );
  }

  componentWillUnmount () {
    // Remove handlers, so Garbage Collector will get rid of if WrappedComponent will be removed
    api.interceptors.request.eject(this.apiRequestInterceptor);
    api.interceptors.response.eject(this.apiResponseInterceptor);

    apiV1.interceptors.request.eject(this.apiV1RequestInterceptor);
    apiV1.interceptors.response.eject(this.apiV1ResponseInterceptor);
  }

  renderError () {
    const { error, errorInfo } = this.state;

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

      const { status } = error.response;

      switch (status) {
        case 404:
          return (
            <PageError
              title='Erreur 404'
              message='Page ou élément introuvable'
            />
          );
        case 500:
          return <PageError title='Erreur 500' message='Erreur serveur' />;
        default:
          return this.props.children;
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      return (
        <PageError title='Une erreur est survenue' textAlign='inherit'>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo && errorInfo.componentStack}
          </details>
        </PageError>
      );
    }
    console.log(error.config);
  }

  render () {
    const renderSection = this.state.error
      ? this.renderError()
      : this.props.children;
    return renderSection;
  }
}
