import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/core';

export const Card = ({ children, ...props }) => {
  const { variant, size, colorScheme, ...rest } = props;
  const styles = useStyleConfig('Card', { variant, size, colorScheme });

  return (
    <Box {...styles} {...rest}>
      {children}
    </Box>
  );
};
