const Button = {
  baseStyle: props => ({
    padding: '0.75em',
    backgroundColor: 'black.light',
    color: 'bg.homepage',
    fontWeight: 'bold',
    borderRadius: 0,
    ...colorScheme[props.colorScheme],
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed'
    }
  }),

  sizes: {
    md: {
      minWidth: 'auto',
      fontSize: '1rem'
    },
    lg: {
      minWidth: '176px',
      height: 'auto',
      fontSize: '1rem'
    },
    xl: {
      minWidth: { base: 'auto', sm: '304px' },
      width: { base: 'full', sm: 'auto' }
    },
    full: {
      width: '100%'
    }
  },

  variants: {
    rounded: {
      borderRadius: '0.25rem'
    },
    filter: {
      color: 'inherit',
      backgroundColor: 'none',
      borderLeftWidth: '1px',
      borderColor: 'gray.default',
      fontWeight: 'inherit',
      flexGrow: 1,
      flexBasis: 0,
      justifyContent: 'start',
      'span:first-of-type': {
        svg: {
          verticalAlign: 'text-top'
        }
      },
      'span:last-of-type:not(:first-of-type)': {
        ml: 'auto',
        pl: 4
      },
      _hover: {
        color: 'primary'
      },
      _expanded: {
        color: 'primary'
      }
    }
  },

  defaultProps: {
    colorScheme: null,
    variant: null,
    size: 'md'
  }
};

const colorScheme = {
  primary: {
    backgroundColor: 'primary',
    boxShadow: '2px 4px 10px rgba(0, 0, 0, 0.2)',
    _hover: {
      textDecoration: 'none',
      '&:not([disabled])': {
        backgroundColor: 'secondary',
        boxShadow: 'none'
      }
    },
    _active: {
      boxShadow: 'inset 1px 3px 8px rgba(255, 101, 101, 0.5)'
    }
  },
  secondary: {
    backgroundColor: 'gray.default',
    color: 'white',
    _hover: {
      textDecoration: 'none',
      '&:not([disabled])': {
        backgroundColor: 'black.light'
      }
    }
  }
};

export default Button;
