/* eslint-disable indent */
import React, { forwardRef, useState } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Stack,
  Switch,
  Text,
  Textarea,
  useCheckbox,
  useCheckboxGroup,
  useRadio,
  useRadioGroup
} from '@chakra-ui/core';
import { useField } from 'formik';
import NumberFormat from 'react-number-format';
import { IconDot, IconEyeOpened, IconEyeClosed } from '../Icons';

export const FormInput = forwardRef(({ label, iconRight, ...props }, ref) => {
  const { disabled } = props;
  const [field, meta, helpers] = useField(props);

  const isInvalid = (!meta.touched && meta.error) || props.isInvalid;

  const Control =
    props.type === 'checkbox'
      ? Switch
      : props.type === 'textarea'
      ? Textarea
      : props.type === 'number'
      ? NumberFormat
      : Input;

  const format = value => Intl.NumberFormat('fr-FR').format(value);

  return (
    <FormControl isDisabled={disabled} isInvalid={isInvalid}>
      {label && <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>}
      <Flex
        alignItems='center'
        pos='relative'
        __css={{ label: { lineHeight: 0, pos: 'relative' } }}
      >
        <Control
          ref={ref}
          {...field}
          {...props}
          isChecked={props.type === 'checkbox' ? !!meta.value : undefined}
          pr={props.iconRight ? 5 : null}
          // onChange={values => console.log(values)}
          {...(props.type === 'number'
            ? {
                type: 'text',
                customInput: Input,
                thousandSeparator: true,
                format,
                onValueChange: value => helpers.setValue(value)
              }
            : {})}
        />
        {props.type === 'checkbox' && (
          <Text ml={2} as='span' textStyle='mini'>
            {meta.value ? 'Oui' : 'Non'}
          </Text>
        )}
        {iconRight || null}
      </Flex>
      <FormErrorMessage flexDirection='column' alignItems='flex-start' mt={1}>
        {typeof meta.error === 'string'
          ? meta.error
          : meta.error?.map(error => <p key={error}>{error}</p>)}
      </FormErrorMessage>
    </FormControl>
  );
});

export const FormPasswordInput = forwardRef(({ ...props }, ref) => {
  const [isPassword, setIsPassword] = useState('password');

  const Icon = isPassword ? IconEyeOpened : IconEyeClosed;

  return (
    <FormInput
      ref={ref}
      iconRight={
        <IconButton
          variant='ghost'
          boxSize={8}
          rounded='full'
          px={0}
          py={0}
          _hover={{ bgColor: 'gray.dark', color: 'primary' }}
          pos='absolute'
          zIndex={2}
          right={2}
          top='50%'
          transform='translateY(-50%)'
          onClick={() => setIsPassword(!isPassword)}
          icon={<Icon />}
        />
      }
      {...props}
      type={isPassword ? 'password' : 'text'}
    />
  );
});

export const FormRadio = ({ radioStyle, ...props }) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label' d='inline-flex' verticalAlign='top' {...radioStyle}>
      <input {...input} />
      <Box
        {...checkbox}
        d='flex'
        justifyContent='center'
        alignItems='center'
        cursor='pointer'
        borderWidth='3px'
        borderRadius='full'
        boxSize='1.25em'
        borderColor='gray.light'
        _hover={{
          svg: {
            color: 'gray.medium',
            opacity: 0.5
          }
        }}
        _checked={{
          svg: {
            opacity: 1,
            color: 'primary'
          }
        }}
        _focus={{
          '&:not([data-checked])': {
            borderColor: 'primary'
          }
        }}
      >
        <Icon
          as={IconDot}
          opacity={0}
          width='0.65em'
          height='0.65em'
          transitionProperty='opacity'
          transitionDuration='0.2s'
        />
      </Box>
      <Box ml={2} mt='1px' textStyle='mini'>
        {props.children}
      </Box>
    </Box>
  );
};

export const FormRadioGroup = ({ legend, children, stackStyle, ...props }) => {
  const [field, meta] = useField({ ...props });

  const { disabled, name, defaultValue } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue: meta.value || defaultValue,
    onChange: field.onChange
  });

  const isInvalid = (meta.error && !meta.touched) || props.isInvalid;
  const childrenWithInvalid = React.Children.map(children, child => {
    const radio = getRadioProps({ value: child.props.value });

    return React.cloneElement(child, {
      isInvalid,
      disabled: child.props.disabled || disabled,
      ...radio
    });
  });

  const group = getRootProps();

  return (
    <FormControl
      as='fieldset'
      isInvalid={isInvalid}
      {...group}
      {...field}
      {...props}
    >
      <FormLabel as='legend' m='0 0 1rem 0'>
        {legend}
      </FormLabel>
      <Stack spacing={4} {...stackStyle} alignItems='flex-start'>
        {childrenWithInvalid}
      </Stack>
      <FormErrorMessage flexDirection='column' alignItems='flex-start' mt={1}>
        {typeof meta.error === 'string'
          ? meta.error
          : meta.error?.map(error => <p key={error}>{error}</p>)}
      </FormErrorMessage>
    </FormControl>
  );
};

export const FormCheckbox = ({ checkboxStyle, ...props }) => {
  const { getInputProps, getCheckboxProps } = useCheckbox({
    ...props,
    isChecked: Object.prototype.hasOwnProperty.call(props, 'isChecked')
      ? props.isChecked
      : props.checked
  });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label' d='inline-flex' verticalAlign='top' {...checkboxStyle}>
      <input {...input} />

      <Box
        {...checkbox}
        d='flex'
        justifyContent='center'
        alignItems='center'
        cursor='pointer'
        borderWidth='2px'
        borderRadius='0'
        boxSize='1.25em'
        borderColor='white'
        flexShrink='0'
        _hover={{
          svg: {
            color: 'gray.medium',
            opacity: 0.5
          }
        }}
        _checked={{
          svg: {
            opacity: 1,
            color: 'primary'
          }
        }}
        _focus={{
          '&:not([data-checked])': {
            borderColor: 'primary'
          }
        }}
      >
        <Icon
          opacity={0}
          width='0.65em'
          height='0.65em'
          viewBox='0 0 12 12'
          fill='currentcolor'
          transitionProperty='opacity'
          transitionDuration='0.2s'
          color='primary'
        >
          <path d='M0 0h12v12H0z' />
        </Icon>
      </Box>
      <Box ml={2} mt='1px' textStyle='mini'>
        {props.children}
      </Box>
    </Box>
  );
};

export const FormCheckboxGroup = ({
  legend,
  children,
  stackStyle,
  ...props
}) => {
  const [field, meta, helpers] = useField({ ...props });

  const { disabled, name, defaultValue } = props;
  const { getCheckboxProps, ...group } = useCheckboxGroup({
    name,
    defaultValue: meta.value || defaultValue,
    value: meta.value,
    onChange: nextValue => helpers.setValue(nextValue)
  });

  const isInvalid = meta.error && !meta.touched;
  const childrenWithInvalid = React.Children.map(children, child => {
    const checkbox = getCheckboxProps({ value: child.props.value, name });

    return React.cloneElement(child, {
      isInvalid,
      disabled: child.props.disabled || disabled,
      ...checkbox
    });
  });

  return (
    <FormControl
      as='fieldset'
      value={group.value}
      name={group.name}
      isInvalid={isInvalid}
      {...field}
      {...props}
    >
      {legend && (
        <FormLabel as='legend' m='0 0 1rem 0'>
          {legend}
        </FormLabel>
      )}
      <Stack {...stackStyle} alignItems='flex-start'>
        {childrenWithInvalid}
      </Stack>
      <FormErrorMessage flexDirection='column' alignItems='flex-start'>
        {typeof meta.error === 'string'
          ? meta.error
          : meta.error?.map(error => <p key={error}>{error}</p>)}
      </FormErrorMessage>
    </FormControl>
  );
};
