import React from 'react';
import { Button } from '@chakra-ui/button';
import { Box, Heading } from '@chakra-ui/layout';
import { chakra } from '@chakra-ui/system';

export const CarouselItem = ({ label, image, ...props }) => {
  return (
    <Button
      pos='relative'
      css={{
        display: 'block',
        width: '100%',
        height: 'auto',
        backgroundColor: 'transparent'
      }}
      px={0}
      py={0}
      border='1px solid transparent'
      boxShadow='md'
      borderColor='gray.dark'
      my={5}
      _hover={{
        borderColor: 'white',
        h2: {
          color: 'white',
          bg: 'rgba(0,0,0,0.5)'
        }
      }}
      _active={{
        borderColor: 'primary',
        boxShadow: 'lg',
        h2: {
          color: 'primary',
          bg: 'rgba(0,0,0,0.4)'
        }
      }}
      {...props}
    >
      <Box
        pt={{ base: '40%', md: '33%' }}
        bgImage={`url("${image.src}")`}
        bgPos='center'
        bgSize='cover'
      />
      <Heading
        bg='rgba(0,0,0,0.6)'
        pos='absolute'
        inset='0'
        d='flex'
        alignItems='center'
        justifyContent='center'
        textAlign='center'
        fontSize={{ base: 'xl', sm: '2xl', md: 'xl', lg: '3xl', xxl: '4xl' }}
        color='#ccc'
      >
        <chakra.span px={4}>{label}</chakra.span>
      </Heading>
    </Button>
  );
};
