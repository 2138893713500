import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton
} from '@chakra-ui/core';
import theme from '../../../App/theme';

const toastSuccess = {
  title: 'Succès',
  description: 'Opération effectuée avec succès',
  status: 'success'
};

const toastError = {
  title: 'Erreur',
  description: "Un problème est survenu lors de l'opération",
  status: 'error'
};

const Toast = ({
  onClose,
  title,
  description,
  isClosable = true,
  status = 'info',
  variant = 'solid',
  id
}) => {
  const STATUS_COLOR = {
    success: theme.colors.success,
    error: theme.colors.error
  };
  return (
    <Alert
      status={status}
      variant={variant}
      id={id}
      alignItems='start'
      borderRadius='md'
      boxShadow='lg'
      paddingRight={8}
      rounded='sm'
      textAlign='left'
      width='auto'
      bg={STATUS_COLOR[status]}
    >
      <AlertIcon />
      <Box flex='1'>
        {title && <AlertTitle>{title}</AlertTitle>}
        {description && (
          <AlertDescription display='block'>{description}</AlertDescription>
        )}
      </Box>
      {isClosable && (
        <CloseButton
          size='sm'
          onClick={onClose}
          position='absolute'
          right={1}
          top={1}
        />
      )}
    </Alert>
  );
};

const toastBaseOptions = options => {
  switch (options.status) {
    case 'success':
      return { ...toastSuccess, ...options };
    case 'error':
      return { ...toastError, ...options };
    default:
      return { status: 'info', ...options };
  }
};

export const toastOptions = ({ duration, isClosable = true, ...options }) => ({
  duration,
  isClosable,
  render: renderProps => (
    <Toast
      {...renderProps}
      {...toastBaseOptions({
        status: options.status,
        ...(options.title ? { title: options.title } : {}),
        ...(options.description ? { description: options.description } : {})
      })}
    />
  )
});
