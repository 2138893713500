export const routes = {
  error: {
    network: '/erreur-reseau'
  },
  home: '/',
  discovery: '/decouverte-artiste',
  login: '/connexion',
  logout: '/deconnexion',
  resetPassword: '/reinitialisation-mot-de-passe',
  register: '/inscription',
  contact: '/contact',
  legalMentions: '/mentions-legales',
  dashboard: {
    base: '/all',
    register: {
      base: '/adhesion'
    },
    missions: {
      base: '/missions'
    },
    profile: {
      base: '/mon-profil'
    },
    candidacies: {
      base: '/mes-candidatures'
    },
    propositions: {
      base: '/mes-propositions'
    }
  }
};

export const getDashboardPath = (path = []) => {
  let finalPath = routes.dashboard.base;
  let currentBase = routes.dashboard;

  for (let i = 0; i < path.length; i++) {
    const pathStep = path[i];

    if (currentBase[pathStep]) {
      finalPath += currentBase[pathStep].base || currentBase[pathStep];
      currentBase = currentBase[pathStep];
    }
  }

  return finalPath;
};
