import Axios from 'axios';
import { api, apiV1 } from './api';

export const getUser = () => apiV1.get('/users/show_current_user');

export const putUser = user => api.put('/users', { user });

export const getJobs = content =>
  apiV1.get('/users/search_jobs', { params: { content } });

export const getRegions = params =>
  Axios.get('https://geo.api.gouv.fr/regions', { params });

export const getDepartments = params =>
  Axios.get('https://geo.api.gouv.fr/departements', { params });

export const getCities = params =>
  Axios.get('https://geo.api.gouv.fr/communes', { params });
