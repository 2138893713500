/* eslint-disable indent, multiline-ternary */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  chakra,
  Flex,
  Heading,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import {
  FormInput,
  FormRadio,
  FormRadioGroup
} from '../../../common/components/Form';
import { FormSection } from '../components/Form';
import { FormDatepicker } from '../../../common/components/Form/FormDatepicker';
import { FormSelect } from '../../../common/components/Form/FormSelect';
import {
  getJobs,
  getRegions,
  getCities,
  getDepartments
} from '../../../common/api/profile';
import { getConstants } from '../../../common/api/config';
import { useAuthDispatch, useAuthState } from '../../auth';
import { updateUser } from '../../auth/actions';
import { toastOptions } from '../../../common/components/Toast';
import { getDateString } from '../../../common/utils';

export const Search = () => {
  const [industries, setIndustries] = useState(null);

  const toast = useToast();
  const dispatch = useAuthDispatch();
  const { user } = useAuthState();

  useEffect(() => {
    getConstants().then(({ data }) => {
      setIndustries(data.sectors.map(value => ({ label: value, value })));
    });
  }, []);

  const searchedJobs = inputValue =>
    getJobs(inputValue).then(({ data }) => {
      return data.map(value => ({ label: value, value }));
    });

  const searchedLocation = inputValue =>
    getRegions({ nom: inputValue, limit: 10 }).then(({ data: regions }) =>
      getDepartments({ nom: inputValue, limit: 10 }).then(
        ({ data: departments }) =>
          getCities({ nom: inputValue, limit: 10 }).then(({ data: cities }) => {
            const data = [
              ...regions.map(region => ({ ...region, locationType: 'region' })),
              ...departments.map(department => ({
                ...department,
                locationType: 'department'
              })),
              ...cities.map(city => ({ ...city, locationType: 'city' }))
            ];

            return data.map(value => ({
              regionCode:
                value.locationType === 'region'
                  ? value.code
                  : '' || value.codeRegion,
              department:
                value.locationType === 'department'
                  ? value.code
                  : '' || value.codeDepartement,
              inseeCode: value.locationType === 'city' ? value.code : undefined,
              name: value.nom,
              locationType: value.locationType
            }));
          })
      )
    );

  return (
    <Formik
      initialValues={{
        ...user,
        searchJobs: user.searchJobs.map(value => ({ label: value, value })),
        searchIndustry: user.searchIndustry
          ? {
              label: user.searchIndustry,
              value: user.searchIndustry
            }
          : null,
        availabilityDate: user.searchAvailability
          ? new Date(user.searchAvailability)
          : new Date(),
        searchAvailability: user.searchAvailability
          ? new Date(user.searchAvailability) <= new Date()
            ? 'Immédiate'
            : 'Date'
          : 'Pas de visibilité'
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);

        const deletedLocations = user.locations
          .map(initLoc => {
            if (
              values.locations &&
              values.locations.find(currLoc => currLoc.id === initLoc.id)
            ) {
              return null;
            }
            return { id: initLoc.id, _destroy: true };
          })
          .filter(loc => loc !== null);

        updateUser(dispatch, {
          ...values,
          searchJobs: values.searchJobs.map(job => job.value),
          locationsAttributes: [
            ...(values.locations ?? []),
            ...deletedLocations
          ],
          searchIndustry: values.searchIndustry?.value,
          searchPricing: values.searchPricing
            ? Number(values.searchPricing.split(' ').join(''))
            : null,
          searchMinimumPrice: values.searchMinimumPrice
            ? Number(values.searchMinimumPrice.split(' ').join(''))
            : null,
          searchAvailability:
            values.searchAvailability === 'Date'
              ? getDateString(values.availabilityDate)
              : values.searchAvailability === 'Immédiate'
              ? new Date()
              : null
        })
          .then(() => {
            toast(
              toastOptions({
                description: 'Recherche sauvegardée',
                status: 'success'
              })
            );
          })
          .catch(err => {
            actions.setSubmitting(false);
            if (err.response) {
              actions.setTouched({});
              actions.setErrors(err.response?.data?.errors);
            }
          });
      }}
    >
      {formik => (
        <Form>
          <Heading as='h1' textStyle='section'>
            Détails de votre recherche
          </Heading>

          <VStack spacing='formSection' align='stretch' mt='formTitle'>
            <FormSection title='Métier souhaité'>
              <FormSelect
                placeholder='Chef de projet'
                label='Quel métier espérez-vous pratiquer dans 5 prochaines années?'
                name='searchJobs'
                cacheOptions
                defaultOptions
                isCreatable
                isAsync
                isMulti
                creationText='créer un nouveau métier'
                noOptionsMessage={() => 'Tapez pour chercher'}
                loadOptions={searchedJobs}
              />
              <FormSelect
                placeholder='Information / Communication'
                label='Dans quel domaine ?'
                name='searchIndustry'
                noOptionsMessage={() => 'Tapez pour chercher'}
                options={industries}
              />
            </FormSection>
            <FormSection title='Titre'>
              <FormInput
                label='Quel titre ou poste souhaitez-vous afficher sur votre profil FFI ?'
                name='searchTitle'
                placeholder='Développeur.euse Front'
              />
            </FormSection>
            <FormSection title='Tarification souhaitée pour vous'>
              <FormInput
                type='number'
                label='Quel est votre tarif à la journée ? (€/jour)'
                name='searchPricing'
                placeholder='1000 (€/jour)'
              />
              <FormInput
                type='number'
                label='Quel est votre tarif plancher ? (€/jour)'
                name='searchMinimumPrice'
                placeholder='500 (€/jour)'
              />
            </FormSection>
            <FormSection title='Prochaine disponibilité'>
              <FormRadioGroup
                name='searchAvailability'
                legend='A quel horizon êtes-vous disponible pour une nouvelle mission ?'
                stackStyle={{ spacing: 6 }}
              >
                <FormRadio value='Immédiate'>Immédiate</FormRadio>
                <FormRadio
                  value='Pas de visibilité'
                  radioStyle={{ mb: { base: 0, md: -3 } }}
                >
                  Pas de visiblité mais je reste à l’écoute du marché
                </FormRadio>
                <FormRadio
                  value='Date'
                  radioStyle={{
                    alignItems: { base: 'flex-start', md: 'center' }
                  }}
                >
                  <Stack
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'flex-start', md: 'center' }}
                  >
                    <Text as='span' d='inline-block' minW='max-content'>
                      Disponible à partir de :{' '}
                    </Text>
                    <FormDatepicker name='availabilityDate' />
                  </Stack>
                </FormRadio>
              </FormRadioGroup>
            </FormSection>
            <FormSection title='Préférences localisation mission'>
              <FormSelect
                placeholder='Île-de-France'
                label='Ville(s) ou Région(s)'
                name='locations'
                cacheOptions
                defaultOptions
                isAsync
                isMulti
                noOptionsMessage={() => 'Tapez pour chercher'}
                loadOptions={searchedLocation}
                getOptionValue={option => {
                  return `${option.locationType}-${option.inseeCode ||
                    option.department ||
                    option.regionCode}`;
                }}
                getOptionLabel={option => (
                  <Flex align='baseline'>
                    {option.name}{' '}
                    {option.inseeCode ? (
                      <chakra.span fontSize='sm' paddingLeft={1}>
                        ({option.department})
                      </chakra.span>
                    ) : null}
                    <chakra.span
                      ml='auto'
                      fontSize='sm'
                      fontStyle='italic'
                      paddingLeft={1}
                    >
                      {option.locationType === 'city'
                        ? 'commune'
                        : option.locationType === 'department'
                        ? 'département'
                        : 'région'}
                    </chakra.span>
                  </Flex>
                )}
              />
              <FormRadioGroup name='searchRemote' legend='Télétravail'>
                <FormRadio value='Full remote'>Full remote</FormRadio>
                <FormRadio value='Alternance'>Alternance</FormRadio>
                <FormRadio value='Pas de télétravail'>
                  Pas de télétravail
                </FormRadio>
              </FormRadioGroup>
            </FormSection>
          </VStack>
          <Box textAlign={{ base: 'left', md: 'right' }} mt='4.625rem'>
            <Button
              colorScheme='primary'
              variant='rounded'
              size='xl'
              type='submit'
              ml={{ md: 'auto' }}
              isLoading={formik.isSubmitting}
            >
              Sauvegarder
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
