const parts = ['track', 'thumb'];

function baseStyleTrack (props) {
  const { colorScheme: c } = props;

  return {
    borderRadius: 'full',
    p: '2px',
    transition: 'all 120ms',
    bg: 'black.light',
    _focus: {
      '&:not(:focus-visible)': {
        boxShadow: 'none'
      }
    },
    _disabled: {
      opacity: 0.4,
      cursor: 'not-allowed'
    },
    _checked: {
      bg: c
    }
  };
}

const baseStyleThumb = {
  bg: 'gray.light',
  transition: 'transform 250ms',
  borderRadius: 'full',
  transform: 'translate(0, -50%)',
  position: 'absolute',
  top: '50%',
  left: '0'
};

const baseStyle = props => ({
  track: baseStyleTrack(props),
  thumb: baseStyleThumb
});

const sizes = {
  md: {
    track: { w: '2.75rem', h: '0.375rem' },
    thumb: {
      w: '1.5rem',
      h: '1.5rem',
      _checked: {
        transform: 'translate(1.5rem, -50%)'
      }
    }
  }
};

const defaultProps = {
  size: 'md',
  colorScheme: 'primary'
};

const Switch = {
  parts,
  baseStyle,
  sizes,
  defaultProps
};

export default Switch;
