/* eslint-disable multiline-ternary */
import React from 'react';
import { Box, Text, VStack } from '@chakra-ui/core';

export const FormSectionTitle = ({ children, ...props }) => {
  return (
    <Text
      textStyle='mini'
      fontWeight='bold'
      color='primary'
      mb={3}
      pos='relative'
      _before={{
        content: "''",
        display: 'block',
        height: 5,
        width: 1,
        bg: 'primary',
        position: 'absolute',
        right: 'calc(100% + 8px)',
        top: '0'
      }}
      {...props}
    >
      {children}
    </Text>
  );
};

export const FormSection = ({
  title,
  titleAs,
  children,
  spacing,
  ...props
}) => {
  return (
    <Box as='section' w={{ xl: 8 / 12 }} {...props}>
      {title ? (
        <FormSectionTitle as={titleAs || 'h2'}>{title}</FormSectionTitle>
      ) : null}
      <VStack align='stretch' spacing={spacing || 3}>
        {children}
      </VStack>
    </Box>
  );
};
