import React from 'react';
import { ChakraProvider } from '@chakra-ui/core';
import { Router, Switch } from 'react-router';
import theme from './theme';
import { Home } from '../features/home';
import { history } from '../common/routes/history';
import { routes } from '../common/routes';
import { Dashboard } from '../features/dashboard';
import { Login } from '../features/login';
import { Page404, PageErrorNetwork } from '../features/errors/PageError';
import { AuthProvider } from '../features/auth';
import { Logout } from '../features/logout';
import { AppRoute } from '../common/routes/AppRoute';
import { Register } from '../features/register';
import { ResetPassword } from '../features/resetPassword';
import { Artists } from '../features/artist';
import { PublicLayout } from '../common/layouts/PublicLayout';
import { LegalMentions } from '../features/legal-mentions';

export const App = () => {
  return (
    <Router history={history}>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <Switch>
            <AppRoute path={routes.home} exact component={Home} />
            <AppRoute path={routes.register} exact component={Register} />
            <AppRoute path={routes.login} exact component={Login} />
            <AppRoute path={routes.logout} exact component={Logout} />
            <AppRoute
              path={routes.resetPassword}
              exact
              component={ResetPassword}
            />
            <AppRoute path={routes.discovery} component={Artists} />
            <AppRoute
              path={routes.legalMentions}
              exact
              component={LegalMentions}
            />
            <AppRoute
              isPrivate
              path={routes.dashboard.base}
              component={Dashboard}
            />
            <AppRoute
              path={routes.error.network}
              component={PageErrorNetwork}
            />
            <AppRoute
              path='*'
              render={() => (
                <PublicLayout>
                  <Page404 />
                </PublicLayout>
              )}
            />
          </Switch>
        </ChakraProvider>
      </AuthProvider>
    </Router>
  );
};
