import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { routes } from '../../common/routes';
import { logoutUser, useAuthDispatch } from '../auth';

export const Logout = () => {
  const dispatch = useAuthDispatch();
  useEffect(() => {
    logoutUser(dispatch);
  }, [dispatch]);

  return <Redirect to={{ pathname: routes.login }} />;
};
