/* eslint-disable multiline-ternary */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { PrivateLayout } from '../../common/layouts/PrivateLayout';
import { PublicLayout } from '../../common/layouts/PublicLayout';
import { getDashboardPath } from '../../common/routes';
import { useAuthState } from '../auth';
import { Candidacies } from './candidacies';
import { Missions } from './missions';
import { Profile } from './profile';
import { Proposal } from './proposal';
import { Enrolment } from './enrolment';
import { Page404 } from '../errors/PageError';

export const Dashboard = props => {
  const { user, hasFullAccess } = useAuthState();

  if (user.registrationStatus === 'registered') {
    return (
      <PublicLayout>
        <Switch>
          <Route path={getDashboardPath(['register'])} component={Enrolment} />
          <Redirect to={getDashboardPath(['register'])} />
        </Switch>
      </PublicLayout>
    );
  }

  if (hasFullAccess) {
    return (
      <PrivateLayout>
        <Switch>
          <Redirect
            from={getDashboardPath()}
            to={getDashboardPath(['missions'])}
            exact
          />
          <Route
            path={getDashboardPath(['propositions'])}
            component={Proposal}
          />
          <Route path={getDashboardPath(['missions'])} component={Missions} />
          <Route
            path={getDashboardPath(['candidacies'])}
            component={Candidacies}
          />
          <Route path={getDashboardPath(['profile'])} component={Profile} />
          <Route path='*' render={Page404} />
        </Switch>
      </PrivateLayout>
    );
  }

  return (
    <PrivateLayout>
      <Switch>
        <Redirect
          from={getDashboardPath()}
          to={getDashboardPath(['profile'])}
          exact
        />
        <Redirect
          path={getDashboardPath(['register'])}
          to={getDashboardPath(['profile'])}
        />
        <Route path={getDashboardPath(['profile'])} component={Profile} />
        <Route path='*' render={Page404} />
      </Switch>
    </PrivateLayout>
  );
};
