/* eslint-disable multiline-ternary */
import React from 'react';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { Box } from '@chakra-ui/layout';
import { Redirect, Route, Switch } from 'react-router';
import { General } from './General';
import { Search } from './Search';
import { FormContainer } from '../components/Container';
import { TabNavigation } from '../components/TabNavigation';
import { Skills } from './Skills';
import { References } from './References';
import { Page404 } from '../../errors/PageError';
import { useAuthState } from '../../auth';

export const Profile = ({ match }) => {
  const { user } = useAuthState();

  return (
    <>
      <Box pos='sticky' top='0' zIndex='docked'>
        {user.registrationStatus === 'joined' ? (
          <Alert
            status='info'
            variant='solid'
            background='primary'
            color='gray.dark'
          >
            <AlertIcon flexShrink='0' ml={{ md: 2 }} mr={{ base: 3, md: 5 }} />
            <AlertDescription
              fontSize={{ base: 'xs', md: 'inherit' }}
              lineHeight={{ base: '1.25', md: 'inherit' }}
            >
              Votre compte est en cours de vérification, vous pouvez compléter
              votre profil en attendant sa validation par les équipes FFI.
              <br />
              Vous aurez accès à tout le contenu de la plateforme FFI dès que
              votre compte sera complètement activé
            </AlertDescription>
          </Alert>
        ) : null}

        {user.registrationStatus === 'rejected' ? (
          <Alert status='error' variant='solid' background='error'>
            <AlertIcon flexShrink='0' ml={{ md: 2 }} mr={{ base: 3, md: 5 }} />
            <AlertDescription
              fontSize={{ base: 'xs', md: 'inherit' }}
              lineHeight={{ base: '1.25', md: 'inherit' }}
            >
              Après vérification, actuellement les informations renseignées ne
              nous permettent pas de valider votre inscription. Si votre statut
              d'indépendant est en cours de finalisation, il vous faut
              préalablement terminer le processus avant d'adhérer à la FFI. Vous
              pouvez soumettre à nouveau votre demande d’inscription à tout
              moment.
            </AlertDescription>
          </Alert>
        ) : null}

        <TabNavigation
          items={[
            { label: 'Général', path: `${match.url}/general` },
            { label: 'Recherche', path: `${match.url}/rechercher` },
            { label: 'Compétences', path: `${match.url}/competences` },
            { label: 'Références', path: `${match.url}/references` }
          ]}
        />
      </Box>

      <FormContainer display='flex' flexDirection='column'>
        <Switch>
          <Redirect exact from={`${match.url}`} to={`${match.url}/general`} />
          <Route exact path={`${match.url}/general`} component={General} />
          <Route exact path={`${match.url}/rechercher`} component={Search} />
          <Route exact path={`${match.url}/competences`} component={Skills} />
          <Route
            exact
            path={`${match.url}/references`}
            component={References}
          />
          <Route path='*' component={Page404} />
        </Switch>
      </FormContainer>
    </>
  );
};
