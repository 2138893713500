import React, { createRef, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast
} from '@chakra-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import { Redirect } from 'react-router';
import { FormInput, FormPasswordInput } from '../../common/components/Form';
import { PublicLayout } from '../../common/layouts/PublicLayout';
import { Container } from '../../common/components/Grid';
import { Card } from '../../common/components/Card';
import { register } from '../../common/api/auth';
import { uploadFile } from '../../common/utils/uploadFile';
import { toastOptions } from '../../common/components/Toast';
import { useAuthState } from '../auth';
import { getDashboardPath } from '../../common/routes';

export const Register = () => {
  const resumeFileRef = createRef();
  const toast = useToast();
  const { user } = useAuthState();
  const [loading, setLoading] = useState(false);

  if (user) {
    return <Redirect to={getDashboardPath()} />;
  }

  return (
    <PublicLayout>
      <Container py={{ xxl: 12 }}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            resume: ''
          }}
          onSubmit={async (values, actions) => {
            setLoading(true);
            if (values.resume) {
              uploadFile({
                file: values.resume.file
              })
                .then(key => {
                  register({
                    ...values,
                    cvKey: values.resume.filename ? `${key}` : undefined,
                    cvFilename: values.resume.filename
                      ? `${values.resume.filename}`
                      : undefined
                  })
                    .then(() => {
                      setLoading(false);
                      actions.resetForm();

                      toast(
                        toastOptions({
                          description:
                            'Un mail a été envoyé à l’adresse indiquée, pour finaliser votre inscription cliquez sur le lien dans le mail.',
                          status: 'success',
                          duration: 15000
                        })
                      );
                    })
                    .catch(err => {
                      setLoading(false);
                      actions.setTouched({});

                      if (err.response) {
                        actions.setErrors(err.response?.data?.errors);
                      }
                    });
                })
                .catch(e => {
                  setLoading(false);
                  actions.setTouched({});

                  actions.setFieldTouched('resumeFile', true);
                  actions.setFieldError(
                    'resume',
                    e.response?.data?.errors ||
                      'Le chargement de votre CV à échouer, merci de recommencer.'
                  );
                });
            } else {
              register(values)
                .then(() => {
                  setLoading(false);
                  actions.resetForm();

                  toast(
                    toastOptions({
                      description:
                        'Un mail a été envoyé à l’adresse indiquée, pour finaliser votre inscription cliquez sur le lien dans le mail.',
                      status: 'success',
                      duration: 15000
                    })
                  );
                })
                .catch(err => {
                  setLoading(false);
                  actions.setTouched({});

                  if (err.response) {
                    actions.setErrors(err.response?.data?.errors);
                  }
                });
            }
          }}
        >
          {formik => (
            <Card
              w={{ base: 'auto', md: 3 / 4, lg: 1 / 2 }}
              mx={[-4, 'auto']}
              bg='bg.dashboard'
            >
              <Form>
                <Stack spacing={[4]}>
                  <Heading textStyle='h1'>Inscription</Heading>
                  {Object.values(formik.errors)?.length ? ( // eslint-disable-line
                    <Alert status='error' variant='solid' background='error'>
                      <AlertIcon />
                      <AlertDescription>
                        Un ou plusieurs champs sont invalides
                      </AlertDescription>
                    </Alert>
                  ) : null}
                  <FormInput label='Prénom *' name='firstName' />
                  <FormInput label='Nom *' name='lastName' />
                  <FormInput
                    type='email'
                    label='Email *'
                    name='email'
                    autoComplete='off'
                  />
                  <FormPasswordInput
                    type='password'
                    label='Mot de passe *'
                    name='password'
                    autoComplete='new-password'
                  />
                  <FormPasswordInput
                    type='password'
                    label='Confirmation du mot de passe *'
                    name='passwordConfirmation'
                    autoComplete='new-password'
                  />
                  <Flex wrap='wrap' alignItems='center' pt={4}>
                    <Button
                      type='button'
                      htmlFor='resumeFile'
                      as={FormLabel}
                      variant='rounded'
                      size='lg'
                      d='inline-flex'
                      colorScheme='secondary'
                      mb={0}
                    >
                      Télécharger un C.V.
                    </Button>
                    <Text as='small' py={2}>
                      {formik.values.resume.filename}
                    </Text>
                    <Input
                      ref={resumeFileRef}
                      type='file'
                      name='resumeFile'
                      id='resumeFile'
                      display='none'
                      onChange={async event => {
                        formik.setFieldTouched('resume', false);
                        if (event.currentTarget.files.length > 0) {
                          const file = event.currentTarget.files[0];
                          formik.setFieldTouched('resume', true);
                          formik.setFieldValue('resume', {
                            filename: file.name,
                            file
                          });
                        }
                      }}
                    />
                    <FormControl w='100%'>
                      <FormErrorMessage>
                        <ErrorMessage name='resume' />
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Stack>
                <Button
                  mt={[6, 8]}
                  type='submit'
                  colorScheme='primary'
                  size='full'
                  isLoading={loading}
                >
                  Demander mon inscription
                </Button>
              </Form>
            </Card>
          )}
        </Formik>
      </Container>
    </PublicLayout>
  );
};
