/* eslint-disable multiline-ternary */
import React from 'react';
import {
  Box,
  Divider,
  Grid,
  Heading,
  Link,
  List,
  ListItem,
  Text
} from '@chakra-ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/modal';
import { chakra } from '@chakra-ui/system';
import { expositions, prizes, publications } from './data';
import { Image } from '@chakra-ui/image';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { IconButton } from '@chakra-ui/button';
import { IconClose } from '../../common/components/Icons';
import { useDisclosure } from '@chakra-ui/hooks';
import { UList, UListItem } from '../../common/components/List';

export const ArtistPrizes = () => {
  const isTab = useBreakpointValue({ base: true, xl: false });
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box textAlign='center'>
        <Link
          color='primary'
          size='lg'
          textDecoration='underline'
          onClick={onOpen}
        >
          En savoir plus
        </Link>
      </Box>

      <Modal isOpen={isOpen} size='6xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          w='100%'
          maxWidth={{ base: 'auto', xl: '6xl' }}
          my={{ base: 0, xl: 15 }}
        >
          <ModalHeader textStyle='h1' pos='relative'>
            <IconButton
              as={ModalCloseButton}
              d='inline-flex'
              variant='ghost'
              icon={<IconClose />}
              aria-label='Fermer'
              position='absolute'
              top={[5, 6]}
              right={[0, 4]}
              _focus={{
                boxShadow: 'none'
              }}
            />
          </ModalHeader>

          <ModalBody px={{ base: '32px', md: '46px' }}>
            {isTab ? (
              <Tabs isFitted>
                <TabList
                  textStyle='h1'
                  color='gray.light'
                  borderColor='gray.light'
                  backgroundColor='bg.homepage'
                  zIndex='docked'
                  position='sticky'
                  top='0'
                >
                  <Tab
                    fontSize={['sm', 'lg']}
                    px='0'
                    _focus={{ outline: 'none' }}
                    _selected={{ color: 'primary', borderColor: 'primary' }}
                  >
                    Publications
                  </Tab>
                  <Tab
                    fontSize={['sm', 'lg']}
                    px='0'
                    _focus={{ outline: 'none' }}
                    _selected={{ color: 'primary', borderColor: 'primary' }}
                  >
                    Expositions
                  </Tab>
                  <Tab
                    fontSize={['sm', 'lg']}
                    px='0'
                    _focus={{ outline: 'none' }}
                    _selected={{ color: 'primary', borderColor: 'primary' }}
                  >
                    Prix et récompenses
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel padding='0'>
                    <Section content={publications} />
                  </TabPanel>
                  <TabPanel padding='0'>
                    <SectionList minW='0px' content={expositions} />
                  </TabPanel>
                  <TabPanel padding='0'>
                    <Section content={prizes} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            ) : (
              <Grid templateColumns='1fr auto 1fr auto 1fr'>
                <Section
                  title='Publications'
                  content={publications}
                  mb={[16, null, null, 0]}
                />
                <Box pt={{ md: 20 }}>
                  <Divider
                    orientation='vertical'
                    mx={8}
                    borderColor='gray.default'
                  />
                </Box>
                <SectionList
                  title='Expositions'
                  minW='0px'
                  content={expositions}
                />
                <Box pt={{ md: 20 }}>
                  <Divider
                    orientation='vertical'
                    mx={8}
                    borderColor='gray.default'
                  />
                </Box>
                <Section title='Prix et récompenses' content={prizes} />
              </Grid>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const SectionList = ({ title, content, ...props }) => {
  return (
    <chakra.section minW='0px' {...props}>
      {title ? (
        <Heading fontSize={{ base: 'xl', lg: '2xl' }} color='primary'>
          {title}
        </Heading>
      ) : null}
      <UList spacing={4} mt={12}>
        {content.map((cont, index) => (
          <UListItem key={index}>{cont.title}</UListItem>
        ))}
      </UList>
    </chakra.section>
  );
};

export const Section = ({ title, content, ...props }) => {
  return (
    <chakra.section {...props}>
      {title ? (
        <Heading fontSize={{ base: 'xl', lg: '2xl' }} color='primary'>
          {title}
        </Heading>
      ) : null}
      <List spacing={20} mt={12}>
        {content.map((cont, index) => (
          <ListItem key={index}>
            <Text as='h3' fontWeight='bold'>
              {cont.title}
            </Text>
            <Text as='span' fontSize='sm' color='gray.light'>
              {cont.subtitle}
            </Text>
            <Text my='5'>{cont.description}</Text>
            {cont.image ? (
              <chakra.figure>
                <Image
                  src={
                    require(`../../common/assets/images/artist/prizes-and-publications/${cont.image.src}`)
                      .default
                  }
                  alt={cont.image.alt}
                  maxW='100%'
                  maxH={['200px', 'sm']}
                  objectFit='cover'
                  pointerEvents='none'
                  loading='lazy'
                />
              </chakra.figure>
            ) : null}
          </ListItem>
        ))}
      </List>
    </chakra.section>
  );
};
