import React from 'react';
import { Route, Switch } from 'react-router';
import { Search } from './Search/Search';
import { Show } from './Show/Show';

export const Missions = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Search} />
      <Route exact path={`${match.url}/:missionId`} component={Show} />
    </Switch>
  );
};
