/* eslint-disable multiline-ternary */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  chakra,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/core';
import { IndexContainer } from '../../components/Container';
import { FormSection } from '../../components/Form';
import { Card } from '../../../../common/components/Card';
import { Form, Formik } from 'formik';
import {
  FormCheckbox,
  FormCheckboxGroup,
  FormInput,
  FormRadio,
  FormRadioGroup
} from '../../../../common/components/Form';
import { IconClose } from '../../../../common/components/Icons';
import {
  postContactProposal,
  postMissionProposal
} from '../../../../common/api/proposals';
import { toastOptions } from '../../../../common/components/Toast';
import { getConstants } from '../../../../common/api/config';
import { FormSelect } from '../../../../common/components/Form/FormSelect';

export const Index = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [proposalType, setProposalType] = useState('');
  const [industries, setIndustries] = useState([]);
  const toast = useToast();

  useEffect(() => {
    getConstants().then(({ data }) => {
      setIndustries(data.sectors.map(value => ({ label: value, value })));
    });
  }, []);

  return (
    <IndexContainer>
      <VStack spacing={12} maxW='100ch' ml={{ lg: 20 }}>
        <chakra.section>
          <Heading as='h2' color='primary' textStyle='h1' mb={6}>
            Proposez une mission ou un contact à la Fédération
          </Heading>
          <Text fontSize='lg' lineHeight='1.7'>
            Être indépendant ne signifie pas être seul, fédérés nous sommes plus
            forts. <br />
            En tant que freelance nous sommes tous commerciaux et pouvons
            contribuer ensemble à la création d’un réseau solide et
            bienveillant.
          </Text>
        </chakra.section>
        <chakra.section as={Card} bgColor='bg.homepage'>
          <Heading as='h2' color='primary' textStyle='h1' fontSize='2xl' mb={4}>
            Proposez une mission à la Fédération
          </Heading>
          <Text lineHeight='1.7' color='#B7B7B7' textAlign='justify'>
            Vous avez eu vent d’une mission chez un client ou prospect et
            souhaitez partager cette information avec votre Fédération,
            n’hésitez plus. Vous pourrez profiter d’une commission commerciale
            de 2% sur la mission proposée dans toute sa durée si elle se
            concrétisait. Dans un premier temps cette proposition sera étudiée
            par la FFI, puis mise en ligne sur la plateforme ALL sous forme de
            mission si le besoin se confirmait.
          </Text>
          <Box textAlign='right'>
            <Button
              colorScheme='primary'
              variant='rounded'
              size='xl'
              mt={6}
              onClick={() => {
                setProposalType('mission');
                onOpen();
              }}
            >
              Proposer une mission
            </Button>
          </Box>
        </chakra.section>
        <chakra.section as={Card} bgColor='bg.homepage'>
          <Heading as='h2' color='primary' textStyle='h1' fontSize='2xl' mb={4}>
            Proposez un contact à la Fédération
          </Heading>
          <Text lineHeight='1.7' color='#B7B7B7' textAlign='justify'>
            Vous connaissez ou avez eu l’occasion de rencontrer un contact
            intéressant chez un client ou prospect et vous souhaitez le partager
            avec votre Fédération, n’hésitez plus. Dans un premier temps ce
            contact sera étudié par la FFI, puis si la relation aboutit à un
            accord commercial immédiat ou futur vous pourrez bénéficier de la
            commission de 2% sur toutes les missions contractées dans les 6 mois
            suivants la mise en relation et l’acception du contact par la FFI,
            ou sur la première mission contractée après un délai de 6 mois.
          </Text>
          <Box textAlign='right'>
            <Button
              colorScheme='primary'
              variant='rounded'
              size='xl'
              mt={6}
              onClick={() => {
                setProposalType('contact');
                onOpen();
              }}
            >
              Proposer un contact
            </Button>
          </Box>
        </chakra.section>
      </VStack>
      <Modal
        isOpen={isOpen}
        size='4xl'
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textStyle='h1' pos='relative'>
            Proposer {proposalType === 'mission' ? 'une mission' : 'un contact'}
            <IconButton
              as={ModalCloseButton}
              d='inline-flex'
              variant='ghost'
              icon={<IconClose />}
              aria-label='Fermer'
              position='absolute'
              top={[10, 12]}
              right={[4, 16]}
              _focus={{
                boxShadow: 'none'
              }}
            />
          </ModalHeader>

          <ModalBody>
            <Formik
              initialValues={{
                clientName: '',
                title: '',
                sector: null,
                description: '',
                durationMission: '',
                estimatedRemuneration: '',
                fullName: '',
                jobTitle: '',
                email: '',
                phoneNumber: '',
                contactPreference: ''
              }}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={(values, actions) => {
                const createProposal =
                  proposalType === 'mission'
                    ? postMissionProposal
                    : postContactProposal;

                createProposal({
                  ...values,
                  sector: values.sector?.value,
                  estimatedRemuneration: values.estimatedRemuneration
                    ? Number(values.estimatedRemuneration.split(' ').join(''))
                    : null
                })
                  .then(() => {
                    toast(
                      toastOptions({
                        duration: 6000,
                        description: `Votre proposition de ${proposalType} a été envoyée, FFI reviendra rapidement vers vous pour faire suite à votre demande.`,
                        status: 'success'
                      })
                    );
                    actions.setSubmitting(false);
                    onClose();
                  })
                  .catch(err => {
                    actions.setSubmitting(false);
                    if (err.response) {
                      actions.setTouched({});
                      actions.setErrors(err.response?.data?.errors);
                    }
                  });
              }}
            >
              {formik => (
                <Form>
                  {proposalType === 'mission' ? (
                    <FormSection
                      title='Description de la mission'
                      width={{ xl: '100%' }}
                      mb={12}
                    >
                      <FormInput
                        label='Nom de la société *'
                        name='clientName'
                      />
                      <FormInput label='Intitulé du poste *' name='title' />
                      <FormSelect
                        placeholder='Information / Communication'
                        label='Domaine de la mission *'
                        name='sector'
                        noOptionsMessage={() => 'Tapez pour chercher'}
                        options={industries}
                      />
                      <FormInput
                        type='textarea'
                        label='Description de la mission *'
                        name='description'
                        rows='7'
                      />
                      <FormInput
                        type='number'
                        label='Estimation TJM *'
                        name='estimatedRemuneration'
                      />
                    </FormSection>
                  ) : null}
                  <FormSection
                    title={
                      proposalType === 'contact'
                        ? 'Information du contact'
                        : 'Informations du contact de la mission'
                    }
                    width={{ xl: '100%' }}
                  >
                    {proposalType === 'contact' ? (
                      <FormInput
                        label='Nom de la société'
                        name='clientName *'
                      />
                    ) : null}
                    <FormRadioGroup
                      name='contactType'
                      legend='Type de contact *'
                    >
                      <FormRadio value='Fonctionnel'>Fonctionnel</FormRadio>
                      <FormRadio value='Manager'>Manager</FormRadio>
                      <FormRadio value='Achats'>Achats</FormRadio>
                    </FormRadioGroup>
                    <FormInput
                      label='Nom *'
                      name='fullName'
                      placeholder='Jean Dupont'
                    />
                    <FormInput
                      label='Poste *'
                      name='jobTitle'
                      placeholder='Chef de projet'
                    />
                    <FormInput
                      label='Email *'
                      type='email'
                      name='email'
                      placeholder='dupont.jean@eti.com'
                    />
                    <FormInput
                      label='Téléphone *'
                      type='tel'
                      name='phoneNumber'
                      placeholder='0601345345'
                    />
                    <FormCheckboxGroup
                      legend='Ses préférences de contact * :'
                      name='contactPreference'
                      stackStyle={{ direction: 'row' }}
                    >
                      <FormCheckbox
                        value='email'
                        checkboxStyle={{ w: '20%', minW: '100px' }}
                      >
                        Email
                      </FormCheckbox>
                      <FormCheckbox
                        value='téléphone'
                        checkboxStyle={{ w: '20%', minW: '100px' }}
                      >
                        Téléphone
                      </FormCheckbox>
                    </FormCheckboxGroup>
                    <Text color='gray.light' fontSize='0.8125rem'>
                      * mention obligatoire
                    </Text>
                  </FormSection>
                  <Button
                    colorScheme='primary'
                    variant='rounded'
                    size='lg'
                    type='submit'
                    isLoading={formik.isSubmitting}
                    mt={10}
                  >
                    Envoyer
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </IndexContainer>
  );
};
