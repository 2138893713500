// theme.js
import { extendTheme } from '@chakra-ui/core';

// Global style overrides
import styles from './styles';

// Foundational style overrides
import breakpoints from './foundations/breakpoints';
import colors from './foundations/colors';
import fonts from './foundations/fonts';
import sizes from './foundations/sizes';
import space from './foundations/space';
import shadows from './foundations/shadows';
import textStyles from './foundations/textStyles';

// Components style override
import Button from './components/Button';
import Card from './components/Card';
import Form from './components/Form';
import FormLabel from './components/FormLabel';
import Heading from './components/Heading';
import Input from './components/Input';
import Menu from './components/Menu';
import Modal from './components/Modal';
import Switch from './components/Switch';
import Tag from './components/Tag';
import Textarea from './components/Textarea';

const overrides = {
  styles,
  breakpoints,
  colors,
  fonts,
  shadows,
  sizes,
  space,
  textStyles,
  // Other foundational style overrides go here
  components: {
    Button,
    Card,
    Drawer: Modal,
    Form,
    FormLabel,
    Heading,
    Input,
    Menu,
    Modal,
    Switch,
    Tag,
    Textarea
  }
};

export default extendTheme(overrides);
