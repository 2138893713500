const styles = {
  global: {
    html: {
      overflowX: 'hidden'
    },
    body: {
      position: 'relative',
      lineHeight: '1.33',
      scrollBehavior: 'smooth'
    },
    'a:focus': {
      boxShadow: 'none!important'
    },
    'a:hover': {
      textDecoration: 'none'
    }
  }
};

export default styles;
