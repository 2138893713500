// Import all artist's images
const galleryContext = require.context(
  '../../common/assets/images/artist/gallery',
  true
);

const descriptions = {
  Insomnia: (
    <>
      La série Insomnia provient d’un travail photographique intitulé « Des
      Ruches Humaine » <br />
      Des ruches humaines ou l’avènement aberrant d’une organisation sociale qui
      a perdu le sens de notre nature Mammifère.
    </>
  ),
  Vitruve: (
    <>
      Représentation allégorique en contrepied, <em>Vitruve</em> ne nie pas sa
      filiation à Léonard de Vinci, bien sûr.
      <br />
      Seulement, cette femme de dos, de trois quart, avec son ombre de profil,
      saisie sur le vif bras déployés, selon l’identique posture de l’Homme de
      <em>Vitruve</em> à l’intérieur de cette architecture géométrique moderne
      haute en couleur, semble sédimenter la symbolique anthropocentrique et le
      vieux monde patriarcal pour faire éclore en douceur une symbolique
      nouvelle.
      <em>Vitruve</em> donne à voir une femme présente, engagée, solidement
      reliée à la terre, en prise directe avec les forces élémentaires et
      cosmiques pour mythifier la femme, citoyenne de son corps et du monde.
    </>
  ),
  'City of colors II':
    'La couleur a une idée, elle peut qualifier une ambiance elle est contraste et concept, elle transforme la perception de l’espace et finalement habille la lumière d’un langage.',
  'Happy Windows': (
    <>
      <em>Happy Windows</em> souligne une harmonie des formes et des couleurs,
      une esthétique fondée sur la symétrie où l’objet apparaît en 3 dimensions
      rendues visibles par le jeu de la lumière et des ombres. Ainsi abstraits,
      les fenêtres deviennent autant d'éléments graphiques se détachant du vide
      de la façade, composant un tableau inattendu.
    </>
  ),
  GiArbre: (
    <>
      GiArbre fait partie d’une série minimaliste intitulée{' '}
      <em>Je tire un trait</em>. Cette série se veut l’expression d’un
      minimalisme sans aucune interprétation possible, ni visuelle ni
      intellectuelle ; cependant elle doit raconter une histoire et faire appel
      à la rêverie.
    </>
  )
};

const images = [];

galleryContext.keys().forEach(filename => {
  const alt = filename.substring(
    filename.lastIndexOf('/') + 1,
    filename.lastIndexOf('.')
  );

  const description = Object.entries(descriptions).find(([key, desc]) =>
    alt.match(key)
  );

  const image = {
    filename: filename,
    category: filename.substring(
      filename.indexOf('/') + 1,
      filename.lastIndexOf('/')
    ),
    src: galleryContext(filename).default,
    alt,
    description: description ? description[1] : null
  };
  images.push(image);
});

// Retrieve all unique categories and associate them with first category image
const categories = images.reduce((acc, img) => {
  if (!acc.find(imgAcc => imgAcc.label === img.category)) {
    return [
      ...acc,
      {
        label: img.category,
        image: img
      }
    ];
  }
  return acc;
}, []);

export { images, categories };

export const prizes = [
  {
    title: 'INTERNATIONAL COLOR AWARDS',
    subtitle: '1er prix Architecture catégorie professionnel',
    image: {
      src: 'color_awards_winner.jpeg'
    }
  },
  {
    title: 'The Black & White Spider Awards 2020',
    subtitle: 'Mention honorable catégorie Aerial Professionnels',
    image: {
      src: 'spider_awards.png'
    }
  },
  {
    title: '35 Awards 2019',
    subtitle:
      '1er Place en tant que photographe Français, et 3eme place en « street photographie',
    image: {
      src: '35awards.jpeg'
    }
  },
  {
    title: 'CEWE Photo Award 2019',
    subtitle: '1er prix catégorie Architecture et Infrastructure',
    image: {
      src: '3hommescontents.jpeg'
    }
  },
  {
    title: 'IPA The International Photography Awards 2019',
    subtitle: 'Street Photography, 3 Mentions Honorable',
    image: {
      src: 'ipa.png'
    }
  },
  {
    title: '"Photo de l\'année 2018"',
    subtitle: '1er prix décerné par PHOX et LENSE',
    image: {
      src: 'la_photo_de_lanne.jpeg'
    }
  },
  {
    title: 'FULL COLOURED - POLKA Magazine 2018',
    subtitle: '1er prix du jury',
    image: {
      src: 'polkamag2.jpeg'
    }
  },
  {
    title: 'FEMMES ET CINÉMA & NIKON FRANCE',
    subtitle: 'Mention honorable',
    image: {
      src: 'femme&cinema.jpeg'
    }
  }
];

export const publications = [
  {
    title: 'POLKA MAGAZINE',
    subtitle:
      'José Antoine Costa Lauréat haut en couleur. Interview par Eléonor Ribes.',
    image: {
      src: 'polka.jpeg'
    }
  },
  {
    title: 'FISHEYE MAGAZINE',
    subtitle:
      'Une série qui se veut l’expression d’un minimalisme sans aucune interprétation possible, ni visuelle ni intellectuelle.  Interview par Daniel Pascoal.',
    image: {
      src: 'fisheye.jpeg'
    }
  },
  {
    title: 'PRACTICAL PHOTOGRAPHY',
    subtitle: 'Mondrian Architecture by José Antoine Costa.',
    image: {
      src: 'practical_photography.jpeg'
    }
  },
  {
    title: 'EYE PHOTO MAGAZINE',
    subtitle: '2017, 2018, 2019, 2020',
    image: {
      src: 'eyephotomagazine.jpeg'
    }
  },
  {
    title: 'NOTRE CHOIX PHOTOGRAPHIQUE',
    subtitle: 'Vol:1  José Antoine Costa travail sur le Népal.',
    image: {
      src: 'FotografiaP&B.jpeg'
    }
  },
  {
    title: 'NOTRE REGARD SUR LE MONDE',
    subtitle: 'Vol: 1. José Antoine Costa On The Street.',
    image: {
      src: 'cantseethelogo.jpeg'
    }
  },
  {
    title: '35 awards',
    subtitle: 'Catalogue des meilleurs photo de 2019 par 35 awards',
    image: {
      src: '35 awards.jpeg'
    }
  }
];

export const expositions = [
  {
    title: (
      <>
        <strong>Novembre 2020 au BBA Circle Gallery à BERLIN</strong>, dans le
        cadre "POWERFUL COMPOSITIONS".
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Octobre 2020, au The Fridge Gallery, in Washington DC, USA.
        </strong>
        , dans le cadre "Mostly Black"
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Juin 2020, au LACP The Los Angeles Center of Photography à Los Angeles
        </strong>
        , dans le cadre "Minimalisme"
      </>
    )
  },
  {
    title: (
      <>
        <strong>Février 2020, au Espaço Espelho D'agua à Lisbonne</strong>, dans
        le cadre "Photographer of the Year 2019"
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Janvier, 2020 au Laurent Gallery, à Melbourne, Australie
        </strong>
        , dans le cadre "Powerful Composition"
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          21-24 Novembre 2019 au Thessaloniki International art Fair à
          Thessalonique, Grèce
        </strong>
        , dans le cadre "Artistic photography"
      </>
    )
  },
  {
    title: <strong>Novembre 2019 au Salon de la Photo à Paris, France.</strong>
  },
  {
    title: (
      <strong>Novembre 2019 au Salon des Créateurs à Colombes, France</strong>
    )
  },
  {
    title: (
      <>
        <strong>Mai 2019 au Blue Berlin Art Gallery à Berlin</strong>, dans le
        cadre "Mostly Black"
      </>
    )
  },
  {
    title: (
      <>
        <strong>Avril 2019 au Blue Berlin Art Gallery à Berlin</strong>, dans le
        cadre "Centered"
      </>
    )
  },
  {
    title: (
      <>
        <strong>Mars 2019 au Hinterland Gallery à Vienne</strong>, dans le cadre
        "Beautiful Imperfections"
      </>
    )
  },
  {
    title: (
      <>
        <strong>Mars 2019 au Valid World Hall à Barcelone</strong>, dans le
        cadre "Urban Scenery"
      </>
    )
  },
  {
    title: (
      <>
        <strong>Mars 2019 au MIA Photo Fair à Milan</strong>, dans le cadre
        "Minimalisme"
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Fevrier/Mars 2019 au WPPI Exposition au the Mandalay Bay Hotel, Las
          Vegas. USA
        </strong>
        , dans le cadre "Your Exhibition Shot"
      </>
    )
  },
  {
    title: (
      <>
        <strong>Fevrier 2019 au Blue Berlin Art Gallery à Berlin</strong>, dans
        le cadre "Your Exhibition Shot"
      </>
    )
  },
  {
    title: (
      <>
        <strong>Janvier 2019 au Valid World Hall à Barcelone</strong>, dans le
        cadre «"ART OF BLACK & WHITE"»
      </>
    )
  },
  {
    title: (
      <>
        <strong>Janvier 2019 au Valid World Hall à Barcelone</strong>, dans le
        cadre «Solo - Single Subjects»
      </>
    )
  },
  {
    title: (
      <>
        <strong>Janvier 2019 au Hinterland Gallery à Vienne, Autriche</strong>,
        dans le cadre «Your Exhibition Shot»
      </>
    )
  },
  {
    title: (
      <>
        <strong>Décembre 2018 Fisheye Gallery à Paris</strong> « Photo de
        l'année 2018 »
      </>
    )
  },
  {
    title: (
      <>
        <strong>Décembre 2018 au New Moment Gallery à Belgrade</strong>, dans le
        cadre «Fascinating Lighting Exhibition»
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Novembre 2018 au Bruno Art Group « Tanglin Place » à Singapour
        </strong>
        , dans le cadre «Mono Chrome»
      </>
    )
  },
  {
    title: (
      <strong>
        Aout/septembre 2018 à la Galerie 5TH BASE GALLERY dans le cadre «MOSTLY
        WHITE» à Londres, Angleterre
      </strong>
    )
  },
  {
    title: (
      <>
        <strong>
          Aout 2018 à la Galerie Qlick gallery à Amsterdam, Pays-Bas
        </strong>
        , dans le cadre « Créative Composition»
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Juillet 2018 à la Galerie Hinterland à Vienne, Autriche,{' '}
        </strong>{' '}
        dans le cadre « Solo Subjects »
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Projection photographique Juin 2018 lors de la Nuit de l’ICART.
        </strong>{' '}
        8ème édition sur l’innovation artistique sur le thème « Discordance des
        temps » à Paris
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Juin 2018 a la Galerie Berlin Blue Art à Berlin, Allemagne
        </strong>
        , dans le cadre « Art Of Black and White »
      </>
    )
  },
  {
    title: (
      <strong>
        Avril 2018. Nordic Light International Festival of Photography, Norvège
      </strong>
    )
  },
  {
    title: (
      <>
        <strong>Mars 2018 au MIA Photo Fair Milan à Milan, Italie.</strong> Dans
        le cadre de l'exposition "BEST OF 2017"
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Février 2018 au ORMS CAPE TOWN SCHOOL OF PHOTOGRAPY au Cape, Afrique
          du Sud
        </strong>
        , dans le cadre "Art Of Photography"
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Janvier 2018 à la Galerie Espaço Cultural Mercês à Lisbonne, Portugal
        </strong>
        , dans le cadre «Fascinatinglighting»
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Novembre 2017 au Art Thessaloniki International art Fair à
          Thessalonique, Grèce
        </strong>
        , dans le cadre "Centered"
      </>
    )
  },
  {
    title: (
      <>
        <strong>
          Juillet 2017 a la Galerie Galeria 6 mas 1 à Madrid, Espagne
        </strong>
        , dans le cadre « Black and White »
      </>
    )
  }
];
