import React from 'react';
import { Box, Button, Heading, Link, Text } from '@chakra-ui/core';

import { Col, Container, Row } from '../../../common/components/Grid';
import { Card } from '../../../common/components/Card';
import { routes } from '../../../common/routes';

export const Confidentiality = () => {
  return (
    <Container mt={{ sm: 24, md: 32 }}>
      <Row>
        <Col mx='auto' w={{ lg: 3 / 5 }}>
          <Card
            marginX={{ base: '-32px', md: 0 }}
            shadow={['none', null, 'card']}
          >
            <Box px={{ base: 0, lg: 6 }} py={{ base: 6, lg: 4 }}>
              <Heading
                textStyle='h1'
                fontSize={{ base: '30px', md: '40px' }}
                mb={4}
              >
                Confidentialité et transparence
              </Heading>
              <Text mb={3}>
                La plateforme ALL assure la confidentialité de vos données
                personnelles, car c'est aussi important pour nous que pour vous.
                Ici tout est transparent, rien n'est communiqué sans votre
                agrément.
              </Text>
              <Button
                as={Link}
                href={routes.register}
                mt={6}
                colorScheme='primary'
                size='lg'
              >
                M'inscrire
              </Button>
            </Box>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
