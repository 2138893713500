import React from 'react';
import { Box, Button, Grid, Heading, Image, Link, Text } from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';
import { routes } from '../../common/routes';
import { history } from '../../common/routes/history';
import Logo from '../../common/assets/images/logo-dashboard.svg';

export const PageError = ({ title, message, children, ...props }) => {
  return (
    <Box
      flexGrow={1}
      h='full'
      p={8}
      textAlign='center'
      color='white'
      {...props}
    >
      {title && (
        <Heading as='h1' textStyle='h1'>
          {title}
        </Heading>
      )}
      {message && <Text>{message}</Text>}
      {children}
    </Box>
  );
};

export const PageErrorNetwork = props => (
  <Grid placeItems='center' height='100vh' bg='bg.homepage'>
    <Box textAlign='center'>
      <Link
        as={NavLink}
        to={routes.home}
        d='inline-block'
        alignSelf='center'
        mb={4}
        _focus={{ outline: 'none' }}
      >
        <Image
          src={Logo}
          height='80px'
          alt='Logo - Fédération Française des Indépendants'
        />
      </Link>
      <PageError title='Connexion au serveur impossible'>
        <Button
          colorScheme='primary'
          mt={8}
          onClick={() => {
            history.goBack();
          }}
        >
          Rafraichir la page
        </Button>
      </PageError>
    </Box>
  </Grid>
);

export const Page404 = props => {
  return (
    <PageError
      title='Erreur 404'
      message='Page ou élément introuvable'
      {...props}
    />
  );
};
