import React from 'react';
import { Icon, useToken } from '@chakra-ui/core';

export const Stat = ({ color = 'primary', label, percent, ...props }) => {
  const [statBg, statColor] = useToken('colors', ['black.dark', color]);
  function polarToCartesian (centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians)
    };
  }

  function describeArc (x, y, radius, startAngle, endAngle) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y
    ].join(' ');

    return d;
  }

  return (
    <Icon
      height='auto'
      width='100%'
      maxW='128px'
      viewBox='0 0 128 64'
      {...props}
    >
      <path
        d={describeArc(64, 64, 64 - 7.5, 270, 90)}
        fill='none'
        stroke={statBg}
        strokeWidth='15'
      />
      <path
        d={describeArc(
          64,
          64,
          64 - 7.5,
          270,
          270 + ((percent * 180 || 0) % 360)
        )}
        fill='none'
        stroke={statColor}
        strokeWidth='15'
      />
      <text
        x='64'
        y='48'
        alignmentBaseline='central'
        textAnchor='middle'
        fontSize='16'
        fill={statColor}
      >
        {label}
      </text>
    </Icon>
  );
};
