const textStyles = {
  mega: {
    fontSize: '4rem',
    fontWeight: 'bold',
    fontFamily: 'heading'
  },
  h1: {
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: 'heading'
  },
  h2: {
    fontSize: '1.8rem',
    fontWeight: 'light',
    fontFamily: 'body'
  },
  section: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontFamily: 'heading'
  },
  number: {
    big: {
      fontSize: '2rem',
      fontWeight: 'bold',
      fontFamily: 'heading'
    },
    small: {
      fontSize: '0.75rem',
      fontWeight: 'black',
      fontFamily: 'heading'
    }
  },
  body: {
    fontSize: '1rem',
    color: 'white',
    fontFamily: 'body',
    lineHeight: 1.3
  },
  mini: {
    fontSize: 'sm'
  }
};

export default textStyles;
