import React from 'react';
import { Box, Button, Grid, Heading, Image, Link, Text } from '@chakra-ui/core';
import { Container } from '../../../common/components/Grid';

import confidentialityImage from '../../../common/assets/images/confidentiality.jpg';
import eye from "../../../common/assets/images/artist/gallery/Architecture/L'oeil derrière le miroir.jpg";
import captureImage from '../../../common/assets/images/artist/gallery/Architecture/Capture Isomnia III.jpg';
import vitruve from '../../../common/assets/images/artist/gallery/Street/Vitruve.jpg';
import tilak from '../../../common/assets/images/artist/gallery/Portrait/Tilak.jpg';
import vision from '../../../common/assets/images/artist/gallery/Architecture/Architecture Vision.jpg';
import shadow from "../../../common/assets/images/artist/gallery/Street/Ma part d'ombre.jpg";
import { routes } from '../../../common/routes';

const images = [
  {
    image: eye,
    alt: "L'oeil derrière le mirroir",
    link: {
      style: {
        gridColumn: '1',
        gridRow: '1 / span 2',
        mt: '10%',
        d: { base: 'none', sm: 'block' }
      }
    }
  },
  {
    image: captureImage,
    alt: 'Capture Insomnia III',
    link: {
      style: {
        gridColumn: '2 / span 2',
        gridRow: '1'
      }
    }
  },
  {
    image: vitruve,
    alt: 'Vitruve',
    style: {
      pr: '6%'
    },
    link: {
      style: {
        d: { base: 'none', sm: 'block' }
      }
    }
  },
  {
    image: tilak,
    alt: 'Tilak',
    link: {
      style: {
        gridColumn: '3 / span 2',
        gridRow: '2',
        mt: '5%',
        d: { base: 'none', sm: 'block' }
      }
    }
  },
  {
    image: vision,
    alt: 'Architecture Vision',
    style: {
      pr: '10%',
      pt: '10%'
    },
    link: {
      style: {
        gridColumn: '4 / span 3',
        gridRow: '1',
        d: { base: 'none', sm: 'block' }
      }
    }
  },
  {
    image: shadow,
    alt: "Ma part d'ombre",
    style: {
      pl: '6%'
    },

    link: {
      style: {
        gridColumn: '-2 / span 2',
        gridRow: '2',
        mt: '-8.5vw',
        d: { base: 'none', sm: 'block' }
      }
    }
  }
];

export const Artist = () => {
  return (
    <Box as='section' bg='gray.dark' mt={[null, 16, 32]}>
      <Box
        bgImage={`url(${confidentialityImage})`}
        bgPosition='center'
        backgroundSize='auto 100%'
        w='100%'
        h='10vw'
      />
      <Container py={{ base: 12, sm: 20 }}>
        <Box
          textAlign={{ base: 'left', md: 'center' }}
          w={{ base: 'full', md: 9 / 12, lg: 7 / 12 }}
          mx='auto'
        >
          <Heading textStyle='h1'>
            Découvrez les oeuvres des artistes indépendants avec lesquels la FFI
            s'associe.
          </Heading>
          <Text mt={4}>
            Nous souhaitons offrir un espace pour les artistes avec lesquels
            nous travaillons, et une touche d'art pour les yeux de nos membres
            ou des visiteurs.
            <br />
            <br />
            FFI vous donne la possibilité d'entrer directement en contact avec
            les artistes si vous souhaitez commander une ou plusieurs œuvres.
          </Text>
          <Button
            as={Link}
            href={routes.discovery}
            mt={6}
            colorScheme='primary'
            size='lg'
          >
            Voir les oeuvres
          </Button>
        </Box>
      </Container>
      <Grid
        d={{ base: 'block', sm: 'grid' }}
        templateColumns={{ sm: '1.2fr 1fr 0.35fr 0.125fr 0.75fr' }}
        autoFlow='dense'
        alignItems='flex-start'
        templateRows='auto'
        rowGap='4.17vw'
        columnGap='3.47vw'
        pb={{ sm: '4vw' }}
      >
        {images.map(img => (
          <Link
            key={img.alt}
            href={routes.discovery}
            d='block'
            {...img.link?.style}
          >
            <Image
              pointerEvents='none'
              key={img.alt}
              src={img.image}
              alt={img.alt}
              {...img.style}
            />
          </Link>
        ))}
      </Grid>
    </Box>
  );
};
