import React from 'react';
import { Switch, useRouteMatch } from 'react-router';
import { PublicLayout } from '../../common/layouts/PublicLayout';
import { AppRoute } from '../../common/routes/AppRoute';
import { Page404 } from '../errors/PageError';
import { ArtistPrizes } from './ArtistPrizes';
import { ArtistShow } from './ArtistShow';

export const Artists = () => {
  const match = useRouteMatch();
  return (
    <PublicLayout>
      <Switch>
        <AppRoute exact path={match.url} component={ArtistShow} />
        <AppRoute
          exact
          path={`${match.url}/prix-et-recompenses`}
          component={ArtistPrizes}
        />
        <AppRoute path='*' component={Page404} />
      </Switch>
    </PublicLayout>
  );
};
