import React from 'react';
import { Box, Divider, Heading, Image, Stack, Text } from '@chakra-ui/core';
import { Card } from '../../../common/components/Card';
import { Col, Container, Row } from '../../../common/components/Grid';

import confidentialityImage from '../../../common/assets/images/artist/gallery/Minimalisme/GiArbre .jpg';

const charges = [
  {
    percentage: 2,
    text: 'de frais de fonctionnement pour la structure FFI'
  },
  {
    percentage: 2,
    text:
      'de commission au niveau de la mise en relation et si la mission se concrétise'
  },
  {
    percentage: 2,
    text:
      'de commision commerciale pour le commercial/consultant qui finalisera la signature de la mission'
  }
];

export const ChargesAndRemuneration = () => {
  return (
    <Box
      as='section'
      d={{ md: 'grid' }}
      gridTemplateColumns='1fr 0.7fr'
      placeItems='center'
      mt={{ sm: 24, md: 32 }}
      id='politique-ffi'
    >
      <Image
        pointerEvents='none'
        gridColumn={{ md: 1 }}
        gridRow={{ md: 1 }}
        src={confidentialityImage}
        objectFit='cover'
        objectPosition={{ base: '68%', md: '45%' }}
        h='100%'
        w='100%'
        maxW='none'
        htmlHeight='500px'
        htmlWidth='500px'
        loading='lazy'
      />
      <Text
        gridColumn={{ md: '1 / span 1' }}
        gridRow={{ md: '1 / span 1' }}
        alignSelf='end'
        justifySelf='start'
        px={2}
        pb={2}
        fontSize='0.75rem'
        position={['absolute', null, 'static']}
        transform={['translateY(-100%)', null, 'none']}
        fontWeight='300'
        opacity='70%'
        color='gray.dark'
      >
        © José Antoine Costa
      </Text>
      <Box
        gridColumn={{ md: '1/-1' }}
        w='full'
        gridRow={{ md: 1 }}
        bg={['bg.homepage', null, 'transparent']}
      >
        <Container>
          <Row alignItems={{ md: 'center' }} my={{ sm: '10%' }}>
            <Col ml='auto' w={{ md: 8 / 12 }}>
              <Card
                marginX={{ base: '-32px', lg: 0 }}
                shadow={['none', null, 'card']}
                bg={['bg.homepage', null, 'black.light']}
              >
                <Box px={{ base: 0, lg: 6 }} py={{ base: 0, lg: 4 }}>
                  <Heading
                    textStyle='h1'
                    mb={4}
                    maxW='523px'
                    fontSize={{ base: '30px', md: '40px' }}
                  >
                    Frais de services
                  </Heading>
                  <Text mt={4}>
                    Là où toutes les structures intermédiaires prennent entre 10
                    et 25% de commission sur la tarification d'un indépendant en
                    mission, la FFI fixe un taux de commission à 6% maximum sur
                    chaque mission.
                  </Text>
                  <Stack spacing={5} mt={3}>
                    {charges.map(charge => (
                      <Text d='flex' alignItems='baseline' key={charge.text}>
                        <Text
                          as='span'
                          textStyle='number.big'
                          color='primary'
                          whiteSpace='nowrap'
                          mr={3}
                          pos='relative'
                          transform='translateY(0.15ch)'
                        >
                          {charge.percentage}
                          <Text as='span' textStyle='number.small'>
                            %
                          </Text>
                        </Text>
                        <Text as='span' color='gray.light'>
                          {charge.text}
                        </Text>
                      </Text>
                    ))}
                  </Stack>
                  <Divider
                    mt={10}
                    borderBottomWidth={2}
                    opacity={1}
                    w='160px'
                  />
                  <Heading textStyle='h1' color='primary' mt={1}>
                    Résultat : 6%
                  </Heading>
                  <Text color='primary' mt={1}>
                    Oui, il est possible pour un consultant indépendant de
                    limiter le taux d’intermédiation à 2% s’il trouve et
                    parvient à finaliser lui-même sa mission via la FFI.
                  </Text>
                  <Text color='primary' fontWeight='bold' mt={5}>
                    Pour votre confort et afin de valoriser vos efforts, FFI
                    s’engage contractuellement à régler les factures de vos
                    prestations 10 jours maximum après la fin de la période de
                    mission / émission de la facture.
                  </Text>
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>
    </Box>
  );
};
