import React, { useEffect, useState } from 'react';
import { IconButton } from '@chakra-ui/button';
import { Box, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/modal';
import reactImageSize from 'react-image-size';
import { IconClose } from '../../../common/components/Icons';

export const GalleryItemShow = ({ currentImage, ...props }) => {
  const [dims, setDims] = useState({ width: 0, height: 0 });

  useEffect(() => {
    reactImageSize(currentImage.src)
      .then(dimensions => setDims(dimensions))
      .catch(e => console.log(e));
  }, [currentImage]);

  const ratio = `calc((${dims.height} / ${dims.width ||
    window.innerWidth * 0.9}) * 100%)`;
  const height = dims.width
    ? (window.innerWidth * 0.9 * dims.height) / dims.width
    : 0;
  const width =
    height > window.innerHeight * 0.8
      ? (window.innerHeight * 0.8 * dims.width) / dims.height
      : window.innerWidth * 0.9;

  return (
    <Modal
      size='full'
      isCentered
      blockScrollOnMount={false}
      css={{ '[class*=-container]': { backdropFilter: 'blur(20px)' } }}
      {...props}
    >
      <ModalOverlay bg='rgba(0,0,0,0.7)' />
      <ModalContent
        bgColor='transparent'
        shadow='none'
        padding='4'
        pointerEvents='none'
      >
        <IconButton
          as={ModalCloseButton}
          d='inline-flex'
          variant='ghost'
          icon={<IconClose />}
          aria-label='Fermer'
          position='absolute'
          top={5}
          right={5}
          px={3}
          zIndex='dropdown'
          pointerEvents='all'
          _focus={{
            boxShadow: 'none'
          }}
        />
        <Box
          mx='auto'
          my='auto'
          pointerEvents='auto'
          fontSize='lg'
          width={width}
          maxW={{ base: '100%', sm: '90vw' }}
        >
          <Box
            pointerEvents='none'
            backgroundImage={`url("${currentImage.src}")`}
            backgroundPosition='center'
            backgroundRepeat='no-repeat'
            backgroundSize='contain'
            paddingTop={ratio}
          />
          <Text color='gray.light' fontWeight='bold' pt={2}>
            {currentImage.alt}
          </Text>
          <Text as='small' color='gray.light' pt={2}>
            {currentImage.description}
          </Text>
        </Box>
      </ModalContent>
    </Modal>
  );
};
