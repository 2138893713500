import React from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Heading,
  Link,
  Stack
} from '@chakra-ui/core';
import { Form, Formik } from 'formik';
import { useLocation, Redirect } from 'react-router';
import { FormInput, FormPasswordInput } from '../../common/components/Form';
import { PublicLayout } from '../../common/layouts/PublicLayout';
import { Container } from '../../common/components/Grid';
import { history } from '../../common/routes/history';
import { getDashboardPath, routes } from '../../common/routes';
import { loginUser, useAuthDispatch, useAuthState } from '../auth';
import { Card } from '../../common/components/Card';

export const Login = () => {
  const location = useLocation();
  const dispatch = useAuthDispatch();
  const { user, loading, errorMessage } = useAuthState();

  if (user) {
    return <Redirect to={getDashboardPath()} />;
  }

  const isEmailConfirmed =
    document.referrer && location.search.match('confirmed=true');

  return (
    <PublicLayout>
      <Container py={{ xxl: 12 }}>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={async (values, actions) => {
            loginUser(dispatch, values)
              .then(() => {
                history.push(routes.dashboard);
              })
              .catch(e => console.log(e));
          }}
        >
          <Card
            w={{ base: 'full', md: 3 / 4, lg: 1 / 2 }}
            mx='auto'
            bg='bg.dashboard'
          >
            <Form>
              <Stack spacing={10}>
                <Heading textStyle='h1'>Connexion</Heading>
                {errorMessage ? ( // eslint-disable-line
                  <Alert status='error' variant='solid' background='error'>
                    <AlertIcon />
                    <AlertDescription>
                      L'email et/ou le mot de passe sont erronés
                    </AlertDescription>
                  </Alert>
                ) : null}
                {isEmailConfirmed ? ( // eslint-disable-line
                  <Alert
                    status='info'
                    variant='solid'
                    background='gray.default'
                  >
                    <AlertIcon />
                    <AlertDescription>
                      Votre adresse mail a été validée, merci de votre confiance
                    </AlertDescription>
                  </Alert>
                ) : null}

                <FormInput type='email' label='Email' name='email' />
                <Box>
                  <FormPasswordInput
                    type='password'
                    label='Mot de passe'
                    name='password'
                  />
                  <Link
                    href={routes.resetPassword}
                    color='primary'
                    d='inline-flex'
                    mt={2}
                    fontSize='sm'
                  >
                    Mot de passe oublié ?
                  </Link>
                </Box>
                <Button type='submit' colorScheme='primary' isLoading={loading}>
                  Se connecter
                </Button>
              </Stack>
            </Form>
          </Card>
        </Formik>
      </Container>
    </PublicLayout>
  );
};
