import React from 'react';
import { Box, Flex, Container as CContainer } from '@chakra-ui/core';

export const Container = ({ children, ...props }) => {
  return (
    <CContainer maxW='base' px={8} {...props}>
      {children}
    </CContainer>
  );
};

export const Row = ({ children, ...props }) => {
  return (
    <Flex mx={-4} {...props}>
      {children}
    </Flex>
  );
};

export const Col = ({ children, ...props }) => {
  return (
    <Box px={4} {...props}>
      {children}
    </Box>
  );
};
