import React, { useState, useEffect, createRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useToast,
  VStack
} from '@chakra-ui/core';
import { ErrorMessage, Form, Formik } from 'formik';
import { FormSection } from '../components/Form';
import { FormSelect } from '../../../common/components/Form/FormSelect';
import { getJobs } from '../../../common/api/profile';
import { getConstants } from '../../../common/api/config';
import {
  FormInput,
  FormRadio,
  FormRadioGroup
} from '../../../common/components/Form';
import { uploadFile } from '../../../common/utils/uploadFile';
import { useAuthDispatch, useAuthState } from '../../auth';
import { updateUser } from '../../auth/actions';
import { toastOptions } from '../../../common/components/Toast';

export const Skills = () => {
  const resumeRef = createRef();
  const [industries, setIndustries] = useState(null);
  const [experiences, setExperiences] = useState([]);
  const [resumeKey, setResumeKey] = useState(null);

  const toast = useToast();
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();

  useEffect(() => {
    getConstants().then(({ data }) => {
      setIndustries(data.sectors.map(value => ({ label: value, value })));
      setExperiences(data.experiences);
    });
  }, []);

  const searchedJobs = inputValue =>
    getJobs(inputValue).then(({ data }) => {
      return data.map(value => ({ label: value, value }));
    });

  return (
    <Formik
      initialValues={{
        ...user,
        actualJob: user.actualJob.map(value => ({ label: value, value })),
        industry: user.industry
          ? {
              label: user.industry,
              value: user.industry
            }
          : null
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={(values, actions) => {
        updateUser(dispatch, {
          ...values,
          actualJob: values.actualJob.map(job => job.value),
          industry: values.industry?.value,
          cv_key: values.resume ? `${resumeKey}` : undefined,
          cv_filename: values.resume ? `${values.resume}` : undefined
        })
          .then(() => {
            actions.setSubmitting(false);
            toast(
              toastOptions({
                description: 'Informations sauvegardées',
                status: 'success'
              })
            );
            actions.setFieldValue('resume', '');
            resumeRef.current.value = null;
          })
          .catch(err => {
            actions.setSubmitting(false);
            if (err.response) {
              actions.setTouched({});
              actions.setErrors(err.response?.data?.errors);
            }
          });
      }}
    >
      {formik => (
        <Form>
          <VStack spacing='form' align='stretch'>
            <Box as='section'>
              <Heading as='h2' textStyle='section'>
                C.V.
              </Heading>
              <VStack spacing='formSection' align='stretch' mt='formTitle'>
                <FormSection title='Document'>
                  {user.cvUrl && (
                    <Text>
                      Votre C.V. actuel est :{' '}
                      <Text as='strong'>{user.cvFilename}</Text>{' '}
                      <Link
                        color='primary'
                        href={user.cvUrl}
                        target='_blank'
                        rel='noreferrer'
                      >
                        voir
                      </Link>
                    </Text>
                  )}
                  <Box>
                    <Button
                      type='button'
                      htmlFor='resume'
                      as={FormLabel}
                      variant='rounded'
                      size='lg'
                      d='inline-flex'
                      colorScheme='secondary'
                      mb={0}
                    >
                      Mettre à jour votre C.V.
                    </Button>
                    <Text as='small'>{formik.values.resume}</Text>
                    <Input
                      ref={resumeRef}
                      type='file'
                      name='resume'
                      id='resume'
                      display='none'
                      onChange={async event => {
                        formik.setFieldTouched('resume', false);
                        if (event.currentTarget.files.length > 0) {
                          const filename = event.currentTarget.files[0].name;
                          uploadFile({
                            file: event.currentTarget.files[0]
                          })
                            .then(key => {
                              setResumeKey(key);
                              formik.setFieldTouched('resume', true);
                              formik.setFieldValue('resume', filename);
                            })
                            .catch(e => {
                              formik.setFieldTouched('resume', true);
                              formik.setFieldError(
                                'cv',
                                e.response?.data?.errors ||
                                  'Le chargement de votre CV à échouer, merci de recommencer.'
                              );
                            });
                        }
                      }}
                    />
                    <FormControl>
                      <FormErrorMessage>
                        <ErrorMessage name='cv' />
                      </FormErrorMessage>
                    </FormControl>
                  </Box>
                </FormSection>
                <FormSection title='Préférences de présentation'>
                  <FormInput
                    label='Souhaitez-vous utiliser uniquement les données de votre CV uploadé ?'
                    type='checkbox'
                    name='presentationPreference'
                  />
                </FormSection>
              </VStack>
            </Box>
            <Box as='section'>
              <Heading as='h2' textStyle='section'>
                Informations complémentaires
              </Heading>
              <VStack spacing='formSection' align='stretch' mt='formTitle'>
                <FormSection title='Métier actuel'>
                  <FormSelect
                    placeholder='Chef de projet'
                    label='Quel est votre métier ?'
                    name='actualJob'
                    cacheOptions
                    defaultOptions
                    isCreatable
                    isAsync
                    isMulti
                    creationText='créer un nouveau métier'
                    noOptionsMessage={() => 'Tapez pour chercher'}
                    loadOptions={searchedJobs}
                  />
                  <FormSelect
                    placeholder='Information / Communication'
                    label='Dans quel domaine ?'
                    name='industry'
                    noOptionsMessage={() => 'Tapez pour chercher'}
                    options={industries}
                  />
                </FormSection>
                <FormSection title='Expérience'>
                  <FormRadioGroup
                    name='yearsOfExperience'
                    legend="De combien d'années d'expérience disposez-vous ?"
                  >
                    {experiences?.map(exp => (
                      <FormRadio key={exp} value={exp}>
                        {exp}
                      </FormRadio>
                    ))}
                  </FormRadioGroup>
                </FormSection>
              </VStack>
            </Box>
          </VStack>
          <Box textAlign={{ md: 'right' }} mt='formSubmit'>
            <Button
              colorScheme='primary'
              variant='rounded'
              size='xl'
              type='submit'
              alignSelf={{ md: 'flex-end' }}
              isLoading={formik.isSubmitting}
            >
              Sauvegarder
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
