const Heading = {
  baseStyle: {
    fontFamily: 'heading'
  },

  sizes: null,

  defaultProps: null
};

export default Heading;
