const Modal = {
  baseStyle: {
    overlay: {
      backdropFilter: 'blur(20px)'
    },
    dialog: {
      background: 'bg.homepage',
      color: 'white',
      rounded: '0',
      shadow: 'card'
    },
    header: {
      px: [8, 20],
      pt: [8, 12]
    },
    body: {
      px: [8, 20],
      pt: 4,
      pb: [8, 20]
    }
  }
};

export default Modal;
