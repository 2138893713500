import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Link,
  Skeleton,
  SkeletonText,
  Text,
  useBreakpointValue,
  VisuallyHidden
} from '@chakra-ui/core';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { Card } from '../../../common/components/Card';
import {
  IconCalendar,
  IconCog,
  IconCurrency,
  IconExperience,
  IconLocation,
  IconTime
} from '../../../common/components/Icons';
import { getDashboardPath } from '../../../common/routes';

export const MissionCard = ({ content, variant = 'mission', ...props }) => {
  const titleTextStyle = useBreakpointValue({ base: 'section', md: 'h1' });
  return (
    <Card
      key={content.id}
      bg={
        variant === 'mission' && content.missionStatuses?.length
          ? 'hsl(218 15% 14% / 1)'
          : 'gray.dark'
      }
      as='article'
      variant='outline'
      borderColor={
        variant === 'mission' && content.missionStatuses?.length
          ? 'gray.medium'
          : 'gray.light'
      }
      pt={{ base: 5, md: 5 }}
      pb={{ base: 0, md: 0 }}
      px={{ base: 4, md: 8 }}
      boxShadow='0'
      pos='relative'
      opacity={
        variant === 'mission' && content.missionStatuses?.length ? 0.85 : 1
      }
      _hover={{
        borderColor: 'secondary',
        opacity: 1,
        footer: {
          borderColor: 'secondary'
        }
      }}
    >
      <Heading
        textStyle={titleTextStyle}
        as='h2'
        d='flex'
        color={
          variant === 'mission' && content.missionStatuses?.length
            ? 'gray.border'
            : null
        }
        justifyContent='space-between'
        flexDir={['column-reverse', 'initial']}
        flexWrap={['wrap', null, 'nowrap']}
        mb={4}
      >
        <Text as='span' d='inline-block' pr={4}>
          {content.title}
        </Text>
        <Text
          as='span'
          whiteSpace='nowrap'
          fontSize='sm'
          fontFamily='body'
          fontWeight='normal'
          color='gray.light'
          mb={1}
        >
          publié le : {format(new Date(content.createdAt), 'dd/MM/yyyy')} -{' '}
          <Text
            as='span'
            color={content.missionStatuses?.length ? 'gray.light' : 'primary'}
          >
            {content.missionStatuses?.length ? 'lu' : 'non lu'}
          </Text>
        </Text>
      </Heading>

      <MissionMeta content={content} mb={[4, '1.125rem']} />

      <Box
        as='section'
        color='gray.light'
        mb={4}
        __css={{
          height: 'calc(3 * 1rem * 1.3)',
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: '3',
          '@media screen and (max-width: 30em)': {
            display: 'none'
          }
        }}
      >
        {content.description.replace(/(<([^>]+)>)/gi, '')}
      </Box>

      <MissionFooter content={content} variant={variant} />
    </Card>
  );
};

export const MissionCardSkeleton = () => (
  <Card
    bg='bg.dashboard'
    variant='outline'
    pt={{ base: 5, md: 5 }}
    pb={{ base: 0, md: 0 }}
    px={{ base: 4, md: 8 }}
  >
    <Skeleton
      startColor='bg.homepage'
      endColor='black.light'
      h='40px'
      w='80%'
    />
    <SkeletonText
      mt={10}
      noOfLines={4}
      startColor='bg.homepage'
      endColor='black.light'
      spacing='4'
    />
    <Skeleton
      mt={10}
      mx={{
        base: 'calc((1rem + 1px) * -1)',
        md: 'calc((2rem + 1px) * -1)'
      }}
      mb='-1px'
      height='40px'
      startColor='bg.homepage'
      endColor='black.light'
      spacing='4'
      borderRadius='0 0 8px 8px'
    />
  </Card>
);

const MISSIONS_META = [
  {
    label: 'Localisation',
    attribute: 'locations',
    icon: IconLocation,
    transform (value) {
      return value.map(value => value.name).join(', ');
    }
  },
  {
    label: 'Rénumération',
    attribute: 'remuneration',
    icon: IconCurrency,
    transform (value) {
      return `${value}/J`;
    }
  },
  {
    label: 'Durée',
    attribute: 'duration',
    icon: IconTime,
    transform (value) {
      return `${value} mois`;
    }
  },
  {
    label: 'Date de début',
    attribute: 'startDate',
    icon: IconCalendar,
    transform (value) {
      return format(new Date(value), 'dd/MM/yyyy');
    }
  },
  {
    label: 'Date de début',
    attribute: 'asap',
    icon: IconCalendar,
    transform (value) {
      return 'Au plus tôt';
    }
  },
  {
    label: 'Expérience',
    attribute: 'experience',
    icon: IconExperience,
    transform (value) {
      return `${value} ans`;
    }
  },
  {
    label: 'Secteur',
    attribute: 'sector',
    icon: IconCog
  }
];

export const MissionMeta = ({ content, ...props }) => {
  return (
    <Flex wrap='wrap' {...props}>
      {MISSIONS_META.map(meta => {
        if (meta.attribute === 'startDate' && content.asap) return null;
        if (meta.attribute === 'asap' && !content.asap) return null;

        return (
          <Text
            key={meta.label}
            whiteSpace={['initial', 'nowrap']}
            fontSize='sm'
            color='#cecece'
            d='flex'
            mb={[1, 2]}
            _notLast={{ mr: 4 }}
          >
            <Icon
              boxSize='0.875rem'
              as={meta.icon}
              color='primary'
              mr='0.375rem'
              verticalAlign='text-top'
            />
            <VisuallyHidden>{meta.label} : </VisuallyHidden>
            {typeof meta.transform === 'function'
              ? meta.transform(content[meta.attribute])
              : content[meta.attribute]}
          </Text>
        );
      })}
    </Flex>
  );
};

export const MissionFooter = ({ content, variant = 'mission' }) => {
  const baseFooterStyles = () => {
    if (content.missionStatuses[0] && variant === 'candidacy') {
      switch (content.missionStatuses[0].status) {
        case 'Transmis au client':
        case 'Contrat validé':
          return {
            borderColor: 'success',
            bg: 'success',
            color: 'black.dark',
            dateColor: 'black.dark',
            linkColor: 'black.dark',
            linkHoverColor: 'black.dark'
          };
        case 'Candidature refusée':
          return {
            borderColor: 'orange',
            bg: 'orange',
            color: 'black.dark',
            dateColor: 'black.dark',
            linkColor: 'black.dark',
            linkHoverColor: 'black.dark'
          };
        default:
          return {
            borderColor: 'white',
            bg: 'white',
            color: 'black.dark',
            dateColor: 'black.dark',
            linkColor: 'black.dark',
            linkHoverColor: 'black.dark'
          };
      }
    } else if (content.missionStatuses[0]) {
      return content.missionStatuses[0].status !== null
        ? {
            borderColor: 'black.light',
            bg: 'black.light',
            color: 'white',
            dateColor: 'gray.light',
            linkColor: 'white',
            linkHoverColor: 'secondary'
          }
        : {
            borderColor: 'gray.medium',
            bg: 'null',
            linkColor: 'primary',
            linkHoverColor: 'secondary'
          };
    } else {
      return {
        borderColor: 'gray.light',
        bg: 'null',
        linkColor: 'primary',
        linkHoverColor: 'secondary'
      };
    }
  };

  const footerStyles = baseFooterStyles();

  return (
    <Box
      as='footer'
      d='flex'
      justifyContent='space-between'
      px={{ base: 4, md: 8 }}
      py={3}
      mx={{
        base: 'calc((1rem + 1px) * -1)',
        md: 'calc((2rem + 1px) * -1)'
      }}
      mb='-1px'
      borderX='1px solid'
      borderBottom='1px solid'
      borderBottomLeftRadius='8px'
      borderBottomRightRadius='8px'
      borderColor={footerStyles.borderColor}
      bg={footerStyles.bg}
      _hover={{
        bg: footerStyles.bg === 'primary' ? 'secondary' : null
      }}
    >
      {content.missionStatuses[0] &&
        content.missionStatuses[0].status !== null && (
          <Text color={footerStyles.color}>
            <Text as='span' textTransform='uppercase'>
              {variant === 'mission'
                ? 'Déjà postulé'
                : content.missionStatuses[0].status}
            </Text>{' '}
            <Text
              as='span'
              color={footerStyles.dateColor}
              textStyle='mini'
              pl={1}
              d={['none', 'initial']}
            >
              le{' '}
              {format(
                new Date(content.missionStatuses[0]?.appliedOn || null),
                'dd/MM/yyyy à HH:mm'
              )}
            </Text>
          </Text>
        ) /* eslint-disable-line */}
      <Link
        as={NavLink}
        to={`${getDashboardPath(['missions'])}/${content.id}`}
        textStyle='button'
        textTransform='uppercase'
        color={footerStyles.linkColor}
        _hover={{
          textDecor: 'none',
          color: footerStyles.linkHoverColor
        }}
        _after={{
          content: "''",
          pos: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <Text
          as='span'
          d={
            content.missionStatuses[0] &&
            content.missionStatuses[0].status !== null
              ? ['none', 'initial']
              : null
          }
        >
          Voir l'offre
        </Text>
      </Link>
    </Box>
  );
};
