import textStyles from '../foundations/textStyles';

const Tag = {
  baseStyle: {
    label: {
      ...textStyles.body,
      color: 'bg.dashboard'
    },
    container: {
      bg: 'secondary',
      py: '0.125rem'
    }
  },

  variants: {
    subtle: () => ({
      container: { bg: null, bgColor: 'secondary', alignItems: 'center' },
      closeButton: {
        color: 'bg.dashboard',
        _hover: {
          bgColor: 'gray.dark',
          color: 'secondary'
        },
        _focus: {
          bgColor: 'gray.default',
          color: 'secondary'
        }
      }
    })
  }
};

export default Tag;
