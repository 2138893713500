import { format } from 'date-fns';

export function debounce (func, wait, immediate) {
  let timeout;

  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) return func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) return func.apply(context, args);
  };
}

export const getDateString = date => (date ? format(date, 'yyyy-MM-dd') : '');
