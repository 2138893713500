/* eslint-disable multiline-ternary, indent */
import React from 'react';
import { Form, Formik } from 'formik';
import {
  Button,
  chakra,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  useBreakpointValue
} from '@chakra-ui/core';

import {
  IconLocation,
  IconSearch
} from '../../../../../common/components/Icons';
import { FormSelect } from '../../../../../common/components/Form/FormSelect';
import {
  getCities,
  getDepartments,
  getRegions
} from '../../../../../common/api/profile';
import { Filters, FiltersLogic, FiltersMobile } from './Filters';
import { add } from 'date-fns';

export const SearchBar = ({ filters, onSubmit, constants, initialValues }) => {
  const inputBorderColor = useBreakpointValue({
    base: 'primary',
    sm: 'gray.light'
  });
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const searchedLocation = inputValue =>
    getRegions({ nom: inputValue, limit: 10 }).then(({ data: regions }) =>
      getDepartments({ nom: inputValue, limit: 10 }).then(
        ({ data: departments }) =>
          getCities({ nom: inputValue, limit: 10 }).then(({ data: cities }) => {
            const data = [
              ...regions.map(region => ({ ...region, type: 'region' })),
              ...departments.map(department => ({
                ...department,
                type: 'department'
              })),
              ...cities.map(city => ({ ...city, type: 'city' }))
            ];

            return data.map(value => ({
              regionCode:
                value.type === 'region' ? value.code : value.codeRegion,
              departmentCode:
                value.type === 'department'
                  ? value.code
                  : value.codeDepartement,
              inseeCode: value.type === 'city' ? value.code : undefined,
              name: value.nom,
              type: value.type
            }));
          })
      )
    );

  return (
    <Formik
      initialValues={{
        ...filters,
        byStartDate:
          typeof filters.byStartDate === 'string'
            ? new Date(filters.byStartDate)
            : filters.byStartDate,
        byAsap:
          typeof filters.byAsap === 'string'
            ? filters.byAsap === 'true'
            : filters.byAsap
      }}
      onReset={(values, actions) => actions.submitForm(values)}
      onSubmit={values => {
        onSubmit({
          ...values,
          byStartDate: values.byStartDate
            ? add(new Date(values.byStartDate.setHours(0, 0, 0)), { days: 1 })
            : null
        });
      }} // eslint-disable-line
    >
      {formik => (
        <Grid
          as={Form}
          gridGap={[null, '4']}
          bg='bg.homepage'
          gridTemplateColumns={['1fr auto', '1fr 1fr auto']}
          gridAutoRows={['1fr', 'auto']}
          borderBottomWidth={[null, '1px']}
          borderColor='gray.light'
          px={{ sm: 4, md: 8 }}
          py={[null, 4]}
          pos='sticky'
          top='0'
          zIndex='10'
        >
          <FiltersLogic />
          <InputGroup gridColumn={['1/-1', 'initial']} gridRow={[1, 'initial']}>
            <InputLeftElement pointerEvents='none'>
              <IconSearch />
            </InputLeftElement>
            <Input
              type='text'
              h='100%'
              placeholder='Trouver une mission'
              borderBottomColor={inputBorderColor}
              {...formik.getFieldProps('byTitleOrDescription')}
            />
          </InputGroup>
          <GridItem
            gridRow={[2, 'initial']}
            minW='0'
            __css={{
              '.chakra-form-control, .chakra-input__group, [class*=-container], [class*=-control]': {
                height: '100%'
              },
              '[class*=-control]': {
                borderBottomColor: inputBorderColor
              },
              '[class*=-ValueContainer]': {
                overflowX: 'auto',
                flexWrap: 'nowrap',
                MsOverflowStyle: 'none' /* IE and Edge */,
                scrollbarWidth: 'none' /* Firefox */,
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              },
              '[class*=-multiValue]': {
                flex: '0 0 auto'
              }
            }}
          >
            <FormSelect
              placeholder='Île de France'
              name='byLocations'
              cacheOptions
              defaultOptions
              isAsync
              isMulti
              noOptionsMessage={() => 'Tapez pour chercher'}
              leftIcon={<IconLocation />}
              loadOptions={searchedLocation}
              getOptionValue={option => {
                return `${option.type}-${option.inseeCode ||
                  option.departmentCode ||
                  option.regionCode}`;
              }}
              getOptionLabel={option => (
                <Flex align='baseline'>
                  {option.name}{' '}
                  {option.inseeCode ? (
                    <chakra.span fontSize='sm' paddingLeft={1}>
                      ({option.departmentCode})
                    </chakra.span>
                  ) : null}
                  <chakra.span
                    ml='auto'
                    fontSize='sm'
                    fontStyle='italic'
                    paddingLeft={1}
                  >
                    {option.type === 'city'
                      ? 'commune'
                      : option.type === 'department'
                      ? 'département'
                      : 'région'}
                  </chakra.span>
                </Flex>
              )}
            />
          </GridItem>
          {isMobile ? (
            <FiltersMobile
              constants={constants}
              initialValues={initialValues}
            />
          ) : null}
          <Button
            type='submit'
            size='full'
            px='5'
            d={['none', 'initial']}
            colorScheme='primary'
          >
            Rechercher
          </Button>
          {isMobile ? null : (
            <Filters constants={constants} d={['none', 'flex']} />
          )}
          <Button
            variant='filter'
            justifyContent='center'
            type='button'
            color='error'
            flex='0 0 138.69px'
            borderRightWidth='1px'
            borderLeftWidth='0px'
            onClick={() => {
              formik.resetForm({ values: initialValues });
            }}
            d={['none', 'inline-flex']}
          >
            <chakra.span ml='-1rem' d='inline-block'>
              Effacer les filtres
            </chakra.span>
          </Button>
        </Grid>
      )}
    </Formik>
  );
};
