import React from 'react';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Link,
  Stack,
  Text
} from '@chakra-ui/core';
import { Card } from '../../../common/components/Card';
import { Col, Container, Row } from '../../../common/components/Grid';
import { UList, UListItem } from '../../../common/components/List';
import { routes } from '../../../common/routes';

export const Ffi = props => {
  return (
    <Box as='section' {...props}>
      <Box d={['none', 'block']}>
        <HStack
          spacing={[4, 10, null, 20]}
          justify='space-around'
          px={{ base: '5%', md: '10%' }}
          py={[10, null, null, '3.5rem']}
          textStyle='mega'
          fontSize='clamp(0.75rem, calc(0.5rem + 3vw), 4rem)'
          color='primary'
        >
          <Heading>Indépendant</Heading>
          <Heading>Innovant</Heading>
          <Heading>Transparent</Heading>
        </HStack>
      </Box>
      <Container mb={{ base: 0, sm: 10, md: 0 }}>
        <Row>
          <Col mx='auto' w={{ md: 4 / 5 }}>
            <Card
              marginX={{ base: '-32px', sm: 0 }}
              shadow={{ base: 'none', md: 'card' }}
              bg={{ base: 'bg.homepage', sm: 'black.light' }}
            >
              <Box py={{ base: 0, lg: 4 }}>
                <Heading
                  fontSize={{ base: '2xl', md: '4xl' }}
                  textAlign='center'
                >
                  La Fédération Française des Indépendants
                </Heading>
                <Stack spacing={6} mt={7} mx='auto' maxW='52ch'>
                  <Text>
                    Ici exit les sociétés de conseil, portage salarial, ESN et
                    autres SSII, seuls les indépendants sont admis.
                  </Text>
                  <Text>
                    Adhérer à la FFI c'est avancer ensemble pour améliorer la
                    condition du freelance en France tout en gardant cette
                    indépendance qui nous est chère.
                  </Text>
                </Stack>
                <Box maxW='30ch' mx='auto'>
                  <Divider
                    borderColor='primary'
                    my={{ base: 8, md: 16 }}
                    borderBottomWidth={2}
                    opacity='1'
                  />
                </Box>
                <Heading
                  fontSize={{ base: '2xl', md: '4xl' }}
                  textAlign='center'
                >
                  ALL, la solution innovante
                </Heading>
                <Box maxW='52ch' mx='auto' mt={7}>
                  <Text mb={3}>
                    <br /> Plus qu'un réseau, la FFI et sa plateforme ALL vous
                    permettent :
                  </Text>
                  <UList>
                    <UListItem color='white'>
                      d'adhérer gratuitement et sans aucun frais ultérieurs à la
                      plus puissante communauté freelance en France
                    </UListItem>
                    <UListItem color='white'>
                      de proposer des missions à la communauté des indépendants
                      et d'être rémunéré pour cela
                    </UListItem>
                    <UListItem color='white'>
                      un accès éthique, privilégié et négocié aux entreprises
                      qui nécessitent votre expertise
                    </UListItem>
                    <UListItem color='white'>
                      la possibilité de contribuer à nos référencements et
                      d'être rémunéré pour cela
                    </UListItem>
                  </UList>
                  <Box textAlign={{ base: 'left', md: 'center' }}>
                    <Button
                      as={Link}
                      href={routes.register}
                      mt={12}
                      mb={4}
                      colorScheme='primary'
                      size='lg'
                    >
                      Devenir membre
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};
