import React from 'react';
import { Box } from '@chakra-ui/core';

export const Container = ({ children, ...props }) => {
  return (
    <Box
      as='section'
      pt={{ base: 8, md: 16 }}
      pl={{ base: 8, md: 16 }}
      pb={{ base: 32, lg: 9 }}
      pr={{ base: 8, sm: 16, lg: 28 }}
      {...props}
    >
      {children}
    </Box>
  );
};

export const IndexContainer = ({ children, ...props }) => {
  return (
    <Box
      as='section'
      pt={8}
      px={{ base: 4, md: 8 }}
      pb={{ base: 32, lg: '53px' }}
      {...props}
    >
      {children}
    </Box>
  );
};

export const FormContainer = ({ children, ...props }) => {
  return (
    <Box
      as='section'
      pt={{ base: 8, md: 16 }}
      pl={{ base: 8, md: 16 }}
      pb={{ base: 32, lg: 9 }}
      pr={{ base: 8, sm: 16, lg: 28 }}
      maxW='1184px'
      {...props}
    >
      {children}
    </Box>
  );
};
