import { transparentize } from '@chakra-ui/theme-tools';

const Input = {
  parts: ['field', 'addon'],
  sizes: {
    md: {
      field: { fontSize: 'inherit', pl: 2, pr: 2, h: 10, borderRadius: 0 }
    }
  },
  variants: {
    outline: {
      field: {
        border: 0,
        borderBottom: '2px solid',
        borderBottomColor: 'gray.light',
        bg: 'black.light',
        _placeholder: {
          color: 'gray.medium'
        },
        _hover: {
          borderColor: transparentize('primary', 0.8)
        },
        _focus: {
          zIndex: 1,
          borderColor: 'primary',
          boxShadow: 'none'
        },
        _invalid: {
          boxShadow: 'none',
          borderBottomColor: 'error'
        }
      }
    }
  },
  defaultProps: { size: 'md', variant: 'outline' }
};

export default Input;
