import Axios from 'axios';
import { apiV1 } from './api';

export const getPresignedUrl = () => apiV1.get('/url_presigned');

export const uploadFromUrl = ({ url, data }) =>
  Axios.put(url, data, {
    headers: { 'Content-Type': data.type },
    withCredentials: false
  });
