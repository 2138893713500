import React, { useEffect } from 'react';
import { HStack, Text } from '@chakra-ui/core';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Card } from '../../../../../common/components/Card';
import { Stat } from '../../../../../common/components/Stat';
import { debounce } from '../../../../../common/utils';

export const FiltersLogic = () => {
  const { values, submitForm, dirty, touched } = useFormikContext();

  const debouncedSubmitForm = debounce(submitForm, 400);

  useEffect(() => {
    if (dirty || (!dirty && touched?.byMissionStatuses?.length)) {
      debouncedSubmitForm();
    }
  }, [values]);

  return null;
};
export const Filters = ({ initialValues, data, onSubmit, ...props }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formik => (
        <HStack as={Form} spacing={[4, null, '10']} {...props} align='stretch'>
          <FiltersLogic />
          {[
            {
              id: 'transmisAuClient',
              label: 'Transmis au client',
              color: 'success'
            },
            {
              id: 'enCoursDeTraitement',
              label: 'En cours de traitement',
              color: 'white'
            },
            {
              id: 'candidatureRefusee',
              label: 'Candidature refusée',
              color: 'orange'
            }
          ].map(status => (
            <Card
              key={status.id}
              as='label'
              flex='1'
              d='flex'
              flexDir='column'
              alignItems='center'
              variant='outline'
              borderColor='#34343c'
              bgColor='bg.dashboard'
              textAlign='center'
              px={{ base: 2, sm: 4, md: 8 }}
              py={{ base: 2, sm: 4, md: 4 }}
              cursor='pointer'
              data-checked={
                formik.values.byMissionStatuses.find(s => s === status.label)
                  ? true
                  : null
              }
              _hover={{
                borderColor: status.color
              }}
              _checked={{
                borderColor: status.color,
                bgColor: '#3d4145'
              }}
              __css={{
                input: {
                  display: 'none'
                }
              }}
            >
              <Text mb={[3, 2]} fontSize={['12px', 'sm', 'inherit']}>
                {status.label}
              </Text>
              <Field
                type='checkbox'
                name='byMissionStatuses'
                value={status.label}
              />
              <Stat
                width='100%'
                maxW='128px'
                mt='auto'
                color={status.color}
                percent={
                  data?.missionsCurrentUserAppliedToCount[status.id] /
                  Object.values(data?.missionsCurrentUserAppliedToCount).reduce(
                    (acc, s) => acc + s,
                    0
                  )
                }
                label={data?.missionsCurrentUserAppliedToCount[status.id]}
              />
            </Card>
          ))}
        </HStack>
      )}
    </Formik>
  );
};
