import { login, logout } from '../../common/api/auth';
import { getUser, putUser } from '../../common/api/profile';

export async function loginUser (dispatch, loginPayload) {
  try {
    dispatch({ type: 'REQUEST_LOGIN' });
    const response = await login(loginPayload);
    const { data } = response;

    if (data) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: data });
      window.localStorage.setItem('currentUser', JSON.stringify(data));
      return data;
    }
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
    throw error;
  }
}

export async function fetchUser (dispatch) {
  try {
    dispatch({ type: 'GET_REQUEST' });
    const response = await getUser();
    const { data } = response;

    if (data) {
      dispatch({ type: 'GET_SUCCESS', payload: data });
      window.localStorage.setItem('currentUser', JSON.stringify(data));
      return data;
    }
  } catch (error) {
    dispatch({ type: 'GET_ERROR', error: error });
    throw error;
  }
}

export async function updateUser (dispatch, updatePayload) {
  try {
    const { data } = await putUser(updatePayload);
    if (data) {
      dispatch({ type: 'UPDATE_SUCCESS', payload: data });
      window.localStorage.setItem('currentUser', JSON.stringify(data));
      return data;
    }
  } catch (error) {
    dispatch({ type: 'UPDATE_ERROR', error: error });
    throw error;
  }
}

export function logoutUser (dispatch) {
  dispatch({ type: 'LOGOUT' });
  window.localStorage.removeItem('currentUser');
  logout();
}
