/* eslint-disable multiline-ternary */
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Global,
  ListItem,
  Link,
  List,
  Grid,
  Stack,
  Icon,
  Image,
  Flex,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  useDisclosure
} from '@chakra-ui/core';
import { getDashboardPath, routes } from '../routes';
import {
  IconBriefCase,
  IconEnvelop,
  IconMenu,
  IconTicket,
  IconUser
} from '../components/Icons';
import { ErrorBoundary } from '../../features/errors/ErrorBoundary';
import Logo from '../assets/images/logo-dashboard.svg';
import { useAuthState } from '../../features/auth';

export const PrivateLayout = ({ children }) => {
  return (
    <Grid
      textStyle='body'
      gridAutoFlow={{ base: 'row', md: 'column' }}
      gridTemplateColumns={{ lg: 'auto 1fr' }}
      gridTemplateRows={{ base: '1fr auto', md: null }}
      minH='100vh'
    >
      <Global
        styles={theme => ({
          body: { backgroundColor: theme.colors.bg.dashboard }
        })}
      />
      <Sidebar />
      <Mobilebar />
      <Box as='main' gridRow={1} minW={0}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
    </Grid>
  );
};

const Sidebar = () => {
  const { hasFullAccess } = useAuthState();

  return (
    <Box
      as='nav'
      borderRightWidth={1}
      borderColor='gray.default'
      d={{ base: 'none', lg: 'block' }}
      w='256px'
      fontSize='1.125rem'
    >
      <Flex flexDir='column' position='sticky' top='0' h='100vh'>
        <Link
          as={NavLink}
          to={getDashboardPath()}
          d='inline-block'
          alignSelf='flex-start'
          mt={8}
          mx={8}
          mb={16}
          _focus={{ outline: 'none' }}
        >
          <Image
            src={Logo}
            height='80px'
            alt='Logo - Fédération Française des Indépendants'
          />
        </Link>
        <Stack
          as={List}
          direction='column'
          align='stretch'
          justify='flex-start'
        >
          <ListItem>
            <SidebarLink
              to={getDashboardPath(['missions'])}
              disabled={!hasFullAccess}
            >
              Missions
            </SidebarLink>
          </ListItem>
          <ListItem>
            <SidebarLink
              to={getDashboardPath(['candidacies'])}
              disabled={!hasFullAccess}
            >
              Mes candidatures
            </SidebarLink>
          </ListItem>
          <ListItem>
            <SidebarLink
              to={getDashboardPath(['propositions'])}
              disabled={!hasFullAccess}
            >
              Mes propositions
            </SidebarLink>
          </ListItem>
          <ListItem>
            <SidebarLink to={getDashboardPath(['profile'])}>
              Mon profil
            </SidebarLink>
          </ListItem>
        </Stack>
        <SidebarLink
          to={routes.discovery}
          mt='auto'
          pb={2}
          _hover={{ color: 'secondary' }}
        >
          Découverte artistique
        </SidebarLink>
        <SidebarLink
          pt={2}
          to={routes.logout}
          color='gray.light'
          _hover={{ color: 'secondary' }}
        >
          Déconnexion
        </SidebarLink>
      </Flex>
    </Box>
  );
};

const Mobilebar = () => {
  const { hasFullAccess } = useAuthState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      as='nav'
      borderTopWidth={1}
      borderColor='gray.default'
      background='bg.dashboard'
      overflowX='auto'
      d={{ base: 'block', lg: 'none' }}
      p='0.75rem 0.25rem 0.5rem'
      gridRow={2}
      position='fixed'
      bottom={0}
      width='full'
      minW={0}
      zIndex='1000'
    >
      <Stack as={List} direction='row' align='stretch' justify='space-between'>
        <ListItem flex='1'>
          <MobilebarLink
            to={getDashboardPath(['missions'])}
            icon={IconBriefCase}
            disabled={!hasFullAccess}
          >
            Missions
          </MobilebarLink>
        </ListItem>
        <ListItem flex='1'>
          <MobilebarLink
            to={getDashboardPath(['candidacies'])}
            icon={IconEnvelop}
            disabled={!hasFullAccess}
          >
            Candidatures
          </MobilebarLink>
        </ListItem>
        <ListItem flex='1'>
          <MobilebarLink
            to={getDashboardPath(['propositions'])}
            icon={IconTicket}
            disabled={!hasFullAccess}
          >
            Propositions
          </MobilebarLink>
        </ListItem>
        <ListItem flex='1'>
          <MobilebarLink to={getDashboardPath(['profile'])} icon={IconUser}>
            Profil
          </MobilebarLink>
        </ListItem>
        <ListItem flex='1' d='flex' alignItems='center'>
          <MobilebarLink as={Link} onClick={onOpen} icon={IconMenu} mx='auto' />
        </ListItem>
      </Stack>
      <Drawer
        placement='bottom'
        onClose={onClose}
        isOpen={isOpen}
        blockScrollOnMount={false}
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent bgColor='bg.homepage'>
          <DrawerBody pb={5}>
            <SidebarLink
              to={routes.discovery}
              mt='auto'
              color='gray.light'
              py={2}
            >
              Découverte artistique
            </SidebarLink>
            <SidebarLink py={2} to={routes.logout} color='gray.light'>
              Déconnexion
            </SidebarLink>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

const SidebarLink = ({ children, disabled, ...props }) => {
  return (
    <Link
      as={NavLink}
      textStyle='button'
      d='block'
      flexDirection='column'
      alignItems='center'
      px={8}
      py={4}
      w='calc(100% + 1px)'
      color='inherit'
      _hover={{ color: 'primary' }}
      _focus={{
        boxShadow: 'none',
        color: 'primary'
      }}
      _activeLink={{
        fontWeight: 600,
        color: 'bg.dashboard',
        bgColor: 'primary'
      }}
      {...(disabled
        ? {
            as: Text,
            color: 'gray.default',
            _hover: { cursor: 'not-allowed', color: 'gray.default' }
          }
        : {})}
      {...props}
    >
      {children}
    </Link>
  );
};

const MobilebarLink = ({ children, icon, disabled, ...props }) => {
  return (
    <Link
      as={NavLink}
      d='flex'
      flexDirection='column'
      alignItems='center'
      px={1}
      py={1}
      color='gray.default'
      fontSize='0.625rem'
      textAlign='center'
      _hover={{ color: 'primary' }}
      _activeLink={{
        color: 'primary'
      }}
      {...(disabled
        ? { as: Text, color: 'gray.dark', _hover: { cursor: 'not-allowed' } }
        : {})}
      {...props}
    >
      <Icon as={icon} boxSize={6} mb='2' />
      {children}
    </Link>
  );
};
