import React, { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { routes } from '.';
import { useAuthDispatch, useAuthState } from '../../features/auth';
import { fetchUser } from '../../features/auth/actions';

export const AppRoute = ({
  component: Component,
  path,
  isPrivate,
  ...rest
}) => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const location = useLocation();

  useEffect(() => {
    if (userDetails.user && isPrivate) {
      fetchUser(dispatch);
    }
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Route
      path={path}
      render={props =>
        isPrivate && !Boolean(userDetails.user) ? ( // eslint-disable-line
          <Redirect to={routes.login} />
        ) : (
          <Component />
        )
      } // eslint-disable-line
      {...rest}
    />
  );
};
