import textStyles from '../foundations/textStyles';

const FormLabel = {
  baseStyle: {
    marginBottom: '0.625rem',
    ...textStyles.mini
  }
};

export default FormLabel;
