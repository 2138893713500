const user = window.localStorage.getItem('currentUser')
  ? JSON.parse(window.localStorage.getItem('currentUser'))
  : '';

export const initialState = {
  user: '' || user,
  loading: false,
  errorMessage: null
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
    case 'GET_REQUEST':
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case 'LOGIN_SUCCESS':
    case 'GET_SUCCESS':
      return {
        ...initialState,
        user: action.payload,
        loading: false
      };
    case 'UPDATE_SUCCESS':
      return {
        ...initialState,
        user: action.payload,
        loading: false
      };
    case 'LOGOUT':
      return {
        ...initialState,
        user: '',
        errorMessage: null
      };

    case 'LOGIN_ERROR':
    case 'GET_ERROR':
    case 'UPDATE_ERROR':
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
