/* eslint-disable multiline-ternary, react/jsx-indent, indent */
import React, { useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import {
  Stack,
  Heading,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  Button,
  useToast,
  Link
} from '@chakra-ui/core';
import { useHistory, useLocation } from 'react-router';
import qs from 'qs';
import { PublicLayout } from '../../common/layouts/PublicLayout';
import { Container } from '../../common/components/Grid';
import { Card } from '../../common/components/Card';
import { sendPasswordReset, passwordReset } from '../../common/api/auth';
import { FormInput, FormPasswordInput } from '../../common/components/Form';
import { IconArrowLeft } from '../../common/components/Icons';
import { routes } from '../../common/routes';
import { toastOptions } from '../../common/components/Toast';

export const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const toast = useToast();
  const [isPasswordResetSent, setIsPasswordResetSent] = useState(false);

  const { reset_password_token: resetPasswordToken } = qs.parse(
    location.search,
    {
      ignoreQueryPrefix: true
    }
  );

  return (
    <PublicLayout>
      <Container>
        <Card
          w={{ base: 'full', md: 3 / 4, lg: 1 / 2 }}
          mx='auto'
          bg='bg.dashboard'
        >
          {resetPasswordToken ? (
            <Formik
              initialValues={{
                password: '',
                passwordConfirmation: '',
                resetPasswordToken: ''
              }}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={(values, actions) => {
                passwordReset({ ...values, resetPasswordToken })
                  .then(() => {
                    toast(
                      toastOptions({
                        description:
                          'Votre mot de passe a bien été réinitialisé. Vous pouvez désormais vous connecter avec votre nouveau mot de passe.',
                        status: 'success'
                      })
                    );
                    history.push(routes.login);
                  })
                  .catch(err => {
                    actions.setTouched({ resetPasswordToken: true });
                    if (err.response) {
                      actions.setErrors({
                        ...err.response?.data?.errors,
                        resetPasswordToken: err.response?.data?.errors
                          ?.resetPasswordToken ? (
                          <Alert
                            status='error'
                            variant='solid'
                            background='error'
                          >
                            <AlertIcon flexShrink='0' />
                            <AlertDescription>
                              Le lien de réinitialisation de votre mot de passe
                              a déjà été utilisé ou a expiré.{' '}
                              <Link
                                href={routes.resetPassword}
                                fontWeight='bold'
                                textDecoration='underline'
                              >
                                Redemander une réinitialisation de mot de passe
                              </Link>
                            </AlertDescription>
                          </Alert>
                        ) : null
                      });
                    }
                  });
              }}
            >
              {formik => (
                <Form>
                  <Stack spacing={10}>
                    <Heading textStyle='h1'>
                      Réinitilisation de mot de passe
                    </Heading>
                    <Text>
                      Le mot de passe doit contenir au moins 8 caractères et
                      inclure au moins 1 majuscule, 1 minuscule, 1 chiffre et 1
                      caractère spécial.
                    </Text>
                    <Stack spacing={6}>
                      <FormPasswordInput
                        type='password'
                        name='password'
                        label='Nouveau mot de passe'
                        autocomplete='new-password'
                      />
                      <FormPasswordInput
                        type='password'
                        name='passwordConfirmation'
                        label='Confirmation de mot de passe'
                        autocomplete='new-password'
                      />
                      <ErrorMessage
                        render={msg => <Text color='error'>{msg}</Text>}
                        color='error'
                        name='resetPasswordToken'
                      />
                    </Stack>
                    <Button
                      type='submit'
                      colorScheme='primary'
                      size='lg'
                      ml='auto'
                      alignSelf='flex-end'
                    >
                      Réinitialiser
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={{ email: '' }}
              onSubmit={(values, actions) => {
                sendPasswordReset(values).then(() =>
                  setIsPasswordResetSent(true)
                );
              }}
            >
              {formik => (
                <Form>
                  <Stack spacing={10}>
                    <Heading textStyle='h1'>Mot de passe oublié ?</Heading>
                    {isPasswordResetSent ? (
                      <>
                        <Alert
                          status='info'
                          variant='solid'
                          background='gray.default'
                        >
                          <AlertIcon flexShrink='0' />
                          <AlertDescription>
                            L'e-mail envoyé vous permettra de choisir un nouveau
                            mot de passe. Vous pouvez consulter dès maintenant
                            votre messagerie.
                          </AlertDescription>
                        </Alert>
                        <Button
                          type='button'
                          colorScheme='primary'
                          size='auto'
                          ml='auto'
                          alignSelf='flex-end'
                          onClick={() => history.goBack()}
                        >
                          <IconArrowLeft mr={2} />
                          Retour
                        </Button>
                      </>
                    ) : (
                      <>
                        <Text>
                          Vous avez oublié votre mot de passe ? Vous recevrez la
                          marche à suivre par e-mail à l'adresse saisie
                          ci-dessous.
                        </Text>
                        <FormInput
                          type='email'
                          name='email'
                          autocomplete='username'
                          label='E-mail'
                          required
                        />
                        <Button
                          type='submit'
                          colorScheme='primary'
                          size='lg'
                          ml='auto'
                          alignSelf='flex-end'
                        >
                          Envoyer
                        </Button>
                      </>
                    )}
                  </Stack>
                </Form>
              )}
            </Formik>
          )}
        </Card>
      </Container>
    </PublicLayout>
  );
};
