/* eslint-disable multiline-ternary */
import React from 'react';
import {
  Box,
  Button,
  chakra,
  Grid,
  Heading,
  IconButton,
  Text,
  useBreakpointValue,
  useToast
} from '@chakra-ui/core';
import { format } from 'date-fns';
import { useDataLoader } from 'react-remote-data-hooks';
import { useHistory, useRouteMatch } from 'react-router';
import { getMission, putMission } from '../../../../common/api/missions';
import { MissionMeta } from '../../components/Mission';
import { IconArrowLeft } from '../../../../common/components/Icons';
import { toastOptions } from '../../../../common/components/Toast';

const MissionApplyButton = ({ statusBannerStyle, ...props }) => {
  const buttonStyle = useBreakpointValue({ base: 'full', md: 'lg' });

  return (
    <Button
      variant='rounded'
      size={buttonStyle}
      colorScheme='primary'
      disabled={statusBannerStyle}
      _disabled={{
        bg: 'gray.dark',
        color: 'gray.light',
        opacity: 1
      }}
      {...props}
    >
      {statusBannerStyle ? 'Déjà postulé' : 'Postuler'}
    </Button>
  );
};

export const Show = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const toast = useToast();
  const titleTextStyle = useBreakpointValue({ base: 'section', md: 'h1' });

  const { data, loaded, loading, reload } = useDataLoader(() =>
    getMission(match.params.missionId).then(({ data }) => data)
  );

  const onApplyToMissionSuccess = data => {
    toast(
      toastOptions({
        description: 'Votre candidature a été transmise avec succès.',
        status: 'success'
      })
    );
    reload();
  };

  const applyToMission = () =>
    putMission(match.params.missionId, {
      missionStatusesAttributes: [
        { ...data?.missionStatuses[0], status: 'En cours de traitement' }
      ]
    }).then(onApplyToMissionSuccess);

  if ((!loaded && loading) || !data) {
    return null;
  }

  const getStatusBannerStyle = () => {
    switch (data?.missionStatuses[0]?.status) {
      case 'Transmis au client':
        return {
          message: 'FFI a transmis votre profil.',
          style: {
            bg: 'success',
            color: 'black.dark'
          }
        };
      case 'Candidature refusée':
        return {
          message: "Votre profil n'a pas été retenu.",
          style: {
            bg: 'orange',
            color: 'black.dark'
          }
        };
      case 'En cours de traitement':
        return {
          message:
            'FFI étudie votre dossier et vous retournera une réponse par mail.',
          style: {
            bg: 'bg.homepage'
          }
        };
      default:
        return null;
    }
  };

  const statusBannerStyle = getStatusBannerStyle();

  return (
    <chakra.article>
      <chakra.header position={[null, null, 'sticky']} top='0'>
        <Box
          d='flex'
          alignItems='center'
          flexWrap={['wrap', null, 'nowrap']}
          borderBottomWidth={1}
          borderColor='gray.default'
          pr={[4, null, 16]}
          pl={[4, null, 0]}
          py={3}
          bg='bg.dashboard'
        >
          <Grid gridTemplateColumns='auto 1fr'>
            <IconButton
              icon={<IconArrowLeft />}
              aria-label='Retour'
              alignSelf='baseline'
              w='16'
              mt='0.125rem'
              px='0'
              color='white'
              bg='transparent'
              flexShrink='0'
              d={['none', null, 'inline-flex']}
              onClick={() => history.goBack()}
            />
            <Box pr={[null, null, 5]}>
              <Heading textStyle={titleTextStyle} pr='8' mb={2}>
                {data.title}
              </Heading>
              <MissionMeta content={data} />
            </Box>
          </Grid>

          <Box
            w={['100%', null, 'auto']}
            ml={[null, null, 'auto']}
            mt={[4, null, 0]}
          >
            <MissionApplyButton
              statusBannerStyle={statusBannerStyle}
              onClick={applyToMission}
            />
          </Box>
        </Box>
        {statusBannerStyle ? (
          <Box
            {...statusBannerStyle.style}
            px={[4, null, 16]}
            py={['1.35rem']}
            shadow={[null, null, 'card']}
          >
            <Text>
              Vous avez postulé le{' '}
              {format(
                new Date(data?.missionStatuses[0]?.appliedOn || null),
                'dd/MM/yyyy à HH:mm'
              )}{' '}
              à cette mission. {statusBannerStyle.message}
            </Text>
          </Box>
        ) : null}
      </chakra.header>
      <Box px={[4, null, 16]} pb={[32, null, null, 8]}>
        <Box>
          <Text color='primary' my={[6, 8]}>
            publié le : {format(new Date(data.createdAt), 'dd/MM/yyyy')}
          </Text>
        </Box>
        <Box
          __css={{
            '> :last-child': {
              mb: 0
            },
            p: { mb: 4 },
            'ul, ol': {
              pl: 6,
              my: 5,
              'li::marker': {
                color: 'primary'
              }
            },
            a: { color: 'primary', _hover: { textDecor: 'underline' } },
            lineHeight: 1.5,
            h2: {
              fontSize: '1.875rem',
              fontFamily: 'heading',
              mb: 5
            }
          }}
          dangerouslySetInnerHTML={{
            __html: data.description
          }}
        />
        <MissionApplyButton
          statusBannerStyle={statusBannerStyle}
          onClick={applyToMission}
          ml='auto'
          mt={16}
        />
      </Box>
    </chakra.article>
  );
};
