import React from 'react';
import { List, ListIcon, ListItem } from '@chakra-ui/core';
import { IconDot } from '../Icons';

export const UList = ({ children, ...props }) => {
  return (
    <List pos='relative' pl={4} spacing={3} {...props}>
      {children}
    </List>
  );
};

export const UListItem = ({ children, ...props }) => {
  return (
    <ListItem color='gray.light' {...props}>
      <ListIcon
        as={IconDot}
        boxSize={2}
        color='primary'
        position='absolute'
        transform='translate(-1rem, 0.35em)'
      />
      {children}
    </ListItem>
  );
};
