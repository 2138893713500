import React from 'react';
import {
  Icon,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  FormLabel,
  FormErrorMessage,
  useBreakpointValue
} from '@chakra-ui/core';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useField } from 'formik';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { IconCalendar } from '../Icons';
import { parse } from 'date-fns';

// Datepicker locale
registerLocale('fr', fr);

const DatePickerCustomInput = React.forwardRef(
  ({ value, onClick, onChange, width, ...props }, ref) => {
    return (
      <InputGroup
        mb={0}
        css={{
          width: width || '20ch'
        }}
        onClick={onClick}
      >
        <InputLeftElement>
          <Icon
            as={IconCalendar}
            color='white'
            opacity={props.disabled ? '0.3' : '1'}
          />
        </InputLeftElement>
        <Input ref={ref} value={value} onChange={onChange} {...props} />
      </InputGroup>
    );
  }
);

const dateInputStyle = ({ theme, ml, isInline, twoColumns, isDark }) => css`
  .react-datepicker-wrapper {
    display: block;
  }
  .react-datepicker {
    width: 100%;
    margin-left: ${isInline ? 0 : ml};
    border: ${isInline ? '0' : `1px solid ${theme.colors.gray.default}`};
    border-radius: 4px;
    ${isInline ? null : `box-shadow: ${theme.shadows.card}`};
    background-color: ${isInline ? 'transparent' : theme.colors.bg.homepage};
    font-family: ${theme.fonts.body};
    ${theme.textStyles.mini};
    color: ${theme.colors.white};
    overflow: hidden;
    &-popper {
      z-index: 999;
    }
    &__header {
      display: flex;
      flex-flow: wrap column-reverse;
      font-family: ${theme.fonts.body};
      background-color: ${isInline ? 'transparent' : theme.colors.bg.homepage};
      border-radius: 0;
      color: ${theme.colors.white};
      border-bottom: 0;
      padding: 8px 0 17px;
      &__dropdown {
        // background-color: ${theme.colors.red};
      }
    }

    &.day-calendar {
      .react-datepicker__header {
        padding-top: 0;
        padding-bottom: 8px;
      }
    }

    &__day-names {
      order: -1;
    }

    &__current-month,
    &-time__header,
    &-year-header {
      color: ${theme.colors.white};
      font-weight: 600;
      font-size: ${theme.sizes[5]};
    }

    &__current-month {
      font-size: ${theme.fontSizes['2xl']};
      padding: ${theme.sizes[2]} 0;
      text-transform: capitalize;
    }

    &__navigation {
      top: 14px;
      &:focus {
        outline: none;
      }
      &--next {
        right: -4px;
        &:hover {
          border-left-color: ${theme.colors.white};
        }
        &:focus {
          border-left-color: ${theme.colors.primary};
        }
      }
      &--previous {
        left: -4px;
        &:hover {
          border-right-color: ${theme.colors.white};
        }
        &:focus {
          border-right-color: ${theme.colors.primary};
        }
      }
    }

    &.day-calendar {
      .react-datepicker__navigation {
        top: 18px;
      }
    }

    &__day {
      margin: 0.3rem;
      color: ${theme.colors.white};

      &:hover:not(.react-datepicker__day--disabled) {
        background-color: ${theme.colors.gray.dark}
      }
      &--disabled {
        color: ${theme.colors.gray.dark};
      }
      &--outside-month:not(.react-datepicker__day--disabled) {
        color: ${theme.colors.primary};
        opacity: 0.5;
      }
      &--disabled {
        cursor: not-allowed;
      }
      &-name {
        color: ${theme.colors.gray.light};
        font-weight: 500;
        margin: 0.3rem 0.3rem 0;
        text-transform: capitalize;
      }
    }
    &__year-read-view {
      padding: ${theme.sizes[2]} 0;
      &:hover {
        .react-datepicker__year-read-view--down-arrow {
          border-color: currentColor;
        }
      }
    }
    &__year-read-view--selected-year {
      font-size: 28px;
      font-weight: 600;
    }

    &.day-calendar {
      .react-datepicker__year-read-view--selected-year {
        font-size: ${theme.sizes[4]};
      }
    }

    &__month {
      margin-top: 0;
      ${isInline ? 'margin: 0;' : ''}

      &-wrapper {
        display: contents;
      }

      &-text {
        color: ${theme.colors.white};
        background-color: ${
          isDark ? theme.colors.black.dark : theme.colors.bg.dashboard
        };
        border-radius: 4px;
        ${theme.textStyles.mini};
        width: 100%;
        padding: 5px;
        margin: 0;

        &:not(.react-datepicker__month--disabled):not(.react-datepicker__month--selected):hover {
          opacity: 0.6;
        }
      }

      &--disabled {
        background-color: ${theme.colors.gray.dark};
        color: ${theme.colors.gray.default};
        cursor: not-allowed;
      }

      &-container {
        width: 100%;
      }
    }

    &__monthPicker {
      display: grid;
      grid-template-columns: repeat(${twoColumns ? 2 : 3}, 1fr);
      justify-content: stretch;
      grid-gap: 7px;
    }

    &__month-text--keyboard-selected:not(.react-datepicker__month--disabled),
    &__day--keyboard-selected,
    &__month--selected:not(.react-datepicker__month--disabled),
    &__day--selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.bg.homepage};

      &:focus {
        outline: none;
      }
    }
  }
`;

export const FormDatepicker = ({
  label,
  width,
  requiredInput,
  style,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const ml = useBreakpointValue({ base: '-20px', sm: 0 });

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  return (
    <FormControl
      {...style}
      css={theme =>
        dateInputStyle({
          theme,
          ml,
          isInline: props.inline,
          twoColumns: props.showTwoColumnMonths,
          isDark: props.dark
        })
      } // eslint-disable-line
      isInvalid={(!meta.touched && meta.error) || props.isInvalid}
    >
      {label && <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>}
      <DatePicker
        locale='fr'
        calendarClassName={
          props.showMonthYearPicker ? 'month-calendar' : 'day-calendar'
        }
        dateFormat='P'
        dateFormatCalendar='MMM yyyy'
        maxDate={new Date(currentYear + 5, 12, 0)}
        minDate={new Date(currentYear, currentMonth, 1)}
        customInput={<DatePickerCustomInput width={width} />}
        placeholderText='XX/XX/XXXX'
        {...field}
        id={props.id || props.name}
        selected={
          typeof meta.value === 'string'
            ? parse(meta.value, 'dd/MM/yyyy', new Date())
            : meta.value
        }
        onChange={date => helpers.setValue(date)}
        filterDate={date => {
          return new Date(currentYear, currentMonth, 1) <= date;
        }}
        {...props}
      />

      <FormErrorMessage flexDirection='column' alignItems='flex-start'>
        {typeof meta.error === 'string'
          ? meta.error
          : meta.error?.map(error => <p key={error}>{error}</p>)}
      </FormErrorMessage>
    </FormControl>
  );
};
