import React from 'react';
import {
  Box,
  Flex,
  HStack,
  List,
  useRadio,
  useRadioGroup
} from '@chakra-ui/core';
import RCPagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/fr_FR';

export const Pagination = ({ onChange, ...props }) => {
  const options = ['5', '10', '20'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'numberOfItems',
    defaultValue: props.perPage,
    onChange: perPage => {
      const newCurrentPage = Math.ceil((props.perPage * props.page) / perPage);

      onChange({
        perPage,
        page:
          newCurrentPage > props.total / perPage
            ? Math.ceil(props.total / perPage)
            : newCurrentPage
      });
    }
  });

  const group = getRootProps();

  return (
    <Flex flexDirection={['column', 'row']} justifyContent='space-between'>
      <Box mb={[4, 0]}>
        Affichage par page :{' '}
        <HStack d='inline-flex' ml={3} value={props.perPage} {...group}>
          {options.map(value => {
            const radio = getRadioProps({ value });
            return (
              <RadioLink key={value} {...radio}>
                {value}
              </RadioLink>
            );
          })}
        </HStack>
      </Box>

      <Box>
        pages :
        <List
          d='inline-flex'
          verticalAlign='top'
          flexDir='row'
          ml={3}
          as={RCPagination}
          locale={localeInfo}
          pageSize={Number(props.perPage)}
          current={props.page}
          onChange={page => onChange({ page })}
          {...props}
          __css={style}
        />
      </Box>
    </Flex>
  );
};

export const RadioLink = props => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        _checked={{
          color: 'primary'
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const style = {
  justifyContent: '',
  '> * + *': {
    ml: 2
  },
  '> *': {
    '&:focus:not(:focus-visible)': {
      outline: 'none'
    }
  },
  '.rc-pagination-item': {
    color: 'gray.light',
    cursor: 'pointer',
    '&:hover': {
      color: 'white'
    },
    '&-active': {
      color: 'primary'
    }
  },
  '.rc-pagination-next, .rc-pagination-prev': {
    display: 'flex',
    alignItems: 'center',

    button: {
      display: 'inline-block',
      height: 0,
      width: 0,
      borderTop: '6px solid transparent',
      borderBottom: '6px solid transparent',
      borderLeft: '13.86px solid white',
      '&:focus:not(:focus-visible)': {
        outline: 'none'
      },
      '&:hover:not(:disabled)': {
        borderLeftColor: 'primary'
      },
      '&:disabled': {
        borderLeftColor: 'gray.dark'
      }
    }
  },
  '.rc-pagination-prev': {
    button: {
      transform: 'rotate(180deg)'
    }
  },
  '[class^=rc-pagination-jump] ': {
    '&:hover': {
      'button:before': {
        color: 'white'
      }
    },
    'button:before': {
      color: 'gray.light',
      content: "'...'"
    }
  }
};
