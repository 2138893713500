/* eslint-disable no-nested-ternary, multiline-ternary */
import React, { useEffect } from 'react';
import { useField } from 'formik';
import {
  FormControl,
  useTheme,
  Text,
  FormErrorMessage,
  FormLabel,
  useStyleConfig,
  css,
  Box,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/core';
import ReactSelect, { components as RCcomponents } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { IconChevronDown, IconClose } from '../Icons';

const selectStyle = ({
  theme,
  controlStyle,
  tagStyle,
  isInvalid,
  hasLeftIcon
}) => {
  return {
    container: provided => {
      return {
        ...provided,
        width: '100%'
      };
    },
    placeholder: provided => {
      return {
        ...provided,
        color: theme.colors.gray.medium,
        fontWeight: 'normal'
      };
    },
    clearIndicator: provided => {
      return {
        ...provided
      };
    },
    dropdownIndicator: (provided, { isMulti }) => {
      return {
        ...provided,
        display: isMulti ? 'none' : provided.display
      };
    },
    indicatorSeparator: provided => {
      return {
        ...provided,
        display: 'none'
      };
    },
    indicatorsContainer: (provided, { isDisabled }) => {
      return {
        ...provided,
        svg: {
          color: isDisabled ? theme.colors.gray.default : theme.colors.primary
        }
      };
    },
    option: (provided, { isSelected, isFocused }) => {
      let style;
      if (isSelected) {
        style = {
          backgroundColor: theme.colors.gray.medium,
          fontWeight: 600
        };
      }
      if (isFocused && !isSelected) {
        style = {
          backgroundColor: '#4E4F46'
        };
      }
      return {
        ...provided,
        borderBottomWidth: '1px',
        borderColor: 'transparent',
        ...css({
          '&:not(:last-of-type)': {
            borderColor: 'gray.medium'
          }
        })(theme),
        fontSize: theme.fontSizes.md,
        ':active': {
          backgroundColor: 'none'
        },
        cursor: 'pointer',
        transition: '0.3s',
        ...style
      };
    },
    control: (provided, { isFocused, isDisabled, isSelected }) => ({
      ...provided,
      ...css(controlStyle.field)(theme),
      height: 'auto',
      minHeight: '2.5rem',
      paddingTop: '0.125rem',
      paddingBottom: '0.125rem',
      ...(!hasLeftIcon || css({ pl: controlStyle.field.h })(theme)),
      ...(isFocused ? css(controlStyle.field._focus)(theme) : {}),
      ...(isDisabled ? css(controlStyle.field._disabled)(theme) : {}),
      ...(isInvalid ? css(controlStyle.field._invalid)(theme) : {})
    }),
    valueContainer: provided => ({
      ...provided,
      padding: 0
    }),
    input: (provided, { isFocused }) => ({
      ...provided,
      color: theme.colors.white
    }),
    singleValue: provided => ({
      ...provided,
      color: theme.colors.white
    }),
    multiValue: (provided, { isFocused }) => ({
      ...provided,
      ...css(tagStyle.container)(theme),
      ...(!isFocused || css({ bg: 'primary' })(theme)),
      span: {
        display: 'none'
      }
    }),
    multiValueLabel: provided => {
      return {
        ...provided,
        ...css(tagStyle.label)(theme)
      };
    },
    multiValueRemove: (provided, { isFocused }) => ({
      ...provided,
      ...css(tagStyle.closeButton)(theme),
      ...(!isFocused ||
        css({ ...tagStyle.closeButton._hover, color: 'primary' })(theme))
    }),
    menu: provided => ({
      ...provided,
      ...css({
        mt: 0,
        padding: 0,
        border: 0,
        borderRadius: 0,
        background: 'gray.dark'
      })(theme)
    }),
    menuList: provided => ({
      ...provided,
      borderRadius: 0,
      padding: 0
    })
  };
};

const DropdownIndicator = props => {
  return (
    <RCcomponents.DropdownIndicator {...props}>
      <IconChevronDown strokeWidth={2} />
    </RCcomponents.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return (
    <RCcomponents.ClearIndicator {...props}>
      <IconClose boxSize={4} strokeWidth={2} />
    </RCcomponents.ClearIndicator>
  );
};

const MultiValue = props => {
  useEffect(() => {
    const multiValues = document.querySelectorAll('[class*=-multiValue]');

    if (props.isFocused) {
      multiValues[props.index].parentElement.scrollLeft =
        multiValues[props.index].offsetLeft - 10;
    }
  }, [props.isFocused, props.index]);

  return <RCcomponents.MultiValue {...props} />;
};

const MultiValueRemove = props => {
  return (
    <RCcomponents.MultiValueRemove {...props}>
      <IconClose boxSize={3} />
    </RCcomponents.MultiValueRemove>
  );
};

export const FormSelect = ({
  label,
  styles,
  creationText,
  isCreatable,
  isAsync,
  components,
  leftIcon,
  mb,
  ...props
}) => {
  const theme = useTheme();
  const controlStyle = useStyleConfig('Input', {});
  const tagStyle = useStyleConfig('Tag', {});
  const { id, name } = props;

  const Select = isCreatable
    ? isAsync
      ? AsyncCreatableSelect // eslint-disable-line
      : CreatableSelect // eslint-disable-line
    : isAsync
    ? AsyncSelect // eslint-disable-line
    : ReactSelect; // eslint-disable-line
  const [field, meta, helpers] = useField(props);
  const isInvalid = (!meta.touched && meta.error) || props.isInvalid;

  const onInputChange = (inputValue, { action }) => {
    switch (action) {
      case 'set-value': {
        const input = document.querySelector('[class*=-Input]');
        if (input) {
          setTimeout(
            () => (input.parentElement.scrollLeft = input.offsetLeft),
            100
          );
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <FormControl as={Box} {...styles} isInvalid={isInvalid}>
      {label && <FormLabel htmlFor={id || name}>{label}</FormLabel>}
      <InputGroup>
        {leftIcon ? (
          <InputLeftElement pointerEvents='none'>{leftIcon}</InputLeftElement>
        ) : null}
        <Select
          styles={selectStyle({
            theme,
            controlStyle,
            tagStyle,
            isInvalid,
            hasLeftIcon: !!leftIcon
          })}
          components={{
            DropdownIndicator,
            ClearIndicator,
            MultiValue,
            MultiValueRemove,
            ...components
          }}
          onKeyDown={e => {
            if (
              e.key === 'Enter' &&
              !document.querySelector('[class*=-option]')
            ) {
              e.preventDefault();
            }
          }}
          onInputChange={onInputChange}
          formatCreateLabel={value => (
            <Text d='flex' justifyContent='space-between'>
              <Text as='span'>
                Créer : "
                <Text as='b' textTransform='normal'>
                  {value}
                </Text>
                "
              </Text>
              <Text as='em'>{creationText}</Text>
            </Text>
          )}
          {...field}
          value={meta.value}
          onChange={value => {
            helpers.setValue(value);
            helpers.setTouched(true);
          }}
          {...props}
        />
      </InputGroup>
      <FormErrorMessage flexDirection='column' alignItems='flex-start' mt={1}>
        {typeof meta.error === 'string'
          ? meta.error
          : meta.error?.map(error => <p key={error}>{error}</p>)}
      </FormErrorMessage>
    </FormControl>
  );
};
