/* eslint-disable indent */
import React from 'react';
import {
  Divider,
  Flex,
  HStack,
  Link,
  List,
  ListItem,
  Text
} from '@chakra-ui/core';
import { format } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { Card } from '../../../../../common/components/Card';
import { Stat } from '../../../../../common/components/Stat';
import { getDashboardPath } from '../../../../../common/routes';
import { useAuthState } from '../../../../auth';
import { IndexContainer } from '../../../components/Container';

export const ProfileRecap = () => {
  const { user, profileCompletion } = useAuthState();

  return (
    <IndexContainer pb={[4, null, 8]} pt={[4, null, 8]}>
      <Card variant='outline' bg='bg.homepage' py={{ base: 6, md: 4 }}>
        <Text fontSize='lg' d={{ base: 'none', md: 'block' }}>
          {user?.firstName || '-'} {user?.lastName || '-'}
        </Text>
        <Flex flexDir={{ base: 'column', xl: 'row' }}>
          <Flex
            color='gray.light'
            textStyle='mini'
            mt={2}
            d={{ base: 'none', md: 'flex' }}
            flexGrow='1'
          >
            <List w={1 / 2} spacing={1}>
              <ListItem>
                {user?.yearsOfExperience || '-'} années d'expérience
              </ListItem>
              <ListItem>
                Disponibilité :{' '}
                {user?.searchAvailability === 'Immédiate' ||
                user?.searchAvailability === 'Pas de visibilité'
                  ? user?.searchAvailability
                  : user?.searchAvailability === null
                  ? '-'
                  : `à partir du ${format(
                      new Date(user?.searchAvailability),
                      'dd/MM/yyyy'
                    )}`}
              </ListItem>
              <ListItem>Métier souhaité : {user?.searchTitle || '-'}</ListItem>
            </List>
            <List w={1 / 2} spacing={1} ml={4}>
              <ListItem>
                Membre FFI depuis le{' '}
                {format(new Date(user?.createdAt), 'dd/MM/yyyy') || '-'}
              </ListItem>
              <ListItem>Tarif : {user?.searchPricing || '-'}€/jour</ListItem>
              <ListItem>
                Localisations souhaitées :{' '}
                {user?.locations.map(location => location.name).join(', ')}
              </ListItem>
            </List>
          </Flex>
          <Divider
            d={{ base: 'none', xl: 'block' }}
            orientation='vertical'
            alignSelf='stretch'
            height='auto'
            borderColor='gray.default'
            px={4}
          />
          <HStack spacing={4} flexGrow='1' mt={{ md: 4, xl: 'auto' }}>
            <Stat
              width='100%'
              maxW='128px'
              color='primary'
              percent={profileCompletion}
              label={`${Math.ceil(profileCompletion * 100)}%`}
            />
            <Text>
              Votre profil est complété à : {Math.ceil(profileCompletion * 100)}
              %
              <br />
              <Link
                as={NavLink}
                to={getDashboardPath(['profile'])}
                color='primary'
                textDecor='underline'
              >
                Modifier
              </Link>
            </Text>
          </HStack>
        </Flex>
      </Card>
    </IndexContainer>
  );
};
