import React from 'react';
import { Route, Switch } from 'react-router';
import { Index } from './Index/Index';

export const Candidacies = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}`} component={Index} />
    </Switch>
  );
};
