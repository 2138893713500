import React from 'react';
import { Global } from '@emotion/core';
import { PublicLayout } from '../../common/layouts/PublicLayout';
import { AllFree } from './components/AllFree';
import { Ffi } from './components/FFI';
import { Hero } from './components/Hero';
import { Confidentiality } from './components/Confidentiality';
import { ChargesAndRemuneration } from './components/ChargesAndRemuneration';
// import { References } from './components/References';
import { Artist } from './components/Artist';

export const Home = () => {
  return (
    <PublicLayout>
      <Global
        styles={theme => ({
          html: { scrollBehavior: 'smooth' }
        })}
      />
      <Hero />
      <Ffi />
      <ChargesAndRemuneration />
      <Confidentiality />
      <AllFree />
      {/* <References /> */}
      <Artist />
    </PublicLayout>
  );
};
