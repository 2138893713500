import React from 'react';
import { Box, Button, Heading, Image, Link, Text } from '@chakra-ui/core';

import allFreeImage from '../../../common/assets/images/all-free.jpg';
import allFreeImage2x from '../../../common/assets/images/all-free@2x.jpg';
import { Col, Container, Row } from '../../../common/components/Grid';
import { Card } from '../../../common/components/Card';

export const AllFree = () => {
  return (
    <Box
      as='section'
      d={{ md: 'grid' }}
      gridTemplateColumns='0.65fr 1fr'
      placeItems='center'
      mt={{ sm: 24, md: 32 }}
      id='axes-commerciaux'
    >
      <Image
        pointerEvents='none'
        gridColumn={{ md: 2 }}
        gridRow={{ md: '1/-1' }}
        srcSet={`${allFreeImage} 1x,${allFreeImage2x} 2x`}
        objectFit='cover'
        objectPosition={{ base: '68%', md: '38%' }}
        h='100%'
        w='100%'
        maxW='none'
        htmlHeight='500px'
        htmlWidth='500px'
        loading='lazy'
      />
      <Text
        gridColumn={{ md: '2 / span 1' }}
        gridRow={{ md: '1 / span 1' }}
        alignSelf='end'
        justifySelf='start'
        px={2}
        pb={2}
        fontSize='0.75rem'
        position={['absolute', null, 'static']}
        transform={['translateY(-100%)', null, 'none']}
        fontWeight='300'
        opacity='50%'
      >
        © José Antoine Costa
      </Text>
      <Box
        gridColumn={{ md: '1/-1' }}
        w='full'
        gridRow={{ md: 1 }}
        bg={['bg.homepage', null, 'transparent']}
      >
        <Container>
          <Row alignItems={{ md: 'center' }} my={{ sm: '10%' }}>
            <Col w={{ md: 8 / 12 }}>
              <Card
                marginX={{ base: '-32px', lg: 0 }}
                shadow={['none', null, 'card']}
                bg={['bg.homepage', null, 'black.light']}
              >
                <Box px={{ base: 0, lg: 6 }} py={{ base: 0, lg: 4 }}>
                  <Heading
                    textStyle='h1'
                    mb={4}
                    maxW='523px'
                    fontSize={{ base: '30px', md: '40px' }}
                  >
                    Proposer une mission ou un contact à la Fédération
                  </Heading>
                  <Text mb={3}>
                    Vous pouvez contribuer à solidifier notre Fédération, un
                    réseau qui se préoccupe de la condition des indépendants,
                    tout en faisant bénéficier vos partenaires des compétences
                    de profils expérimentés.
                  </Text>
                  <Heading
                    textStyle='h1'
                    as='h3'
                    mb={4}
                    mt={8}
                    color='primary'
                    fontSize={{ base: '24px', md: '30px' }}
                  >
                    Proposez une mission
                  </Heading>
                  <Text mb={3} color='gray.light'>
                    Vous avez eu vent d’une mission chez un partenaire ou un
                    prospect et souhaitez partager cette information à la FFI,
                    n’hésitez plus. <br />
                    Profitez d’une prime de 1000 euros si la mission proposée
                    venait à se concrétiser.
                  </Text>
                  <Heading
                    textStyle='h1'
                    as='h3'
                    mb={4}
                    color='primary'
                    fontSize={{ base: '24px', md: '30px' }}
                  >
                    Proposez un contact
                  </Heading>
                  <Text mb={3} color='gray.light'>
                    Vous connaissez ou avez eu l’occasion de rencontrer un
                    contact intéressant chez un partenaire ou prospect et vous
                    souhaitez le partager avec la FFI, n’hésitez plus. <br />
                    Le contact est d’abord étudié par la FFI, si la relation
                    aboutit à un accord commercial immédiat ou futur vous
                    bénéficiez d’une prime de 1000 euros.
                  </Text>
                  <Button
                    as={Link}
                    href='mailto:contact@all-ffi.com'
                    mt={6}
                    colorScheme='primary'
                    size='lg'
                  >
                    Proposez un axe commercial
                  </Button>
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>
    </Box>
  );
};
