/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Grid, Text, VStack } from '@chakra-ui/core';
import { useDataLoader } from 'react-remote-data-hooks';
import qs from 'qs';
import { getMissions } from '../../../../common/api/missions';
import { Pagination } from '../../../../common/components/Pagination';
import { IndexContainer } from '../../components/Container';
import { MissionCard, MissionCardSkeleton } from '../../components/Mission';
import { Filters } from './components/Filters';

export const Index = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParameters = useMemo(
    () =>
      qs.parse(location.search, {
        skipNulls: true,
        ignoreQueryPrefix: true
      }),
    [location.search]
  );

  const [filters, setFilters] = useState({
    page: 1,
    perPage: '5',
    byMissionStatuses: [],
    ...queryParameters
  });
  const { data, loaded, loading, reload } = useDataLoader(() =>
    getMissions({ byCurrentUserApplied: true, ...filters }).then(
      ({ data }) => data
    )
  );

  useEffect(() => {
    reload();
    history.push({
      pathname: location.pathname,
      search: qs.stringify(filters, { skipNulls: true })
    });
  }, [filters]);

  const missions =
    (!loaded && loading) || !data
      ? [...Array(5)].map((i, index) => <MissionCardSkeleton key={index} />)
      : data?.missions?.map((mission, index) => (
          <MissionCard key={index} variant='candidacy' content={mission} /> // eslint-disable-line
        )); // eslint-disable-line

  return (
    <IndexContainer minH='100vh' d='flex' flexDir='column'>
      <Filters
        mb={[4, 8]}
        initialValues={filters}
        data={
          data || {
            missionsCurrentUserAppliedToCount: {
              enCoursDeTraitement: 0,
              transmisAuClient: 0,
              candidatureRefusee: 0
            }
          }
        }
        onSubmit={newFilters => {
          window.scrollTo(0, 0);
          setFilters({ ...filters, ...newFilters });
        }}
      />
      <VStack
        align='stretch'
        spacing={[4, 8]}
        mb={[4, 8]}
        flex='1'
        justify={data?.missions?.length ? 'flex-start' : 'center'}
      >
        {data?.missions?.length || (!loaded && loading) ? (
          missions
        ) : (
          <Grid placeItems='center' h='100%'>
            <Text textAlign='center'>
              Vous n'avez encore aucune candidature.
            </Text>
          </Grid>
        )}
      </VStack>
      <Pagination
        mt='auto'
        onChange={newPagination => {
          window.scrollTo(0, 0);
          setFilters({ ...filters, ...newPagination });
        }}
        page={Number(filters.page)}
        perPage={filters.perPage}
        total={data?.meta?.totalCount}
      />
    </IndexContainer>
  );
};
