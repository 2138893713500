import Input from './Input';

const Textearea = {
  sizes: {
    md: {
      ...Input.sizes.md.field
    }
  },
  variants: {
    outline: {
      ...Input.variants.outline.field
    }
  },
  defaultProps: { size: 'md', variant: 'outline' }
};

export default Textearea;
