import { api, apiV1 } from './api';

export const register = user =>
  api.post('/users', { user }, { withCredentials: false });

export const login = user => api.post('/users/sign_in', { user });

export const logout = () => api.delete('/users/sign_out');

export const sendPasswordReset = user => apiV1.post('/passwords', { user });

export const passwordReset = user => apiV1.put('/passwords', { user });
