import axios from 'axios';
import applyConverters from 'axios-case-converter';
import qs from 'qs';

const options = {
  preservedKeys: input => {
    return input.match(/^_/);
  }
};

export const api = applyConverters(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    withCredentials: true
  }),
  options
);

const apiV1 = applyConverters(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
    withCredentials: true,
    paramsSerializer: function (params) {
      return qs.stringify(params, { skipNulls: true, arrayFormat: 'brackets' });
    }
  }),
  options
);

export { apiV1 };
