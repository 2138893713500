const FormLabel = {
  baseStyle: {
    errorText: {
      color: 'error',
      mt: 0
    }
  }
};

export default FormLabel;
