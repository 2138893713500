import React from 'react';
import { Box, HStack, Link, List, ListItem } from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';

export const TabNavigation = ({ items, ...props }) => {
  return (
    <Box
      as='nav'
      overflowX={{ base: 'auto', md: 'visible' }}
      pb={{ base: 5, md: 0 }}
      fontSize={{ sm: '1.125rem' }}
    >
      <HStack
        as={List}
        borderBottom='1px'
        borderBottomColor='gray.default'
        minW='min-content'
        spacing={{ base: 8, sm: 16 }}
        pt={{ base: 3, md: 7 }}
        px={{ base: 6, md: 16 }}
        bgColor='bg.dashboard'
      >
        {items.map((item, index) => (
          <ListItem key={index}>
            <Link
              as={NavLink}
              to={item.path}
              d='inline-block'
              pb={2}
              marginBottom='-5px'
              borderBottomWidth={4}
              borderBottomColor='transparent'
              color='gray.light'
              _hover={{ textDecoration: 'none', color: 'white' }}
              _focus={{ boxShadow: 'none' }}
              _activeLink={{
                color: 'white',
                fontWeight: 600,
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px',
                borderBottomColor: 'primary'
              }}
            >
              {item.label}
            </Link>
          </ListItem>
        ))}
      </HStack>
    </Box>
  );
};
