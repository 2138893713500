const Menu = {
  parts: ['field', 'addon'],
  baseStyle: {
    list: {
      bg: 'bg.homepage',
      boxShadow: 'card',
      mt: '-2px',
      py: '3',
      zIndex: 1,
      borderRadius: '4px',
      borderWidth: '1px',
      borderColor: 'gray.medium'
    }
  }
};

export default Menu;
