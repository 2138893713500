/* eslint-disable multiline-ternary, indent */
import React, { createRef, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Spinner,
  Stack,
  Text,
  useToast
} from '@chakra-ui/core';
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik';
import Axios from 'axios';
import { FormInput } from '../../../common/components/Form';
import { Container } from '../../../common/components/Grid';
import { Card } from '../../../common/components/Card';
import { uploadFile } from '../../../common/utils/uploadFile';
import { toastOptions } from '../../../common/components/Toast';
import { useAuthDispatch, useAuthState } from '../../auth';
import { updateUser } from '../../auth/actions';
import { IconCheck } from '../../../common/components/Icons';

export const Enrolment = () => {
  const idDocumentRef = createRef();
  const toast = useToast();
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const [isSearching, setIsSearching] = useState(false);

  return (
    <Container>
      <Formik
        initialValues={{ ...user, idDocument: null }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values, actions) => {
          const { idDocument, ...enrolment } = values;
          // Send unless form isn't filled
          if (
            values.sirenOrSiret &&
            values.corporateName &&
            values.idDocumentFilename
          ) {
            if (idDocument.length) {
              uploadFile({
                file: idDocument[0] // + new Date().getTime()
              })
                .then(key => {
                  updateUser(dispatch, {
                    ...enrolment,
                    idDocumentKey: key
                  })
                    .then(() => {
                      actions.setSubmitting(false);
                      toast(
                        toastOptions({
                          description: 'Vos informations ont été enregistrées.',
                          status: 'success'
                        })
                      );
                    })
                    .catch(err => {
                      actions.setSubmitting(false);
                      actions.setTouched({ idDocument: true });

                      if (err.response) {
                        actions.setErrors(err.response?.data?.errors);
                      }
                    });
                })
                .catch(e => {
                  actions.setTouched({ idDocument: true });
                  actions.setFieldError(
                    'idDocument',
                    e.response?.data?.errors ||
                      "Le chargement de votre pièce d'identité à échouer, merci de recommencer."
                  );
                });
            } else {
              actions.setSubmitting(false);
              actions.setTouched({ idDocument: true });
              actions.setErrors({ idDocument: 'doit être rempli(e)' });
            }
          } else {
            actions.setSubmitting(false);
          }
        }}
      >
        {formik => (
          <Card
            w={{ base: 'auto', md: 3 / 4, lg: 1 / 2 }}
            mx={[-4, 'auto']}
            bg='bg.dashboard'
          >
            <Form>
              <Stack spacing={[4, 6]}>
                <Heading textStyle='h1'>Adhésion</Heading>
                {user.sirenOrSiret && user.corporateName ? (
                  <Alert status='success' variant='solid' background='success'>
                    <AlertIcon flexShrink='0' />
                    <AlertDescription>
                      Après vérification de vos identifiants et documents nous
                      procéderons à votre adhésion, nous vous confirmerons au
                      plus tôt votre inscription.
                    </AlertDescription>
                  </Alert>
                ) : user.registrationStatus === 'registered' ? (
                  <Alert
                    status='info'
                    variant='solid'
                    background='gray.default'
                  >
                    <AlertIcon flexShrink='0' />
                    <AlertDescription>
                      Afin de finaliser votre inscription, nous devons vérifier
                      votre statut d’indépendant
                    </AlertDescription>
                  </Alert>
                ) : user.registrationStatus === 'rejected' ? (
                  <Alert status='error' variant='solid' background='error'>
                    <AlertIcon flexShrink='0' />
                    <AlertDescription>
                      Après vérification, actuellement les informations
                      renseignées ne nous permettent pas de valider votre
                      inscription. Si votre statut d'indépendant est en cours de
                      finalisation, il vous faut préalablement terminer le
                      processus avant d'adhérer à la FFI. Vous pouvez soumettre
                      à nouveau votre demande d’inscription à tout moment.
                    </AlertDescription>
                  </Alert>
                ) : null}

                <Box>
                  <Box pos='relative'>
                    <FormInput label='SIREN / SIRET *' name='sirenOrSiret' />
                    {!formik.errors.sirenOrSiret &&
                    formik.values.sirenOrSiret &&
                    !isSearching ? (
                      <IconCheck
                        boxSize={4}
                        pos='absolute'
                        right='0.5rem'
                        top='2.35rem'
                        zIndex='2'
                      />
                    ) : null}
                    {isSearching ? (
                      <Spinner
                        boxSize={4}
                        pos='absolute'
                        right='0.5rem'
                        top='2.35rem'
                        zIndex='2'
                      />
                    ) : null}
                  </Box>
                </Box>

                <FetchCorporateName setIsSearching={setIsSearching} />

                <FormInput
                  label='Dénomination *'
                  name='corporateName'
                  readOnly
                  cursor='not-allowed'
                  opacity='0.6'
                />

                <FormInput
                  label='Nom du gérant *'
                  name='businessOwnerName'
                  readOnly
                  cursor='not-allowed'
                  opacity='0.6'
                />

                <Flex wrap='wrap' alignItems='center' pt={3}>
                  <Button
                    htmlFor='idDocument'
                    as={FormLabel}
                    borderColor={formik.errors.idDocument ? 'error' : undefined}
                    borderWidth={formik.errors.idDocument ? '1px' : undefined}
                    variant='rounded'
                    size='lg'
                    d='inline-flex'
                    colorScheme='secondary'
                    whiteSpace='break-spaces'
                    textAlign='center'
                    mb={0}
                    mr={{ base: 0, md: 3 }}
                    width={{ base: '100%', md: 'auto' }}
                  >
                    Télécharger une pièce d'identité *
                  </Button>
                  <Text as='small' py={2} width={{ base: '100%', md: 'auto' }}>
                    {formik.values.idDocumentFilename}
                  </Text>
                  <Input
                    ref={idDocumentRef}
                    type='file'
                    name='idDocument'
                    id='idDocument'
                    display='none'
                    onChange={event => {
                      formik.setFieldTouched('idDocument', false);
                      if (event.currentTarget.files.length > 0) {
                        const filename = event.currentTarget.files[0].name;
                        formik.setFieldValue('idDocumentFilename', filename);
                        formik.setFieldValue(
                          'idDocument',
                          event.currentTarget.files
                        );
                      }
                    }}
                  />
                  <FormControl
                    w='100%'
                    isInvalid={
                      formik.touched.idDocument && formik.errors.idDocument
                    }
                  >
                    <FormErrorMessage>
                      <ErrorMessage name='idDocument' />
                    </FormErrorMessage>
                  </FormControl>
                  {user.idDocumentUrl ? (
                    <Text as='small' mt={4}>
                      Pièce d'identité actuelle :{' '}
                      <Link
                        href={user.idDocumentUrl}
                        color='primary'
                        textDecor='underline'
                      >
                        {user.idDocumentFilename}
                      </Link>
                    </Text>
                  ) : null}
                </Flex>
              </Stack>

              <Button
                mt={[8, 12]}
                type='submit'
                colorScheme='primary'
                size='full'
                isLoading={formik.isSubmitting}
                isDisabled={
                  !formik.values.sirenOrSiret ||
                  !formik.values.corporateName ||
                  !formik.values.idDocumentFilename
                }
              >
                Finaliser mon inscription
              </Button>
            </Form>
          </Card>
        )}
      </Formik>
    </Container>
  );
};

const FetchCorporateName = ({ setIsSearching }) => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    setValues
  } = useFormikContext();

  const handleErrors = err => {
    setFieldTouched('sirenOrSiret', false);
    setFieldValue('corporateName', '');
    setFieldValue('businessOwnerName', '');
    setIsSearching(false);

    if (err.response) {
      switch (err.response.status) {
        case 404:
          setFieldError('sirenOrSiret', 'SIREN / SIRET inconnu');
          break;
        default:
          setFieldError(
            'sirenOrSiret',
            'Impossible de vérifier le SIREN / SIRET'
          );
          break;
      }
    } else {
      setFieldError(
        'sirenOrSiret',
        'Impossible de vérifier le SIREN / SIRET (Erreur interne)'
      );
    }
  };

  const getSirenOrSiretData = async (data, sirenOrSiret) => {
    const base = sirenOrSiret.length === 14 ? data.etablissement : data;

    let businessOwner;
    let uniteLegaleActivitePrincipaleLabel;
    let etablissementActivitePrincipaleLabel;
    let legalCategoryLabel;
    let representants;

    try {
      ({
        data: { intitule: uniteLegaleActivitePrincipaleLabel }
      } = await Axios.get(
        `https://api.insee.fr/metadonnees/V1/codes/nafr2/sousClasse/${encodeURIComponent(
          base.unite_legale.activite_principale
        )}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SIREN_API_KEY}`
          }
        }
      ));
    } catch (e) {}

    try {
      ({
        data: { intitule: etablissementActivitePrincipaleLabel }
      } = await Axios.get(
        `https://api.insee.fr/metadonnees/V1/codes/nafr2/sousClasse/${encodeURIComponent(
          base.unite_legale.etablissement_siege.activite_principale
        )}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SIREN_API_KEY}`
          }
        }
      ));
    } catch (e) {}

    try {
      ({
        data: { intitule: legalCategoryLabel }
      } = await Axios.get(
        `https://api.insee.fr/metadonnees/V1/codes/cj/n3/${encodeURIComponent(
          base.unite_legale.categorie_juridique
        )}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SIREN_API_KEY}`
          }
        }
      ));
    } catch (e) {}

    if (!base.unite_legale.nom) {
      try {
        ({
          data: {
            dossier_entreprise_greffe_principal: { representants }
          }
        } = await Axios.get(
          `https://entreprise.data.gouv.fr/api/rncs/v1/fiches_identite/${encodeURIComponent(
            sirenOrSiret.slice(0, 9)
          )}`
        ));

        businessOwner = representants
          .filter(rep => rep.qualite !== 'Associé')
          .map(
            rep =>
              `${rep.prenoms || ''} ${(
                rep.nom_usage ||
                rep.nom_patronyme ||
                rep.denomination
              )?.toUpperCase()}`
          )
          .join(', ');
      } catch (e) {}
    }

    setIsSearching(false);

    console.log(
      base.unite_legale.caractere_employeur,
      base.unite_legale.caractere_employeur === 'O'
    );

    return {
      corporateName:
        base.unite_legale.denomination ||
        `${base.unite_legale.prenom_1} ${base.unite_legale.nom}`,
      birthName: base.unite_legale.nom,
      usualName: base.unite_legale.nom_usage,
      legalCategory: `${base.unite_legale.categorie_juridique}${
        legalCategoryLabel ? ` - ${legalCategoryLabel}` : ''
      }`,
      legalUnitState:
        base.unite_legale.etat_administratif === 'A' ? 'Active' : 'Fermé',
      businessState:
        base.unite_legale.etablissement_siege.etat_administratif === 'A'
          ? 'Actif'
          : 'Fermé',
      nicNumber: base.nic || base.unite_legale.nic_siege,
      legalUnitMainActivity: `${base.unite_legale.activite_principale}${
        uniteLegaleActivitePrincipaleLabel
          ? ` - ${uniteLegaleActivitePrincipaleLabel}`
          : ''
      }`,
      isLegalUnitAnEmployer: base.unite_legale.caractere_employeur === 'O',
      isPartOfEss: base.unite_legale.economie_sociale_solidaire === 'O',
      businessSign: base.unite_legale.denomination,
      businessMainActivity: `${
        base.unite_legale.etablissement_siege.activite_principale
      }${
        etablissementActivitePrincipaleLabel
          ? ` - ${etablissementActivitePrincipaleLabel}`
          : ''
      }`,
      isBusinessAnEmployer:
        base.unite_legale.etablissement_siege.caractere_employeur === 'O',
      businessOwnerName: base.unite_legale.nom
        ? `${base.unite_legale.prenom_1 || ''} ${base.unite_legale.nom}`
        : businessOwner || 'gérant non indiqué'
    };
  };

  useEffect(() => {
    setFieldError('sirenOrSiret', '');
    if (values.sirenOrSiret?.length === 14) {
      setIsSearching(true);
      Axios.get(
        `https://entreprise.data.gouv.fr/api/sirene/v3/etablissements/${encodeURIComponent(
          values.sirenOrSiret
        )}`
      )
        .then(async ({ data }) => {
          const sirenOrSiretData = await getSirenOrSiretData(
            data,
            values.sirenOrSiret
          );

          setValues({
            ...values,
            ...sirenOrSiretData
          });
        })
        .catch(handleErrors);
    } else if (values.sirenOrSiret?.length === 9) {
      setIsSearching(true);
      Axios.get(
        `https://entreprise.data.gouv.fr/api/sirene/v3/unites_legales/${encodeURIComponent(
          values.sirenOrSiret
        )}`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SIREN_API_KEY}`
          }
        }
      )
        .then(async ({ data }) => {
          const sirenOrSiretData = await getSirenOrSiretData(
            data,
            values.sirenOrSiret
          );

          setValues({
            ...values,
            ...sirenOrSiretData
          });
        })
        .catch(handleErrors);
    } else {
      if (values.sirenOrSiret) {
        setFieldTouched('sirenOrSiret', false);
        setFieldError(
          'sirenOrSiret',
          'Erreur de format - Format attendu : 9 chiffres pour le SIREN ou 14 chiffres pour le SIRET'
        );
      }
      setFieldValue('corporateName', '');
      setFieldValue('businessOwnerName', '');
    }
  }, [values.sirenOrSiret]);

  return null;
};
