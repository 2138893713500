import React from 'react';
import { Avatar } from '@chakra-ui/avatar';
import { Box, Heading, Text } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { chakra } from '@chakra-ui/system';
import { Container } from '../../../common/components/Grid';

export const ArtistHeader = ({
  artistName,
  artistQuote,
  bgImage,
  avatarImage,
  children,
  ...props
}) => {
  const avatarSize = useBreakpointValue({
    base: 'xl',
    md: '',
    xxl: '2xl'
  });

  return (
    <chakra.section {...props} pos='relative'>
      <Box
        opacity='0.5'
        backgroundImage={`url("${bgImage}")`}
        backgroundSize='auto 170vh'
        backgroundPosition='center'
        pos='absolute'
        inset='0'
      />
      <Container
        position='relative'
        px={{ base: 0, md: 8 }}
        pb={{ base: 0, sm: 12, lg: 24 }}
        zIndex='1'
      >
        <Box textAlign='center' pt={{ base: 12, md: 24 }}>
          <Avatar
            pointerEvents='none'
            src={avatarImage}
            size={avatarSize}
            pos='absolute'
            top={0}
            left='50%'
            transform='translate(-50%,-50%)'
          />
        </Box>
        <Heading
          textAlign='center'
          color='primary'
          fontSize={{ base: '3xl', md: '5xl' }}
        >
          {artistName}
        </Heading>
        <Text
          textAlign='center'
          fontSize={{ base: 'xl', lg: '2.25rem' }}
          px={4}
          mt={10}
        >
          {artistQuote}
        </Text>
        {children}
      </Container>
    </chakra.section>
  );
};
